import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { Owner } from "../../../models/user/owner.users.model";
import { GetOwnerUsersService } from "../../../services/user/get.owner.users.service";


interface GetOwnerUsersState extends BaseState<Owner> {}

const initialState: GetOwnerUsersState = {
    data: null,
    loading: false,
    error: null
};

export const fetchOwnerUsers = createAsyncThunk("fetch/OwnerUsers", async (id: string) => {
    const service = new GetOwnerUsersService()
    const response = await service.GetOwnerUsersAsync(id)
    return response;
});

export const getOwnerUsersSlice = createSlice({
    name: "getOwnerUsersSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOwnerUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchOwnerUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchOwnerUsers.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default getOwnerUsersSlice.reducer;
