// import React, { useEffect, useState } from 'react';
// import { BiMenuAltRight } from 'react-icons/bi';
// import { AiOutlineClose } from 'react-icons/ai';
// import { Link, useNavigate } from 'react-router-dom';
// import '../../styles/navbar/navbar.scss';
// import Sync3DLogo from '../../assets/Sync3DLogo-removebg-preview.png';
// import { LogoutService } from '../../services/auth/logout.service';
// import { GetAuthUrlService } from '../../services/oneDrive/auth/get.auth.url.service';
// import OneDriveNav from '../OneDrive/OneDriveNav';
// import { LoginResponse } from '../../models/auth/login.model';
// import GoogleDriveNav from '../GoogleDrive/GoogleDriveNav';
// import { GetBoxAuthorizationUrlService } from '../../services/auth/box/box.auth.url.service';
// import BoxNav from '../Box/BoxNav';
// import { GetGoogleAuthUrlService } from '../../services/googleDrive/auth/get.google.auth.url.service';
// import { GetAWSAuthorizationUrlService } from '../../services/auth/aws/aws.auth.url.service';
// import AWSNav from '../AWS/AWSNav';
// import { NavDropdown, NavItem } from 'react-bootstrap';

// interface HeaderProps {
// 	setIsBox: React.Dispatch<React.SetStateAction<boolean>> | undefined;
// }

// const SecondHeader: React.FC<HeaderProps> = ({ setIsBox }) => {
// 	const navigate = useNavigate();
// 	const [menuOpen, setMenuOpen] = useState(false);
// 	const [size, setSize] = useState({
// 		width: 0,
// 		height: 0,
// 	});
// 	const loginInfo = sessionStorage.getItem('login') as string;
// 	const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse;

// 	const clickedToBox = sessionStorage.getItem('clickBox');
// 	const clickedToGoogleDrive = sessionStorage.getItem('clickGoogleDrive');
// 	const clickedToOneDrive = sessionStorage.getItem('clickOneDrive');
// 	const clickedToAWS = sessionStorage.getItem('clickAWS');

// 	const liStyle = {
// 		color:
// 			clickedToBox !== null && clickedToBox === 'true' ? 'grey' : 'inherit',
// 		fontSize: '1.1rem', // Koşula göre renk ayarı yapılır
// 	};
// 	const OneDriveliStyle = {
// 		color:
// 			clickedToOneDrive !== null && clickedToOneDrive === 'true'
// 				? 'grey'
// 				: 'inherit',
// 		fontSize: '1.1rem', // Koşula göre renk ayarı yapılır
// 	};
// 	const GoogleDriveliStyle = {
// 		color:
// 			clickedToGoogleDrive !== null && clickedToGoogleDrive === 'true'
// 				? 'grey'
// 				: 'inherit',
// 		fontSize: '1.1rem',
// 	};

// 	const AWSiStyle = {
// 		color:
// 			clickedToAWS !== null && clickedToAWS === 'true' ? 'grey' : 'inherit',
// 		fontSize: '1.1rem',
// 	};

// 	useEffect(() => {
// 		const handleResize = () => {
// 			setSize({
// 				width: window.innerWidth,
// 				height: window.innerHeight,
// 			});
// 		};
// 		window.addEventListener('resize', handleResize);

// 		return () => window.removeEventListener('resize', handleResize);
// 	}, []);

// 	useEffect(() => {
// 		if (size.width > 768 && menuOpen) {
// 			setMenuOpen(false);
// 		}
// 	}, [size.width, menuOpen]);

// 	const menuToggleHandler = () => {
// 		setMenuOpen((p) => !p);
// 	};

// 	function removeSessionToExternalAuth(key: string) {
// 		if (sessionStorage.getItem(key) !== null) {
// 			sessionStorage.removeItem(key);
// 		}
// 	}

// 	return (
// 		<header className='header'>
// 			<div className='header__content'>
// 				<Link
// 					to={'/'}
// 					className='header__content__logo'
// 				>
// 					<img
// 						src={Sync3DLogo}
// 						alt='logo'
// 						width='120'
// 						height='120'
// 						className='d-inline-block'
// 					/>
// 				</Link>
// 				<nav
// 					className={`${'header__content__nav'}
//           ${menuOpen && size.width < 768 ? `${'isMenu'}` : ''}}`}/>
// 		  </div>
// 		  </header>
// 	)

// }


// export default SecondHeader

import React, { useEffect, useState } from 'react';
import { BiMenuAltRight } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/navbar/navbar.scss';
import Sync3DLogo from '../../assets/New Sync 3D Logo 2.png'
import VeewerLogo from '../../assets/Veewer siyah @4x.png'
import { LogoutService } from '../../services/auth/logout.service';
import { GetAuthUrlService } from '../../services/oneDrive/auth/get.auth.url.service';
import OneDriveNav from '../OneDrive/OneDriveNav';
import { LoginResponse } from '../../models/auth/login.model';
import GoogleDriveNav from '../GoogleDrive/GoogleDriveNav';
import { GetBoxAuthorizationUrlService } from '../../services/auth/box/box.auth.url.service';
import BoxNav from '../Box/BoxNav';
import { GetGoogleAuthUrlService } from '../../services/googleDrive/auth/get.google.auth.url.service';
import { GetAWSAuthorizationUrlService } from '../../services/auth/aws/aws.auth.url.service';
import AWSNav from '../AWS/AWSNav';
import {NavDropdown, NavItem } from 'react-bootstrap';

interface HeaderProps {
  setIsBox: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

const SecondHeader: React.FC<HeaderProps> = ({ setIsBox }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const loginInfo = sessionStorage.getItem('login') as string;
  const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse;

  const clickedToBox = sessionStorage.getItem("clickBox");
  const clickedToGoogleDrive = sessionStorage.getItem("clickGoogleDrive");
  const clickedToOneDrive = sessionStorage.getItem("clickOneDrive");
  const clickedToAWS = sessionStorage.getItem("clickAWS");

  const liStyle = {
    // color: clickedToBox !== null && clickedToBox === "true" ? 'grey' : 'inherit',
    fontSize: '1.1rem' // Koşula göre renk ayarı yapılır
  };
  const OneDriveliStyle = {
    // color: clickedToOneDrive !== null && clickedToOneDrive === "true" ? 'grey' : 'inherit' ,
    fontSize: '1.1rem'// Koşula göre renk ayarı yapılır
  };
  const GoogleDriveliStyle = {
    // color: clickedToGoogleDrive !== null && clickedToGoogleDrive === "true" ? 'grey' : 'inherit',
    fontSize: '1.1rem'
  }

  const AWSiStyle = {
    // color: clickedToAWS !== null && clickedToAWS === "true" ? 'grey' : 'inherit',
    fontSize: '1.1rem'
  }

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  function removeSessionToExternalAuth(key: string) {
    if (sessionStorage.getItem(key) !== null) {
      sessionStorage.removeItem(key);
    }
  }

  return (
    // <header className='header'>
    //   <div className='header__content'>
    //     <Link
    //       to={'/'}
    //       className='header__content__logo'
    //     >
    //       <img
    //         src={VeewerLogo}
    //         alt='logo'
    //         width='120'
    //         height='120'
    //         className='d-inline-block'
    //       />
    //     </Link>
    //     <nav
    //       className={`${'header__content__nav'}
    //       ${menuOpen && size.width < 768 ? `${'isMenu'}` : ''}
    //       }`}
    //     >
    //       <ul>
    //         <li className='nav-link'>
    //           <Link to={'/'} style={{
    //             color:'black'
    //           }}><b>Home</b></Link>
    //         </li>
    //         {loginInfo && jsonLoginInfo.isAuthenticated ? (
    //           <li className='nav-link'>
    //             <Link to={'/models'} style={{
    //             color:'black'
    //           }}><b>Storage</b></Link>
    //           </li>
    //         ) : (<></>)
    //         }

    //         {loginInfo && jsonLoginInfo.isAuthenticated && (    
    //           <li className='nav-link'>
    //               <NavDropdown as={NavItem} title="Connect to Cloud" style={{
    //                 color:"black",
    //                 fontWeight:"bold"
    //               }} >
                
    //                    {sessionStorage.getItem('microsoft-auth') !== null ? (
    //               <OneDriveNav />
    //             ) : (
    //               <NavDropdown.Item className={`nav-link text-center`} style={liStyle} onClick={async () => {
    //                 sessionStorage.setItem("clickOneDrive", "true")
    //                 sessionStorage.setItem("clickBox", "false")
    //                 sessionStorage.setItem("clickGoogleDrive", "false")
    //                 const authUrlService = new GetAuthUrlService();
    //                 const response = await authUrlService.GetAsync();
    //                 window.location.assign(response.url);
    //               }}>
    //                 OneDrive
    //               </NavDropdown.Item>
    //             )}
    //             {sessionStorage.getItem("google-auth") !== null ? (
    //               <GoogleDriveNav />
    //             ) : (<NavDropdown.Item className={`nav-link text-center disabled !text-gray-400`}  onClick={async () => {
    //               sessionStorage.setItem("clickOneDrive", "false")
    //               sessionStorage.setItem("clickBox", "false")
    //               sessionStorage.setItem("clickGoogleDrive", "true")
    //               const googleAuthUrlService = new GetGoogleAuthUrlService()
    //               const response = await googleAuthUrlService.GetAsync()
    //               window.location.assign(response.url)
    //             }}>
    //               Google Drive
    //             </NavDropdown.Item>)
    //             }
    //             {sessionStorage.getItem('box-auth') !== null ? (
    //               <BoxNav />
    //             ) : (
    //               <NavDropdown.Item  className={`nav-link text-center disabled !text-gray-400`} style={liStyle}  onClick={async () => {
    //                 sessionStorage.setItem("clickBox", "true")

    //                 const authUrlService = new GetBoxAuthorizationUrlService()
    //                 const response = await authUrlService.GetAsync();


    //                 window.location.assign(response.url);
    //               }}>
    //                 Box
    //               </NavDropdown.Item >
    //             )}
    //             {sessionStorage.getItem('aws-auth') !== null ? (
    //               <AWSNav />
    //             ) : (
    //               <NavDropdown.Item className={`nav-link text-center disabled !text-gray-400`} style={liStyle} onClick={async () => {
    //                 sessionStorage.setItem("clickAWS", "true")

    //                 const authUrlService = new GetAWSAuthorizationUrlService()
    //                 const response = await authUrlService.GetAsync();


    //                 window.location.assign(response.url);
    //               }}>
    //                 AWS
    //               </NavDropdown.Item>
    //             )}
               
    //               </NavDropdown>
    //               </li>
    //           )
    //         }
    //         {/* {loginInfo && jsonLoginInfo.isAuthenticated && jsonLoginInfo.isSuperAdmin ? (
    //           <li className='nav-link'>
    //             <Link to={"/dashboard/home"}>Dashboard</Link>
    //           </li>
    //         ) : (<></>)
    //         } */}

    //         {
    //           loginInfo && jsonLoginInfo.isAuthenticated && (
    //             <li className='nav-link'>
    //               <Link to={'/profile'} style={{
    //             color:'black'
    //           }}>
    //                 <b>Profile</b>
    //               </Link>
    //             </li>
    //           )
    //         }

    //         <li className='nav-link'>
    //           {
    //             loginInfo && jsonLoginInfo.isAuthenticated ? (
    //               <a
    //               style={{
    //                 color:'black'
    //               }}
    //                 onClick={async () => {
    //                   const logOutService = new LogoutService();
    //                   const response = await logOutService.GetByStringParamAsync(jsonLoginInfo.uid)

    //                   if (response !== null) {

    //                     removeSessionToExternalAuth("microsoft-auth");
    //                     removeSessionToExternalAuth("google-auth")
    //                     removeSessionToExternalAuth("box-auth")


    //                     sessionStorage.removeItem('login');
    //                     sessionStorage.removeItem('b')
    //                     window.location.replace('/');
    //                   }


    //                 }}
    //               >
    //                 <b>Logout</b>
    //               </a>
    //             ) : (<a onClick={() => {
    //               navigate("/login")
    //             }} style={{
    //               color:'black'
                  
    //             }}>
    //               <b>Sign In/Sign Up</b>
    //             </a>)
    //           }

    //         </li>
    //         <li className='nav-link'>
    //           <Link to={"/privacy"} style={{
    //             color:'black'
    //           }}><b>Privacy</b></Link>
    //         </li>
    //       </ul>
    //     </nav>
    //     <div className='header__content__toggle'>
    //       {!menuOpen ? (
    //         <BiMenuAltRight onClick={menuToggleHandler} />
    //       ) : (
    //         <AiOutlineClose onClick={menuToggleHandler} />
    //       )}
    //     </div>
    //   </div>
    // </header>
     <header className='header'></header>
  );
}

export default SecondHeader;