import { GetFileModel } from "../../../models/oneDrive/file/get.file.model";
import { BaseGetService } from "../../base/base.get.service";



export class GetFileService extends BaseGetService<GetFileModel>{

    constructor(controllerName:string="oneDrive",endpoint:string="files"){
        super(controllerName,endpoint)
    }
}