import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UploadBoxFilesToForgeRequestModel, UploadBoxFilesToForgeResponseModel } from "../../../models/box/upload.box.files.forge.model";
import { UploadBoxFilesForgeService } from "../../../services/box/upload.box.files.forge.service";



interface UploadFilesToForgeState extends BaseState<UploadBoxFilesToForgeResponseModel>{

}


let initialState:UploadFilesToForgeState={
    data:null,
    loading:false,
    error:null
}


export const uploadBoxFilesToForge=createAsyncThunk("upload/forge",
async(model:UploadBoxFilesToForgeRequestModel)=>{
    const uploadForgeService=new UploadBoxFilesForgeService()
    const response=await uploadForgeService.PutAsync(model) as UploadBoxFilesToForgeResponseModel
    return response
})


export const uploadBoxFilesToForgeSlice=createSlice({
    name:"uploadBoxFilesToForgeSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(uploadBoxFilesToForge.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(uploadBoxFilesToForge.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(uploadBoxFilesToForge.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})

export default uploadBoxFilesToForgeSlice.reducer