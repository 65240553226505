import { useEffect, useState } from "react"
import { Button, Container } from "react-bootstrap"

import '../../styles/register/register.css';
import { useAppDispatch, useAppSelector } from "../../store";
import { fetchUserRegister } from "../../store/features/user/user.register.slice";
import { useNavigate } from "react-router-dom";
import CircleLoading from "../Loading/CircleLoading";



const RegisterForm = () => {
    let userName = ""
    let password = ""
    let firstName = ""
    let lastName = ""
    let companyName = ""
    let email = ""
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useAppDispatch();
    const postRegisterSelector=useAppSelector(x=>x.fetchUserRegisterSlice)
    const navigate=useNavigate()

    useEffect(()=>{
        if (
            postRegisterSelector.error === null &&
            postRegisterSelector.data != null &&
            postRegisterSelector.loading === false
          ){
              navigate("/")
          }
    },[postRegisterSelector.data,postRegisterSelector.error,postRegisterSelector.loading,navigate])
    return (
        <div className="register">
            <Container className="registerContent">
                <div className="card-header">
                    <div>
                        <h3 className='text-register'>Sign Up</h3>
                    </div>
                </div>
                {
                    postRegisterSelector.loading?(
                        <CircleLoading text="Try to create user..."/>
                    ):(
                        <div className="form-container">
                        <form>
                            <div className="flex-container">
                                <label htmlFor='firstname'>
                                    FirstName
                                    <input
                                        className='register-form-input firstname'
                                        type='text'
                                        placeholder='Enter FirstName'
                                        onChange={(e) => {
                                            firstName = e.target.value;
                                        }}
                                    />
                                </label>
                                <label htmlFor='lastname'>
                                    LastName
                                    <input
                                        className='register-form-input lastname'
                                        type='text'
                                        placeholder='Enter LastName'
                                        onChange={(e) => {
                                            lastName = e.target.value;
                                        }}
                                    />
                                </label>
                                <label htmlFor='username'>
                                    Username
                                    <input
                                        className='register-form-input username'
                                        type='text'
                                        placeholder='Enter Username'
                                        onChange={(e) => {
                                            userName = e.target.value;
                                        }}
                                    />
                                </label>
                                <label htmlFor='companyname'>
                                   CompanyName
                                    <input
                                        className='register-form-input companyname'
                                        type='text'
                                        placeholder='Enter CompanyName'
                                        onChange={(e) => {
                                            companyName = e.target.value;
                                        }}
                                    />
                                </label>
                                <label htmlFor='password'>
                                    Password
                                    <div className='password-container'>
                                        <input
                                            className='register-form-input password'
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter Password'
                                            onChange={(e) => {
                                                password = e.target.value;
                                            }}
                                        />
                                    </div>
                                </label>
                                <label htmlFor='email'>
                                    Email
                                    <input
                                        className='register-form-input email'
                                        type='email'
                                        placeholder='Enter Email'
                                        onChange={(e) => {
                                            email = e.target.value;
                                        }}
                                    />
                                </label>
                            </div>
                        </form>
                        <div className="register btn-container">
                            <Button className='btn-register'onClick={async (e) => {
                                dispatch(fetchUserRegister({
                                    userName:userName,
                                    firstName:firstName,
                                    lastName:lastName,
                                    companyName:companyName,
                                    password:password,
                                    email:email,
                                    subscriptionName:"Platinum",
                                    phoneNumber:""
                                }))
                                e.preventDefault()
                            }}>
                                Register
                            </Button>
                        </div>
                    </div>
                    )
                }
             
            </Container>
        </div>
    )
}

export default RegisterForm