
import { PostBucketResponseModel } from "../../models/bucket/post.bucket.response.model";
import {PostBucketRequestModel} from "../../models/bucket/post.bucket.request.model"
import { BasePostService } from "../base/base.post.service";



export class PostUserBucketService extends BasePostService<PostBucketRequestModel,PostBucketResponseModel>{
    constructor(controllerName:string="buckets",endpointName:string="user"){
        super(controllerName,endpointName)
    }
}