import { CircularProgress } from '@mui/material';

const LittleCircleLoading=()=> {
  return (
      <CircularProgress
        size={20}
        thickness={2}
        sx={{ color: 'white' }}
        
      />
  );
};

export default LittleCircleLoading;
