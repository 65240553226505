import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { DeleteFolderModel, DeleteFolderRequestModel } from "../../../models/oneDrive/folder/delete.folder.model";
import { DeleteFolderService } from "../../../services/oneDrive/folder/delete.folder.service";




interface DeleteFolderState extends BaseState<DeleteFolderModel>{

}


let initialState:DeleteFolderState={
    data:null,
    loading:false,
    error:null
}


export const deleteFolder=createAsyncThunk("delete/folder",async(model:DeleteFolderRequestModel)=>{
    const deleteFolderService=new DeleteFolderService()
    const response=await deleteFolderService.DeleteByStringManyParamByAsync([model.bucketName,model.folderName])
    return response
})



export const deleteFolderSlice=createSlice({
    name:"deleteFolderSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(deleteFolder.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(deleteFolder.fulfilled,(state,action)=>{
            state.loading=false
            if (action.payload !== undefined) {
                state.data = action.payload;
            }
        })
        .addCase(deleteFolder.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default deleteFolderSlice.reducer