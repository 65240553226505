
import { UserCreditModel } from "../../models/dashboard/get.user.credit.model";
import { BaseGetService } from "../base/base.get.service";



export class GetUserCreditService extends BaseGetService<UserCreditModel[]>{

    constructor(controllerName:string="dashboard",endpoint:string="owner/credits"){
        super(controllerName,endpoint)
    }
}