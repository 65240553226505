import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { AWSLoginRequest, AWSLoginResponse } from "../../../models/auth/aws/aws.login.model";
import { AWSPostTokenService } from "../../../services/auth/aws/aws.post.token.service";


interface AWSPostTokenState extends BaseState<AWSLoginResponse>{

}

let initialState:AWSPostTokenState={
    data: null,
    loading: false,
    error: null,
}


export const fetchAWSAuth=createAsyncThunk('fetch/AWSAuth',async({code,bucketName}:AWSLoginRequest,thunkAPI)=>{
    try{
        const service=new AWSPostTokenService()
        var response=await service.PostAsync({
            code,
            bucketName
        })

        return response
    }
    catch (err: any) {
        if (!err.response) {
          throw err
        }
  
  
        const errorData=err.response.data
        
        return thunkAPI.rejectWithValue({
          error: errorData.error?errorData.error:errorData.message
        })
  
      }
  
})

export const postAWSTokenSlice=createSlice({
    name:"postAWSTokenSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAWSAuth.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(fetchAWSAuth.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(fetchAWSAuth.rejected, (state, action: any) => {
          state.loading = false;
          state.error = action.payload
        });
    },
})

export default postAWSTokenSlice.reducer