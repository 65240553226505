import { GetUploadedObjectResponseModel } from "../../models/object/uploadedObject/get.uploadedObject.response.model";
import { BaseGetFilterService } from "../base/base.get.filter.service";
import { BaseGetService } from "../base/base.get.service";




export class GetListUploadedObjectService extends BaseGetService<GetUploadedObjectResponseModel[]>
{

}

export class GetListUploadedObjectByFilterService extends BaseGetFilterService<GetUploadedObjectResponseModel[]>{
    
}