import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";

import { GetAWSFilesResponseModel } from "../../../models/aws/get.files.aws.model";
import { GetAllAWSFilesService } from "../../../services/aws/get.all.aws.files.service";




interface GetAllAWSFilesState extends BaseState<GetAWSFilesResponseModel>{

}


let initialState:GetAllAWSFilesState={
    data:null,
    loading:false,
    error:null
}


export const fetchAllAWSFiles=createAsyncThunk("fetch/allAWSFiles",async(bucketName:string)=>{
    const service=new GetAllAWSFilesService()
    const response=await service.GetByStringParamAsync(bucketName)
    return response
})


export const getAllAWSFilesSlice=createSlice({
    name:"getAllAWSFilesSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAllAWSFiles.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAllAWSFiles.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAllAWSFiles.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAllAWSFilesSlice.reducer