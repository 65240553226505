import video1 from '../assets/videos/Sequence 01 1.mp4';
import video2 from '../assets/videos/6895614-hd_1920_1080_25fps.mp4';
import video3 from '../assets/videos/zımba.mp4';

export default function ChallengeSolution() {
  return (
    <section className="py-16 bg-white">
      <div className="md:container lg:container xl:container 2xl:container sm:!w-full xs:!w-full mx-auto px-4">
        <div className="space-y-24">
          {/* Challenge Section */}
          <div className="flex flex-col xs:flex-col md:flex-row items-center md:items-start gap-8">
            {/* 1. Video Solda */}
            <div className="w-full md:w-1/2 aspect-video">
              <video autoPlay loop muted className="w-full h-full object-cover rounded-lg">
                <source src={video1} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="w-full md:w-1/2">
              <h2 className="text-2xl md:text-3xl font-bold mb-2">Challenge</h2>
              <p className="text-gray-600 md:text-base xs:text-sm">
                Traditional product catalogs struggle to showcase products' full potential and details.
                2D images fail to accurately represent the actual size, functionality, and features of products.
                Moreover, customers cannot explore and interact with these catalogs in detail, leading to dissatisfaction
                and low sales conversion rates.
              </p>
            </div>
          </div>

          {/* Solution Section */}
          <div className="flex flex-col xs:flex-col md:flex-row-reverse items-center md:items-start gap-8">
            {/* 2. Video Sağda */}
            <div className="w-full md:w-1/2 aspect-video">
              <video autoPlay loop muted className="w-full h-full object-cover rounded-lg">
                <source src={video3} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="w-full md:w-1/2">
              <h2 className="text-2xl md:text-3xl font-bold mb-2">Solution</h2>
              <p className="text-gray-600 md:text-base xs:text-sm">
                VEEWER revolutionizes your product catalogs by adding a new dimension.
                With 3D technology, you can present your products more impressively and interactively.
                Customers can examine your products 360 degrees, zoom in, and interact with them.
                VEEWER enhances the shopping experience, increasing customer trust and engagement with your products.
              </p>
            </div>
          </div>

          {/* Benefits Section */}
          <div className="flex flex-col xs:flex-col md:flex-row items-center md:items-start h-auto gap-8">
            {/* 3. Video Tekrar Solda */}
            <div className="w-full md:w-1/2 aspect-video border">
              <video autoPlay loop muted className="w-full h-full object-cover rounded-lg">
                <source src={video2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="w-full md:w-1/2">
              <h2 className="text-2xl md:text-3xl font-bold mb-2">Benefits</h2>
              <ul className="space-y-4 md:space-y-1 list-disc px-0 list-inside text-gray-700 md:text-base xs:text-sm">
                <li className="flex items-start">
                  <span className="mr-2 text-green-500">✓</span>
                  <span>Your customers will better understand your products and make purchasing decisions with confidence.</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2 text-green-500">✓</span>
                  <span>Interactive and detailed product exploration drives higher purchase rates.</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2 text-green-500">✓</span>
                  <span>Stand out in your industry and stay ahead of the competition with VEEWER.</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2 text-green-500">✓</span>
                  <span>A more cost-effective and eco-friendly solution compared to traditional catalogs, protecting your business budget.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
