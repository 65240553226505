import { faAmbulance } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Modal } from 'react-bootstrap';
const Unauthorized = () => {
  return (
    <Modal>
      <Modal.Header>
        <Modal.Title>
          Unauthorized <FontAwesomeIcon icon={faAmbulance} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>You've to login!</Card.Body>
          <Card.Footer>
            <Button
              onClick={() => {
                window.location.replace('/');
              }}
            >
              Click
            </Button>
          </Card.Footer>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default Unauthorized;
