import { BaseModel } from "../../models/base/base.model";
import { BaseService } from "./base.service";




export class BasePatchService<T extends BaseModel> extends BaseService{


    async PatchAsyncWithStringParam(param:string):Promise<T>{
        const res=await this.request.patch<T>(`/${param}`)
        return res.data
    }

    async PatchAsyncWithStringParams(params:string[]):Promise<T>{
        let text:string=""
        params.map((param)=>{
            text+=`/${param}`
        })

        const res=await this.request.patch<T>(text)
        return res.data
    }

    async PatchAsync():Promise<T>{
        const res=await this.request.patch<T>("")
        return res.data
    }
}