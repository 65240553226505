import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { DeleteFileRequestModel, DeleteFileResponseModel } from "../../../models/oneDrive/file/delete.file.model";
import { DeleteFileService } from "../../../services/oneDrive/file/delete.file.service";


interface DeleteFileState extends BaseState<DeleteFileResponseModel>{

}


let initialState:DeleteFileState={
    data:null,
    loading:false,
    error:null
}


export const deleteFile=createAsyncThunk("delete/folder",async(model:DeleteFileRequestModel)=>{
    const deleteFolderService=new DeleteFileService()
    const response=await deleteFolderService.DeleteByStringManyParamByAsync([model.fileId,model.bucketName])
    return response
})



export const deleteFileSlice=createSlice({
    name:"deleteFileSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(deleteFile.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(deleteFile.fulfilled,(state,action)=>{
            state.loading=false
            if (action.payload !== undefined) {
                state.data = action.payload;
            }
        })
        .addCase(deleteFile.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default deleteFileSlice.reducer