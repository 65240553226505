
import { GetBoxFolderListModel } from "../../models/box/get.box.folders.model";
import { BaseGetService } from "../base/base.get.service";



export class GetAllBoxFoldersService extends BaseGetService<GetBoxFolderListModel>{
    constructor(controllerName:string="box",endpoint:string="folders"){
        super(controllerName,endpoint)
    }
    
}