import '../styles/home/newsletter.css';
import Sync3DLogo from '../assets/Sync 3D logo 2 v3.png';
import VeewerLogo from '../assets/Veewer siyah @4x.png'
import { useAppDispatch, useAppSelector } from '../store';
import { useState } from 'react';
import { fetchPutSubscriber } from '../store/features/subscriber/subscriber.slice';
import CircleLoading from './Loading/CircleLoading';
import LittleCircleLoading from './Loading/LittleCircleLoading';

export default function Newsletter() {

	const subscriber=useAppSelector(s => s.subscriberSlice)
	const dispatch=useAppDispatch()

	const [email,setEmail]=useState("")


	return (
		<section className='newsletter'>
			<div className='newsletter-container'>
				<div className='newsletter-content'>
					<div className='subscribe'>
						<div>
							<h2 className='subscribe-title'>Stay in the loop</h2>
							<p className='subscribe-desc'>
								{' '}
								Sign up with your email address to receive news and updates.
							</p>
						</div>
						<form className='subscribe-form'>
							<input
								className='subscribe-input'
								name='email'
								type='email'
								placeholder='Email Address'
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<button type="button" disabled={subscriber.loading?true:false} className='subscribe-button' onClick={()=>{
								dispatch(fetchPutSubscriber({
									email
								}))
							}}>{subscriber.loading?<LittleCircleLoading/>:'Subscibe'}</button>
						</form>
					</div>
					<div className='brand'>
						<img
							className='brand-logo'
							src={VeewerLogo}
							alt='brand-logo'
						/>
					</div>
				</div>
			</div>
		</section>
	);
}
