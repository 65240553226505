

export const initializeViewer = (urn:string,token:string) => {
 

    const Autodesk=global.Autodesk

    const viewerOptions = {
        env: 'AutodeskProduction',
        accessToken: token,
        api: 'derivativeV2',
    };
    const viewerContainer = document.getElementById('viewerContainer')
    var viewer = new Autodesk.Viewing.GuiViewer3D(viewerContainer as HTMLElement, {})
    
    Autodesk.Viewing.Initializer(viewerOptions, () => {
        
        viewer.start();

        Autodesk.Viewing.Document.load(`urn:${urn}`,OnSuccessLoad,onErrorLoad)

        console.log(viewer)
      })

    const OnSuccessLoad=(document:Autodesk.Viewing.Document)=>{
        var defaultModel = document.getRoot().getDefaultGeometry();

        console.log(document)
        console.log(defaultModel)
        viewer.loadDocumentNode(document, defaultModel);
    }

    const onErrorLoad=()=>{
        console.error('Failed fetching Forge manifest');
    }
    

}


export const InitializeViewerBySvf=()=>{
    const options={
        env:'Local'
    }

    const svf="./output.svf"

    const viewerContainer = document.getElementById('forgeViewer')
    var viewer = new Autodesk.Viewing.GuiViewer3D(viewerContainer as HTMLElement, {})


    Autodesk.Viewing.Initializer(options, () => {
        
        const startedCode=viewer.start(svf);

        if (startedCode > 0) {
            console.error('Failed to create a Viewer: WebGL not supported.');
            return;
        }
    
        console.log('Initialization complete, loading a model next...');
    })


}

