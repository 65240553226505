import { CircularProgress } from '@mui/material';
import { Container } from 'react-bootstrap';

interface CircleLoadingProps {
  text: string;
}

const CircleLoading: React.FC<CircleLoadingProps> = ({ text }) => {
  return (
    <Container
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
      }}
    >
      <CircularProgress
        size={40}
        thickness={2}
      />

      <h3>{text}</h3>
    </Container>
  );
};

export default CircleLoading;
