
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { CreateAWSBucketRequestModel, CreateAWSBucketResponseModel } from "../../../models/aws/create.aws.bucket.model";
import { CreateAWSBucketService } from "../../../services/aws/create.aws.bucket.service";




interface CreateAWSBucketState extends BaseState<CreateAWSBucketResponseModel>{

}


let initialState:CreateAWSBucketState={
    data:null,
    loading:false,
    error:null
}


export const postAWSBucket=createAsyncThunk("post/AWSBucket",async(model:CreateAWSBucketRequestModel)=>{
    const service=new CreateAWSBucketService()
    const response=await service.PostAsync(model)
    return response
})


export const postAWSBucketSlice=createSlice({
    name:"postAWSBucketSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(postAWSBucket.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(postAWSBucket.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(postAWSBucket.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default postAWSBucketSlice.reducer