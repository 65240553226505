import { useEffect } from "react"
import { InitializeViewerBySvf } from "../../viewer/viewer.helper"


const OneDrive3DView=()=>{
  //  const location=useLocation()
    // const htmlContent=location.state.htmlString as string

    useEffect(()=>{
        InitializeViewerBySvf()
    })
    //const divId=htmlContent[htmlContent.indexOf("forgeViewer")]
    return (
        <div id="forgeViewer">
        </div>
    )
}

export default OneDrive3DView