import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetTypesFilterFileModel } from "../../../models/oneDrive/file/get.types.filter.file.model";
import { GetTypeFiltersFilesService } from "../../../services/oneDrive/file/get.type.filters.files.service";






interface GetFileTypesFilesState extends BaseState<GetTypesFilterFileModel>{
    
}

let initialState:GetFileTypesFilesState={
    data:null,
    loading:false,
    error:null
}


export const fetchFilesByTypes=createAsyncThunk("fetch/typeFilter",async()=>{
    const service=new GetTypeFiltersFilesService()
    const response=await service.GetByQueryParameter("types=ipt,iam,idw,dwg,dwfx,model,zip,rvt")
    return response
})


export const getFilesByTypesSlice=createSlice({
    name:"getFilesByTypesSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(fetchFilesByTypes.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchFilesByTypes.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchFilesByTypes.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})

export default getFilesByTypesSlice.reducer