
import { GetGoogleDriveFolderListModel } from "../../models/googleDrive/get.googleDrive.folders.model";
import { BaseGetService } from "../base/base.get.service";



export class GetAllGoogleDriveFoldersService extends BaseGetService<GetGoogleDriveFolderListModel>{
    constructor(controllerName:string="googleDrive",endpoint:string="folders"){
        super(controllerName,endpoint)
    }
    
}