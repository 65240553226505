import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetFileService } from "../../../services/oneDrive/file/get.file.service";
import { GetIAMFilesModelResponse, GetIAMFilesRequest } from "../../../models/oneDrive/file/get.iam.files.model";
import { GetIAMFilesService } from "../../../services/oneDrive/file/get.iam.files.service";




interface GetAllFilesState extends BaseState<GetIAMFilesModelResponse>{

}


let initialState:GetAllFilesState={
    data:null,
    loading:false,
    error:null
}


export const fetchIAMFiles=createAsyncThunk("fetch/IAMFiles",async(model:GetIAMFilesRequest)=>{
    const service=new GetIAMFilesService()
    const response=await service.GetByStringManyParamsAsync([model.itemId,model.bucketName])
    return response
})


export const getIAMFilesSlice=createSlice({
    name:"getFileSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchIAMFiles.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchIAMFiles.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchIAMFiles.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getIAMFilesSlice.reducer