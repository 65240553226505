import { BaseGetService } from "../base/base.get.service";
import { GetUserModel } from "../../models/user/get.user.model";

export class GetUserInfoService extends BaseGetService<GetUserModel> {
    constructor(controllerName: string = "user", endpointName: string = "") {
        super(controllerName, endpointName);
    }

    async GetUserInfoAsync(userId: string): Promise<GetUserModel> {
        return this.GetByStringParamAsync(userId);
    }
}
