import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface TextAreaModalProps {
  show: boolean
  onHide: (val: boolean) => void
  incomingValue: string
}

const TextAreaModal: React.FC<TextAreaModalProps> = ({
  show,
  onHide,
  incomingValue
}) => {
  const [text, setText] = useState(incomingValue.trim())
  const [copied, setCopied] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setText(incomingValue.trim())
  }, [incomingValue])

  const handleClose = () => {
    if (copied) {
      setCopied(false)
    }
    onHide(false)
  }

  const handleCopy = () => {
    const textAreaDoc = document.getElementById('textA') as HTMLTextAreaElement
    navigator.clipboard.writeText(textAreaDoc.value)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  const handleTestEmbed = () => {
    const textAreaDoc = document.getElementById('textA') as HTMLTextAreaElement
    navigate('/embed', {
      state: {
        embedCode: textAreaDoc.value
      }
    })
  }

  if (!show) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Edit and Copy Text</h2>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>
        <div className="p-4">
          <textarea
            id="textA"
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="w-full h-64 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="text-sm text-gray-500 mt-2">
            Character count: {text.length}
          </div>
        </div>
        <div className="flex justify-end space-x-2 p-4 border-t">
          <button
            onClick={handleCopy}
            className={`px-4 py-2 rounded-md transition-colors ${
              copied
                ? 'bg-green-500 text-white'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            {copied ? 'Copied!' : 'Copy'}
          </button>
          <button
            onClick={handleTestEmbed}
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
          >
            Test embed code
          </button>
        </div>
      </div>
    </div>
  )
}

export default TextAreaModal