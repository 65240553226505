import React, { useEffect, useState } from 'react';
import '../../styles/onedrive/upload.css';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import CircleLoading from '../Loading/CircleLoading';
import { createUploadedObject } from '../../store/features/object/create.uploadedObject.slice';
import { getUploadedObjects } from '../../store/features/object/get.list.uploadedObject.slice';
import { UploadedFile } from '../../models/object/uploadedObject/put.uploadedObject.request.model';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SelectableZipFiles } from '../../pages/object/ObjectList';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Checkbox, IconButton, Button } from '@mui/material';
import { ExpandMore, InsertDriveFile, Close } from '@mui/icons-material';

interface DragAndDropProps {
	handleFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleCloseModal: () => void;
	handleFileDrop: (e: React.DragEvent<HTMLDivElement>) => void;
	setIsDragDrop: React.Dispatch<React.SetStateAction<boolean>>;
	selectedFiles: UploadedFile[];
	setSelectedFiles: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
	setZipFile: React.Dispatch<React.SetStateAction<SelectableZipFiles | undefined>>;
	zipFile: SelectableZipFiles | undefined;
	bucket: string;
	processZipFile: (file: File) => Promise<void>
	openZipOption: boolean
	setOpenZipOption: React.Dispatch<React.SetStateAction<boolean>>
	extractZipStatus: boolean;
	setExtractZipStatus: React.Dispatch<React.SetStateAction<boolean>>
}



const MultipleUploadModal: React.FC<DragAndDropProps> = ({
	handleFile,
	handleFileDrop,
	setIsDragDrop,
	selectedFiles,
	setSelectedFiles,
	bucket,
	zipFile,
	setZipFile,
	processZipFile,
	setOpenZipOption,
	openZipOption,
	setExtractZipStatus,
	extractZipStatus
}) => {
	const dispatch = useAppDispatch();
	const [isFinishedToLoading, setIsFinishedToLoading] = useState(false);
	const uploadedFilesSelector = useAppSelector((s: RootState) => s.createUploadedObject);
	const UploadFilesSwal = withReactContent(Swal);
	const [selectedFile, setSelectedFile] = useState({
		fileName:"",
		file:"",
		fileSize:"",
	});


	const handleselectedFile:any = (file:any) => {
		setSelectedFile(file)
	}

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);

	const handleSelectFileAsRoot = (file: File, rootFileName: string) => {
		setSelectedFiles([{
			file: file,
			rootFileName: rootFileName
		}]);

		setOpenZipOption(false)
	};

	return (
		<div className='dnd-modal'>
			<div className='dnd-container'>
				<button
					className='close-button'
					hidden={uploadedFilesSelector.loading}
					onClick={() => {
						setZipFile(undefined);
						setSelectedFiles([])
						if (isFinishedToLoading) {
							setIsFinishedToLoading(false);
							dispatch(getUploadedObjects({ bucketKey: bucket }));
							setIsDragDrop(false);
						} else {
							setIsDragDrop(false);
						}
					}}
				>
					<svg className='close-icon' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style={{ width: '50px', height: '50px' }}>
						<path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
						<path d='M0 0h24v24H0z' fill='none' />
					</svg>
				</button>
				{
					extractZipStatus === true ? (
						<CircleLoading text='Extracting all iam files to zip...' />
					) : (
						<>
							{
								zipFile !== undefined && openZipOption === true ? (
									<Box className="bg-white rounded-lg shadow-md p-6 min-w-[400px] max-w-2xl mx-auto overflow-y-hidden">
										<Box className="flex items-center justify-between mb-4">
											<Typography variant="h5" component="h2" className="font-semibold text-gray-800">
												Selected files
											</Typography>

										</Box>
										<Box className="mb-4">
											<Typography variant="subtitle1" className="font-medium text-gray-700">
												Main IAM file{!selectedFile.fileName && <span className='text-xs text-red-400'>{" ("}Please select the main IAM file.{")"}</span>}
											</Typography>
											{selectedFile.fileName  &&  <Box className="flex items-center justify-between p-2 bg-gray-50 rounded">
											<Box className="flex items-center">
													<Checkbox
														edge="start"
														checked={true}
														tabIndex={-1}
														disableRipple
														className="text-blue-500"
													/>
													<InsertDriveFile className="text-gray-400 mr-2" />
													 <Typography variant="body2">{selectedFile.fileName}</Typography> 
												</Box>
												<Box className="flex items-center">
													<Typography variant="body2" className="text-gray-500 mr-2">
														{selectedFile.fileSize} KB
													</Typography>
												</Box>
											</Box>}
										</Box>
										<Box className="mb-4  scroll-y">
											<Typography variant="subtitle1" className="font-medium text-gray-700 mb-2">
												Referans dosyalar {"("+zipFile.subFiles.length+")"}
											</Typography>
											<List className="divide-y divide-gray-200 max-h-[50vh] overflow-auto">
												{zipFile.subFiles.map((file, i) => (
													selectedFile.fileName != file.fileName &&
													<ListItem
														key={i}
														dense
														button
														onClick={()=>handleselectedFile(file)}
														className="py-1"
													>
														<ListItemIcon>
															<Checkbox
																edge="start"
																tabIndex={-1}
																disableRipple
																
																checked={selectedFile.fileName == file.fileName}
																className="text-blue-500"
															/>
														</ListItemIcon>
														<ListItemIcon>
															<InsertDriveFile className="text-gray-400" />
														</ListItemIcon>
														<ListItemText
															primary={file.fileName}
															className="text-sm"
														/>
														<Typography variant="body2" className="text-gray-500">
															{file.fileSize} KB
														</Typography>
													</ListItem>
												))}
											</List>
										</Box>
										<Box className="flex flex-col items-center">
											<Button
												variant="contained"
												color="primary"
												disabled={selectedFile.fileName.length < 1}
												className="w-full max-w-xs"
												onClick={() => handleSelectFileAsRoot(zipFile.mainZipFile, selectedFile.fileName)}
											>
												CONTINUE
											</Button>
										</Box>
									</Box>) : (
									<label className='dnd-label'>
										<input
											type='file'
											multiple
											onChange={async (e) => {
												handleFile(e);
												const files = Array.from(e.target.files || []);
												for (const file of files) {
													if (file.name.endsWith('.zip')) {
														await processZipFile(file);
													}
												}
												e.target.value = '';
											}}
											disabled={uploadedFilesSelector.loading ? true : false}
											hidden={uploadedFilesSelector.loading ? true : false}
											style={{ display: 'none' }}
										/>
										<div
											onDrop={handleFileDrop}
											onDragOver={(e) => e.preventDefault()}
											className='drag-drop-area'
										>
											<div className='svg-container'>
												<svg
													className='svg-icon'
													viewBox='0 0 24 24'
													xmlns='http://www.w3.org/2000/svg'
												>
													<g>
														<path
															fill='none'
															d='M0 0h24v24H0z'
														/>
														<path
															className='hover-path'
															fill-rule='nonzero'
															d='M14 6h2v2h5a1 1 0 0 1 1 1v7.5L16 13l.036 8.062 2.223-2.15L20.041 22H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zm8 11.338V21a1 1 0 0 1-.048.307l-1.96-3.394L22 17.338zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z'
														/>
													</g>
												</svg>
											</div>

											<div className='dnd-desc'>
												<p>
													<b>Drag & Drop or Browse</b>
												</p>
												<>
													<p>We support IPT,ZIP,DWG,DWFX,RVT and more...
														<br />
														You can select a maximum of 15 files to upload
													</p>

												</>
											</div>
											<br />
											{
												<div className='dnd-files'>
													{uploadedFilesSelector.loading ? (
														<CircleLoading text={`${selectedFiles.length > 1 ? "Files are" : "File is"} uploading...`} />
													) : (
														<>
															{selectedFiles &&
																selectedFiles.length > 0 &&
																selectedFiles.map((file, i) => (
																	<div
																		className='addedFile'
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																		}}
																	>
																		<span
																			style={{
																				marginLeft: '0.5rem',
																			}}
																		>
																			{file.file.name}
																		</span>
																	</div>
																))}
														</>
													)}
												</div>
											}
										</div>
										<div className='dnd-upload-btn-grp'>
											<button
												className='dnd-cancel-btn'
												hidden={
													uploadedFilesSelector.loading ? true : false ||
														isFinishedToLoading ? true : false
												}
												onClick={() => {
													setSelectedFiles([])
													setIsDragDrop(false)
												}}
											>
												Cancel
											</button>
											{selectedFiles && selectedFiles.length > 0 && (
												<button
													className='dnd-upload-btn'
													hidden={uploadedFilesSelector.loading ? true : false}
													onClick={() => {
														console.log(selectedFiles)
														dispatch(createUploadedObject({
															bucketName: bucket,
															files: [...selectedFiles]
														})).then(() => {
															setSelectedFiles([])
															setIsFinishedToLoading(true)
														})
													}}
												>
													Upload
												</button>
											)}
											{
												isFinishedToLoading && selectedFiles.length === 0 && (
													<>
														{
															uploadedFilesSelector.error == null && uploadedFilesSelector.data != null ? (
																<>
																	{
																		selectedFiles.length > 1 ? (
																			<>
																				<p style={{
																					color: "green",
																					marginRight: "auto"
																				}}>Files have been uploaded succesfully</p>
																				<div style={{ textAlign: "center", marginTop: "10px;" }}>
																					<button
																						className='dnd-upload-btn'
																						onClick={() => {
																							setSelectedFiles([])
																							dispatch(getUploadedObjects({ bucketKey: bucket }))
																							setIsDragDrop(false)
																						}}
																					>
																						Return Upload Page
																					</button>
																				</div>
																			</>
																		)
																			: (
																				<>
																					<p style={{
																						color: "green",
																						marginRight: "auto"
																					}}>File has been uploaded successfully</p>
																					<div style={{ textAlign: "center", marginTop: "10px;" }}>
																						<button
																							className='dnd-upload-btn'
																							onClick={() => {
																								setSelectedFiles([])
																								dispatch(getUploadedObjects({ bucketKey: bucket }))
																								setIsDragDrop(false)
																							}}
																						>
																							Return Upload Page
																						</button>
																					</div>
																				</>

																			)
																	}

																</>
															) : (
																<>
																	{
																		selectedFiles.length > 1 ? (<p style={{
																			color: "red",
																			marginRight: "auto"
																		}}>Files have not been uploaded succesfully</p>)
																			: (<p style={{
																				color: "red",
																				marginRight: "auto"
																			}}>File has not been uploaded successfully</p>)
																	}


																</>)
														}
													</>
												)
											}
										</div>
									</label>
								)
							}
						</>
					)
				}


			</div>
		</div>
	);
};

export default MultipleUploadModal;
