import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { PostBucketRequestModel } from "../../../models/bucket/post.bucket.request.model";
import { PostBucketResponseModel } from "../../../models/bucket/post.bucket.response.model";
import { PostBucketService } from "../../../services/bucket/post.bucket.service";






interface CreateBucketState extends BaseState<PostBucketResponseModel>{

}


let initialState:CreateBucketState={
    data:null,
    loading:false,
    error:null
}


export const createBucket=createAsyncThunk("bucket/create",async(request:PostBucketRequestModel)=>{

    const createBucketService=new PostBucketService("buckets","")
    return await createBucketService.PostAsync(request)
})


export const createBucketSlice=createSlice({
    name:"createBucketSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(createBucket.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(createBucket.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(createBucket.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})

export default createBucketSlice.reducer