import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetAllFilesModel } from "../../../models/oneDrive/file/get.all.files.model";
import { GetAllFilesService } from "../../../services/oneDrive/file/get.all.files.service";




interface GetAllFilesState extends BaseState<GetAllFilesModel>{

}


let initialState:GetAllFilesState={
    data:null,
    loading:false,
    error:null
}


export const fetchAllFiles=createAsyncThunk("fetch/allFiles",async(bucketName:string)=>{
    const service=new GetAllFilesService()
    const response=await service.GetByStringParamAsync(bucketName)
    return response
})


export const getAllFilesSlice=createSlice({
    name:"getAllFilesSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAllFiles.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAllFiles.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAllFiles.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAllFilesSlice.reducer