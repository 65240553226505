

import { GetBoxFolderModel } from "../../models/box/get.box.folder.model";
import { BaseGetService } from "../base/base.get.service";



export class GetBoxFolderService extends BaseGetService<GetBoxFolderModel>{
    constructor(controllerName:string="box",endpoint:string="folders"){
        super(controllerName,endpoint)
    }
    
}