import { BaseModel } from "../../models/base/base.model";
import { BaseService } from "./base.service";

export class BaseDeleteService<TResponse extends BaseModel> extends BaseService{
    async DeleteAsync(){     
        const res=await this.request.delete<TResponse>("")
        return res.data
    }

    async DeleteByStringParamByAsync(param:string){
        const res= await this.request.delete<TResponse>(`/${param}`)
        return res.data
    }

    async DeleteByStringManyParamByAsync(params:string[]){
        let text:string=""
        params.map((param)=>{
            text+=`/${param}`
        })

        console.log(text)

        const res=await this.request.delete<any,TResponse>(text)
        console.log(res)
        return res
    }


    async DeleteByNumnerParamByAsync(param:number){
        return await this.request.delete<any,TResponse>(`/${param}`)
    }

    async DeleteByNumberManyParamByAsync(params:number[]){
        let text:string=""
        params.map((param)=>{
            text+=`/${param}`
        })

        return await this.request.delete<any,TResponse>(text)
    }

}