import { UploadBoxFilesToForgeRequestModel, UploadBoxFilesToForgeResponseModel } from "../../models/box/upload.box.files.forge.model";
import { BasePutService } from "../base/base.put.service";




export class UploadBoxFilesForgeService extends 
BasePutService<UploadBoxFilesToForgeRequestModel,UploadBoxFilesToForgeResponseModel>{

    constructor(controllerName:string="box",endpoint:string="upload/forge"){
        super(controllerName,endpoint)
    }
}