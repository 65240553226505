import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SecondHeader from '../components/Navbar/SecondHeader';
import Footer from '../components/Footer/Footer';
import '../styles/forgotPassword/forgotPassword.css';
import { fetchForgotPassword } from '../store/features/forgotPassword/forgot.password.slice';
import { RootState, useAppDispatch, useAppSelector } from '../store';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const forgotPasswordState = useAppSelector((state:RootState) => state.forgotPasswordSlice);

  const handleForgotPassword = async (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(fetchForgotPassword({ email }));
  };

  useEffect(() => {
    if (forgotPasswordState.data) {
      setMessage(forgotPasswordState.data.message);
      if (forgotPasswordState.data.message.includes('Password reset email sent')) {
        setTimeout(() => navigate('/login'), 3000);
      }
    }
  }, [forgotPasswordState.data, navigate]);

  return (
    <>
      <div className="forgot-password-page">
        <div className="forgot-password-container">
          <h2>Forgot Password</h2>
          <form onSubmit={handleForgotPassword}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit">Reset Password</button>
          </form>
          {message && <p>{message}</p>}
        </div>
      </div>
      <Footer />
    </>
  );
}
