
import { UploadPrevFilesRequestModel, UploadPrevFilesResponseModel } from "../../../models/oneDrive/file/upload.prev.files.model";
import { BasePutService } from "../../base/base.put.service";




export class UploadPrevFilesService extends 
BasePutService<UploadPrevFilesRequestModel,UploadPrevFilesResponseModel>{

    constructor(controllerName:string="oneDrive",endpoint:string="upload"){
        super(controllerName,endpoint)
    }

    async UploadWithFormDataAsync(request:UploadPrevFilesRequestModel){
        const formData=new FormData()

        request.files.map((file,i)=>{
            formData.append(`files`, file)     
        })
        formData.append("bucketName",request.bucketName)

        
       
        return await this.PutWithFormDataAsync(formData)
    }
}