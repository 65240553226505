import { UploadOneDriveFilesToForgeRequestModel } from "../../../models/oneDrive/file/upload.oneDrive.files.forge.request.model";
import { UploadOneDriveFilesToForgeSecondaryResponseModel } from "../../../models/oneDrive/file/upload.oneDrive.files.forge.secondary.response.model";
import { BasePutService } from "../../base/base.put.service";




export class UploadOneDriveFilesToForgeSecondaryService extends 
BasePutService<UploadOneDriveFilesToForgeRequestModel,UploadOneDriveFilesToForgeSecondaryResponseModel>{

    constructor(controllerName:string="oneDrive",endpoint:string="upload/forge/secondary"){
        super(controllerName,endpoint)
    }
}