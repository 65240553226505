// import {createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { BaseState } from '../../../commons/base.state';
// import { LoginRequest, LoginResponse } from '../../../models/auth/login.model';
// import { LoginPostService } from '../../../services/auth/login.post.service';

// interface AuthPostState extends BaseState<LoginResponse> { }

// let initialState: AuthPostState = {
//   data: null,
//   loading: false,
//   error: null,
// };

// export const fetchPostAuth = createAsyncThunk(
//   'fetch/postAuth',
//   async (request: LoginRequest, thunkAPI) => {

//     try {
//       const service = new LoginPostService();
//       const response = await service.PostAsync({
//         userName: request.userName,
//         password: request.password,
//       });
//       return response
//     }

//     catch (err: any) {
//       if (!err.response) {
//         throw err
//       }


//       const errorData=err.response.data
      
//       return thunkAPI.rejectWithValue({
//         error: errorData.error?errorData.error:errorData.message
//       })

//     }





//   }
// );

// export const postAuthSlice = createSlice({
//   name: 'postAuthSlice',
//   initialState,
//   reducers: {},
//   extraReducers(builder) {
//     builder
//       .addCase(fetchPostAuth.pending, (state, action) => {
//         state.loading = true;
//       })
//       .addCase(fetchPostAuth.fulfilled, (state, action) => {
//         state.loading = false;
//         state.data = action.payload;
//       })
//       .addCase(fetchPostAuth.rejected, (state, action: any) => {
//         state.loading = false;
//         state.error = action.payload


//       });
//   },
// });

// export default postAuthSlice.reducer;


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BaseState } from '../../../commons/base.state';
import { LoginRequest, LoginResponse } from '../../../models/auth/login.model';
import { LoginPostService } from '../../../services/auth/login.post.service';

interface AuthPostState extends BaseState<LoginResponse> { }

let initialState: AuthPostState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchPostAuth = createAsyncThunk(
  'fetch/postAuth',
  async (request: LoginRequest, thunkAPI) => {
    try {
      const service = new LoginPostService();
      const response = await service.PostAsync({
        userName: request.userName,
        password: request.password,
        email:request.email,
        isAnyOpenSession:request.isAnyOpenSession
      });
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      const errorData = err.response.data;

      return thunkAPI.rejectWithValue({
        error: errorData.error ? errorData.error : errorData.message,
      });
    }
  }
);

export const postAuthSlice = createSlice({
  name: 'postAuthSlice',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPostAuth.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchPostAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchPostAuth.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetError } = postAuthSlice.actions;

export default postAuthSlice.reducer;
