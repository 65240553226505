import { GetAllFilesModel } from "../../../models/oneDrive/file/get.all.files.model";
import { BaseGetService } from "../../base/base.get.service";




export class GetAllFilesService extends BaseGetService<GetAllFilesModel>{
    constructor(controllerName:string="oneDrive",endpoint:string="files"){
        super(controllerName,endpoint)
    }
}