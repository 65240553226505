import { PutUploadedObjectRequestModel } from "../../models/object/uploadedObject/put.uploadedObject.request.model";
import { PutUploadedObjectResponseModel } from "../../models/object/uploadedObject/put.uploadedObject.response.model";
import { BasePutService } from "../base/base.put.service";



export class PutUploadedObjectService extends BasePutService<PutUploadedObjectRequestModel, PutUploadedObjectResponseModel> {

    async UploadObjectAsync(request: PutUploadedObjectRequestModel) {
        const formData = new FormData();
        formData.append("bucketKey", request.bucketName);

        request.files.forEach((fileObj, index) => {
            formData.append(`files[${index}].file`, fileObj.file); // Dosya olarak eklenir
            formData.append(`files[${index}].rootFileName`, fileObj.rootFileName || ''); // rootFileName string olarak eklenir
        });

        return await this.PutWithFormDataAsync(formData);
    }
}
