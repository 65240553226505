import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetFolderService } from "../../../services/oneDrive/folder/get.folder.service";
import { GetFolderModel, GetFolderRequestModel } from "../../../models/oneDrive/folder/get.folder.model";




interface GetAllFoldersState extends BaseState<GetFolderModel | undefined>{

}


let initialState:GetAllFoldersState={
    data:undefined,
    loading:false,
    error:null
}


export const fetchFolder=createAsyncThunk("fetch/allFolders",async(model:GetFolderRequestModel)=>{
    const folderService=new GetFolderService()
    const response=await folderService.GetByStringManyParamsAsync([model.bucketName,model.folderName])
    return response
})



export const getFoldersSlice=createSlice({
    name:"getAllFoldersSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchFolder.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchFolder.fulfilled,(state,action)=>{
            state.loading=false
            if (action.payload !== undefined) {
                state.data = action.payload;
            }
        })
        .addCase(fetchFolder.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getFoldersSlice.reducer