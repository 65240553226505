
import { useLocation, useNavigate } from 'react-router-dom';
import Unauthorized from '../../components/Unauthorized';
import { TokenResponse } from '../../models/auth/auth.token.model';
import { GetUploadedObjectResponseModel } from '../../models/object/uploadedObject/get.uploadedObject.response.model';
import '../../styles/objectDetails/objectDetails.css';
import { useState } from 'react';
import { Button, Pagination } from 'react-bootstrap'; // Bootstrap pagination component
import SecondHeader from '../../components/Navbar/SecondHeader';
import { LoginResponse } from '../../models/auth/login.model';

const ObjectDetail = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const object = location.state.object as GetUploadedObjectResponseModel;
	const loginInfo = sessionStorage.getItem('login') as string;
	const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10; // Number of items per page
	const handleBackClick = () => {
		navigate('/models');
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	// Pagination logic
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = object.metadata !== undefined ? object.metadata.slice(indexOfFirstItem, indexOfLastItem) : null;

	return loginInfo && jsonLoginInfo.isAuthenticated ? (
		<>
			<div className='object-page-wrapper'>
			
				<div className='object-details'>
					{currentItems && currentItems.map((m, i) => (
						<div className='object-detail' key={i}>
							<div className='detail-card'>
								<div className='card-body'>
									<div className='detail-group'>
										{Object.keys(m.properties).map((val, j) => (
											<div className='detail-group-item' key={j}>
												{typeof m.properties[val] === 'object' ? (
													<div className='biriki'>
														<h4 className='property-name'>{`${val}`}</h4>
														{Object.keys(m.properties[val]).map((val2, k) => (
															<div className='name-and-value' key={k}>
																<span className='name'>{`${val2}`}</span>
																<span className='value'>{`${m.properties[val][val2]}`}</span>
															</div>
														))}
													</div>
												) : (
													<>
														<div className='name-and-value'>
															<span className='name'>{`${val}`} </span>
															<span className='value'>{`${m.properties[val]}`}</span>
														</div>
													</>
												)}
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<Pagination className='pagination-wrapper'>
				{object.metadata && Array.from({ length: Math.ceil(object.metadata.length / itemsPerPage) }, (_, number) => (
					<Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => handlePageChange(number + 1)}>
						{number +1}
					</Pagination.Item>
				))}
			</Pagination>
			{/* <div className='back-button-wrapper'>
				<Button variant='primary' onClick={handleBackClick}>Back to List</Button>
			</div> */}
		</>
	) : (
		<>
			<Unauthorized />
		</>
	);
};

export default ObjectDetail;
