import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import '../../styles/userProfile/userProfile.scss';
import { fetchUserInfo } from '../../store/features/user/get.user.slice';
import { createUserToTeam, resetUserData, resetUserError } from '../../store/features/user/create.team.user.slice';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { LoginResponse } from '../../models/auth/login.model';
import SecondHeader from '../../components/Navbar/SecondHeader';
import { Modal, Button, Form } from 'react-bootstrap';
import { fetchOwnerUsers } from '../../store/features/user/get.owner.users.slice';
import { fetchAdminUsers } from '../../store/features/user/get.admin.users.slice';
import { createUserToAdminTeam, resetAdminData, resetAdminUserError } from '../../store/features/user/create.admin.team.user.slice';
import CircleLoading from '../../components/Loading/CircleLoading';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const UserProfile: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfoSlice = useAppSelector((state: RootState) => state.userInfoSlice);
    const createUserSlice = useAppSelector((state: RootState) => state.createTeamUserSlice);
    const ownerUsersSlice = useAppSelector((state: RootState) => state.getOwnerUsersSlice);
    const adminUsersSlice = useAppSelector((state: RootState) => state.getAdminUsersSlice);
    const createAdminTeamUserSlice = useAppSelector((state: RootState) => state.createAdminTeamUserSlice);
    const userInfo = userInfoSlice.data;
    const login = sessionStorage.getItem('login') as string;
    const jsonLogin = JSON.parse(login) as LoginResponse;
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        role: 'User',
        password: '',
        userName: '',
        adminTeamName: '',
        phoneNumber: ''
    });
    const [phoneNumberValidations, setPhoneNumberValidations] = useState({
        validLength: false,
        startsWithPlus: false,
        validFormat: false
    });
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        phoneNumber: '',
        password: ''
    })

    const passwordRules = [
        {
            text: 'Minimum 6 characters',
            state: /.{6,}/.test(formData.password),
            errorMessage:'Password must be at least 6 characters'
        },
        {
            text: 'A non-alphanumeric character',
            state: /.*[^a-zA-Z\d].*/.test(formData.password),
            errorMessage:'Password must be at least 1 non-alphanumeric character'
        },
        {
            text: 'A lowercase letter',
            state: /[a-z]/.test(formData.password),
            errorMessage:'Password must contain at least 1 lowercase letter'
        },
        {
            text: 'An uppercase letter',
            state: /[A-Z]/.test(formData.password),
            errorMessage:'Password must contain at least 1 uppercase letter'
        },
        {
            text: 'A number',
            state: /\d/.test(formData.password),
            errorMessage:'Password must contain at least 1 number'
        },
    ];

    const phoneRules = [
        {
            text: 'Valid length (min 8 characters)',
            state: phoneNumberValidations.validLength,
        },
        {
            text: 'Valid format',
            state: phoneNumberValidations.validFormat,
        }
        
    ];


    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
   // const nameRegex = /^[a-zA-Z0-9\s]+$/;
   const nameRegex = /^[a-zA-Z0-9\sÇŞĞÜÖİçşğüöı]*$/;


    const handlePhoneChange = (phone: string) => {
        setFormData((prev) => {
            prev.phoneNumber = `+${phone}`
            return prev;
        });
        //validateForm();
        setPhoneNumberValidations({
            validLength: formData.phoneNumber.length >= 8,
            startsWithPlus: formData.phoneNumber.startsWith('+'),
            validFormat: phoneRegex.test(formData.phoneNumber)
        });
    };

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(fetchUserInfo(jsonLogin.uid));
        if (jsonLogin.isOwner) {
            dispatch(fetchOwnerUsers(jsonLogin.uid));
        }
        if (jsonLogin.isAdmin) {
            dispatch(fetchAdminUsers(jsonLogin.uid));
        }
    }, [dispatch, jsonLogin.uid, jsonLogin.isOwner, jsonLogin.isAdmin]);

    useEffect(() => {
        if (createUserSlice.data && createUserSlice.error === null) {
            setIsLoading(false);
            setShowModal(false);
            Swal.fire({
                icon: 'success',
                title: 'User added successfully!',
                showConfirmButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        } else if (createUserSlice.error) {
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Failed to add user',
                text: createUserSlice.error.error,
                showConfirmButton: true,
            });
        }
    }, [createUserSlice, dispatch, jsonLogin]);

    useEffect(() => {
        if (createAdminTeamUserSlice.data && createAdminTeamUserSlice.error === null) {
            setIsLoading(false);
            setShowModal(false);
            Swal.fire({
                icon: 'success',
                title: 'Admin team user added successfully!',
                showConfirmButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        } else if (createAdminTeamUserSlice.error) {
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Failed to add admin team user',
                text: createAdminTeamUserSlice.error.error,
                showConfirmButton: true,
            });
        }
    }, [createAdminTeamUserSlice, dispatch, jsonLogin]);

    useEffect(() => {
        if (!showModal) {
            if (createUserSlice.error) {
                dispatch(resetUserError());
            }
            if (createAdminTeamUserSlice.error) {
                dispatch(resetAdminUserError());
            }
        }
    }, [showModal, createUserSlice.error, createAdminTeamUserSlice.error, dispatch]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const validateForm = () => {
        let formErrors = { firstName: '', lastName: '', userName: '', email: '', phoneNumber: '',password:'' };
        if (!formData.firstName.trim()) {
            formErrors.firstName = 'First name is required';
        } else if (!nameRegex.test(formData.firstName)) {
            formErrors.firstName = 'First name contains invalid characters';
        }else if(/^\d+$/.test(formData.firstName)) {
            return "First Name cannot consist of only numbers";
        }

        if (!formData.lastName.trim()) {
            formErrors.lastName = 'Last name is required';
        } else if (!nameRegex.test(formData.lastName)) {
            formErrors.lastName = 'Last name contains invalid characters';
        }else if(/^\d+$/.test(formData.lastName)) {
            return "Last Name cannot consist of only numbers";
        }

        if (!formData.userName.trim()) {
            formErrors.userName = 'User name is required';
        } else if (!nameRegex.test(formData.userName)) {
            formErrors.userName = 'User name contains invalid characters';
        }else if(/^\d+$/.test(formData.userName)) {
            return "User Name cannot consist of only numbers";
        }

        if (!isValidEmail) {
            formErrors.email = 'Invalid email format';
        }

        if (!phoneRegex.test(formData.phoneNumber)) {
            formErrors.phoneNumber = 'Invalid phone number';
        }

        passwordRules.forEach((passwordRule)=>{
            if(!passwordRule.state){
                formErrors.password=passwordRule.errorMessage
            }
        })

        setErrors(formErrors);
        return Object.values(formErrors).every(error => !error);
    };



    const handleAddUser = async () => {
            setIsLoading(true);
            const login = sessionStorage.getItem('login') as string;
            const jsonLogin = JSON.parse(login) as LoginResponse;
    
            try {
                if (jsonLogin.isOwner) {
                    await dispatch(createUserToTeam({ ...formData, ownerId: jsonLogin.uid })).unwrap();
                    setShowModal(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'User added successfully!',
                        showConfirmButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                }
    
                if (jsonLogin.isAdmin) {
                    await dispatch(createUserToAdminTeam({ ...formData, adminId: jsonLogin.uid })).unwrap();
                    setShowModal(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'User added successfully!',
                        showConfirmButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                }
            } catch (error) {
                setIsLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to add user',
                    text: createUserSlice.error ? createUserSlice.error.error : createAdminTeamUserSlice.error.error,
                    showConfirmButton: true,
                });
            }
        
      
    };



    return (
        <div>
            <div className="user-profile-grid">
                {userInfo && userInfoSlice.error === null && (
                    <div className="profile-info">
                        <h2>User Profile</h2>
                        <p><strong>UserName:</strong>&nbsp;{userInfo.userName}</p>
                        <p><strong>FullName:</strong>&nbsp;{userInfo.fullName}</p>
                        <p><strong>Email:</strong>&nbsp;{userInfo.email}</p>
                        <p><strong>PhoneNumber:</strong>&nbsp;{userInfo.phoneNumber}</p>
                        <p><strong>CompanyName:</strong>&nbsp;{userInfo.companyName}</p>
                        <p><strong>Subscription Type:</strong>&nbsp;{userInfo.subscriptionType}</p>
                        {(jsonLogin.isAdmin && adminUsersSlice.data) && (
                            <p><strong>Team Name:</strong>&nbsp;{adminUsersSlice.data.teamName}</p>
                        )}
                        {(userInfo && jsonLogin && (jsonLogin.isOwner || jsonLogin.isAdmin)) && (
                            <Button variant="primary" onClick={() => setShowModal(true)}>
                                Add User
                            </Button>
                        )}
                        {
                            (userInfo && jsonLogin && (jsonLogin.isOwner || jsonLogin.isAdmin)) && (
                                <Button variant="success" onClick={() => {
                                    navigate("/dashboard")
                                }}>Go to Dashboard</Button>
                            )
                        }
                    </div>
                )}
                {ownerUsersSlice.data && (
                    <div className="team-members">
                        <h2>Owner's Users</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>FullName</th>
                                    <th>UserName</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>TeamName</th>
                                    <th>UsedCredit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ownerUsersSlice.data.subUsers.map((member, i) => (
                                    <tr key={i}>
                                        <td>{member.fullName}</td>
                                        <td>{member.userName}</td>
                                        <td>{member.email}</td>
                                        <td>{member.roleName}</td>
                                        <td>{member.teamName == null ? "No Team" : member.teamName}</td>
                                        <td>{member.usedCredit}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {adminUsersSlice.data && (
                    <div className="team-members">
                        <h2>Admin's Users</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>FullName</th>
                                    <th>UserName</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>UsedCredit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {adminUsersSlice.data.subUsers.map((member, i) => (
                                    <tr key={i}>
                                        <td>{member.fullName}</td>
                                        <td>{member.userName}</td>
                                        <td>{member.email}</td>
                                        <td>{member.roleName}</td>
                                        <td>{member.usedCredit}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            <Modal show={showModal} onHide={() => {
                setShowModal(false);
                dispatch(resetUserError());
                dispatch(resetAdminUserError());
                dispatch(resetUserData())
                dispatch(resetAdminData())
                setFormData({
                    firstName: '',
                    lastName: '',
                    userName: '',
                    email: '',
                    role: 'User',
                    password: '',
                    phoneNumber: '',
                    adminTeamName: ''
                })
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? (
                        <CircleLoading text="Adding User.." />
                    ) : (
                        <Form>
                            <Form.Group>
                                <Form.Label>FirstName</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    onBlur={()=>{
                                        validateForm()
                                    }}
                                    isInvalid={!!errors.firstName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.firstName}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>LastName</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    onBlur={()=>{
                                        validateForm()
                                    }}
                                    isInvalid={!!errors.lastName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.lastName}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>UserName</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="userName"
                                    value={formData.userName}
                                    onChange={handleInputChange}
                                    onBlur={()=>{
                                        validateForm()
                                    }}
                                    isInvalid={!!errors.userName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.userName}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    onBlur={()=>{
                                        validateForm()
                                    }}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>PhoneNumber</Form.Label>
                                <PhoneInput
                                    country={undefined}
                                    value={`+${formData.phoneNumber}`}
                                    onChange={handlePhoneChange}
                                    onBlur={()=>{
                                        validateForm()
                                    }}
                                    inputStyle={{ width: '100%' }}
                                    isValid={() => phoneNumberValidations.validFormat}

                                />
                                <ul className='phone-rule-list'>
                                    {phoneRules.map((rule, index) => (
                                        <li
                                            className='rule'
                                            key={index}
                                        >
                                            <span
                                                style={{
                                                    color: rule.state ? 'green' : 'red',
                                                    marginRight: '0.3rem',
                                                }}
                                            >
                                                {rule.state ? '✔' : '•'}
                                            </span>
                                            {rule.text}
                                        </li>
                                    ))}
                                </ul>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Role</Form.Label>
                                <Form.Select
                                    name="role"
                                    value={formData.role}
                                    onChange={handleSelectChange}
                                >
                                    <option>User</option>
                                    <option>Admin</option>
                                </Form.Select>
                            </Form.Group>
                            {formData.role === "Admin" && (
                                <Form.Group>
                                    <Form.Label>Team Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="adminTeamName"
                                        value={formData.adminTeamName}
                                        onChange={handleInputChange}

                                    />
                                </Form.Group>
                            )}
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}  
                                    onBlur={()=>{
                                        validateForm()
                                    }}
                                    isInvalid={!!errors.password}
                                />
                                 <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                                <ul className='password-rule-list'>
                                    {passwordRules.map((rule, index) => (
                                        <li
                                            className='rule'
                                            key={index}
                                        >
                                            <span
                                                style={{
                                                    color: rule.state ? 'green' : 'red',
                                                    marginRight: '0.3rem',
                                                }}
                                            >
                                                {rule.state ? '✔' : '•'}
                                            </span>
                                            {rule.text}
                                        </li>
                                    ))}
                                </ul>
                               
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                {!isLoading && (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            setShowModal(false);
                            dispatch(resetUserError());
                            dispatch(resetAdminUserError());
                            dispatch(resetUserData())
                            dispatch(resetAdminData())
                            setFormData({
                                firstName: '',
                                lastName: '',
                                userName: '',
                                email: '',
                                role: 'User',
                                password: '',
                                phoneNumber: '',
                                adminTeamName: ''
                            })

                            setErrors({
                                firstName: '',
                                lastName: '',
                                userName: '',
                                email: '',
                                phoneNumber: '',
                                password:''
                            })
                        }}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleAddUser} disabled={
                            (formData.firstName==="" ||
                            formData.lastName==="" ||
                            formData.userName==="" ||
                            formData.email==="" ||
                            formData.password==="" || formData.phoneNumber.length<8 ) || Object.values(errors).filter(err => err!=="").length>0
                            }>
                            Add User
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        </div>
    );
}

export default UserProfile;

