import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { Admin} from "../../../models/user/admin.users.model";
import { GetAdminUsersService } from "../../../services/user/get.admin.users.service";


interface GetAdminUsersState extends BaseState<Admin> {}

const initialState: GetAdminUsersState = {
    data: null,
    loading: false,
    error: null
};

export const fetchAdminUsers = createAsyncThunk("fetch/AdminUsers", async (id: string) => {
    const service = new GetAdminUsersService()
    const response = await service.GetAdminUsersAsync(id)
    return response;
});

export const getAdminUsersSlice = createSlice({
    name: "getAdminUsersSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdminUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAdminUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchAdminUsers.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default getAdminUsersSlice.reducer;
