import React from 'react';
import '../../styles/onedrive/multipleUploadModel.css'
import { Button, Form, Modal } from 'react-bootstrap';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { useEffect, useState, useRef } from 'react';
import { OneDriveFileData } from '../../pages/oneDrive/FileList';
import { LinearWithValueLabel } from '../LinearProgressBar';
import { resetUploadState, uploadFilesToForgeSecondary } from '../../store/features/oneDrive/upload.files.to.forge.secondary.slice';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { baseUrl } from '../../config/axios.config';
import { SelectableZipFiles } from '../../pages/object/ObjectList';
import { GetIAMFilesModelResponse, SubIAMFilesModel } from '../../models/oneDrive/file/get.iam.files.model';
import CircleLoading from '../Loading/CircleLoading';
import { Box, Checkbox, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ExpandMore, InsertDriveFile, Close } from '@mui/icons-material';

interface AddedFileListProps {
    file: string;
    bucket: string | undefined
    id: string;
    handleRemoveClick: () => void;
    fileIds: string[]
}

interface MultipleOneDriveUploadModalProps {
    show: boolean;
    onHide: () => void;
    fileList: OneDriveFileData[];
    setFileList: React.Dispatch<React.SetStateAction<OneDriveFileData[]>>;
    bucket: string | undefined;
    zipOption:boolean
    setOpenZipOption: React.Dispatch<React.SetStateAction<boolean>>;
    //setShowTranslationModal: React.Dispatch<React.SetStateAction<boolean>>;
    zipFile:SelectableZipFiles|undefined;
    setZipFile: React.Dispatch<React.SetStateAction<SelectableZipFiles|undefined>>;
    // processZipFile: (file: File) => Promise<void>
    iamFiles:GetIAMFilesModelResponse|undefined
}

const AddedFileList: React.FC<AddedFileListProps> = ({
    file,
    bucket,
    id,
    handleRemoveClick,
    fileIds
}) => {
    const [isPrompt, setIsPrompt] = useState(false);
	const [folder, setFolder] = useState({
		name: "",
		id: "",
		check: false
	})

    const dispatch = useAppDispatch();

    return (
        <>
            {id && file && (
                <div className='addedFile'>
                    <li id={`${id}`}>{file} </li>
                </div>
            )}
        </>
    );
};

export const MultipleOneDriveUploadModal: React.FC<MultipleOneDriveUploadModalProps> = ({
    show,
    onHide,
    fileList,
    setFileList,
    bucket,
    setOpenZipOption,
    zipOption,
    setZipFile,
    zipFile,
    iamFiles
}) => {
    const dispatch = useAppDispatch();
    const uploadIndexRef = useRef(0);

    const uploadOneDriveFilesSelector = useAppSelector(
        (s: RootState) => s.uploadFilesToForgeSecondary
    );
    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState("");
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const [rootFile,setRootFile] = useState("")
    const [isVisible, setIsVisible] = useState(false);
    const fetchIAMFiles=useAppSelector((s: RootState) =>s.getIAMFilesSlice)
    const [selectedFile, setSelectedFile] = useState({
        name:"",
        size:""
    });
    console.log(zipOption)
    useEffect(() => {
        
        const connection = new HubConnectionBuilder()
            .withUrl(`${baseUrl}progress`)
            .withAutomaticReconnect()
            .build();

        setConnection(connection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.on('ReceiveProgress', (progressValue, stepValue) => {
                console.log(progressValue)
                console.log(stepValue)
                setProgress(progressValue || 0);
                setStep(stepValue || '');
            });

            return () => {
                if (isConnected) {
                    connection
                        .stop()
                        .then(() => {
                            console.log("Connection successfully stopped.")
                        });
                }
            };
        }
    }, [connection, isConnected]);

    const uploadFilesSequentially = (fileIds: string[], bucketKey: string,rootFileNames:string[]) => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    console.log('Connection successfully established.');
                    setIsConnected(true);

                    const uploadNextFile = () => {
                        if (uploadIndexRef.current < fileIds.length) {
                            const fileId = fileIds[uploadIndexRef.current];
                            dispatch(
                                uploadFilesToForgeSecondary({
                                    fileIds: [fileId],
                                    bucketKey,
                                    rootFileNames
                                    
                                })
                            ).then(() => {
                                uploadIndexRef.current++;
                                uploadNextFile();
                            });
                        }
                    };

                    // İlk dosyayı başlat
                    uploadNextFile();
                })
                .catch((err) =>
                    console.log('Error while establishing the connection: ', err)
                );
        }
    };

    useEffect(() => {
        const { loading: uploadLoading, error: uploadError, data: uploadData } = uploadOneDriveFilesSelector;

        if (!uploadLoading && uploadData && uploadData.isSuccess) {
            if (uploadIndexRef.current === fileList.length - 1) {
                console.log("All files uploaded successfully!");
                setFileList([]);
               //dispatch(resetUploadState());
            } else {
             
                uploadFilesSequentially(fileList.map((file) => file.id), bucket as string,[rootFile]);
            }
        } else if (!uploadLoading && uploadError) {
            console.error("Error uploading file:", uploadError);
          // dispatch(resetUploadState());
        }
    }, [uploadOneDriveFilesSelector, fileList, bucket, onHide, setFileList]);

    const handleSelectFileAsRoot=(name:string,size:number, fileName: string,id:string)=> {
        setFileList((prev)=>{
            return [...prev,{
                name:fileName,
                size,
                id:id,
                rootFileName:name
            }]
        })
        
        setOpenZipOption(false)
    }

    const handleselectedFile:any = (file:any) => {
        console.log(file)
        setSelectedFile(file)
    }

    return (
        <Modal
            show={show}
            onShow={()=>{
                if(uploadOneDriveFilesSelector.error!==null){
                    dispatch(resetUploadState());
                }
            }}
            onHide={() => {
                console.log(uploadOneDriveFilesSelector)
                if(uploadOneDriveFilesSelector.error===null && uploadOneDriveFilesSelector.loading===false &&
                     (uploadOneDriveFilesSelector.data!==undefined || uploadOneDriveFilesSelector.data!==null)){
                    window.location.reload();   
                    onHide();
                }
                else{
                   
                    onHide();
                 }        
            }}
            backdrop={uploadOneDriveFilesSelector.loading?"static":true}
            keyboard={!uploadOneDriveFilesSelector.loading}
        >
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {
                    fetchIAMFiles.loading ? (
                        <div className="text-center">
                            <CircleLoading text='Extracting all iam files from zip'/>
                        </div>
                    ):(
                        <Form>
                        <Form.Group>
                            {
                                iamFiles!==undefined && iamFiles.iamFiles.length>0 && rootFile==="" ?(
                                    // <div className='zipFile'>
                                    //     <h4>{iamFiles.mainZipFileName}</h4>
                                    //     <div className='zip-file-item'>
                                    //         {iamFiles.iamFiles.map((zipSubFile,i)=>(
                                    //             <li key={i}>
                                    //                 <label>
                                    //                     <input type='checkbox'onClick={() =>{
                                    //                         const splitList=zipSubFile.name.split("/")
                                    //                         console.log(splitList)
                                    //                         const newRootFileName=splitList[splitList.length - 1]
                                    //                         console.log(newRootFileName)
    
                                    //                         setRootFile(newRootFileName)
                                    //                         setOpenZipOption(false)
                                    //                     }}
                                    //                             />
                                    //                             {zipSubFile.name.split("/")[zipSubFile.name.split("/").length - 1]} &nbsp;
                                    //                 </label>
                                    //             </li>
                                    //         ))}
                                    //     </div>
                                    // </div>
                                    <Box className="bg-white rounded-lg shadow-md p-6 min-w-[400px] max-w-2xl mx-auto-overflow-y-hidden">
										<Box className="flex items-center justify-between mb-4">
											<Typography variant="h5" component="h2" className="font-semibold text-gray-800">
												Selected files
											</Typography>

										</Box>
										<Box className="mb-4">
											<Typography variant="subtitle1" className="font-medium text-gray-700">
												Main IAM file{!selectedFile.name && <span className='text-xs text-red-400'>{" ("}Please select the main IAM file.{")"}</span>}
											</Typography>
											{selectedFile.name  &&  <Box className="flex items-center justify-between p-2 bg-gray-50 rounded">
											<Box className="flex items-center">
													<Checkbox
														edge="start"
														checked={true}
														tabIndex={-1}
														disableRipple
														className="text-blue-500"
													/>
													<InsertDriveFile className="text-gray-400 mr-2" />
													 <Typography variant="body2">{selectedFile.name.split("/")[selectedFile.name.split("/").length-1]}</Typography> 
												</Box>
												<Box className="flex items-center">
													<Typography variant="body2" className="text-gray-500 mr-2">
														{selectedFile.size} 
													</Typography>
												</Box>
											</Box>}
										</Box>
										<Box className="mb-4">
											<Typography variant="subtitle1" className="font-medium text-gray-700 mb-2">
												Referans dosyalar {iamFiles.iamFiles.length}
											</Typography>
											<List className="divide-y divide-gray-200 max-h-[50vh] overflow-auto">
												{iamFiles.iamFiles.map((file, i) => (
													selectedFile.name != file.name &&
													<ListItem
														key={i}
														dense
														button
														onClick={()=>handleselectedFile(file)}
														className="py-1"
													>
														<ListItemIcon>
															<Checkbox
																edge="start"
																tabIndex={-1}
																disableRipple
																
																checked={selectedFile.name == file.name}
																className="text-blue-500"
															/>
														</ListItemIcon>
														<ListItemIcon>
															<InsertDriveFile className="text-gray-400" />
														</ListItemIcon>
														<ListItemText
															primary={file.name.split('/')[file.name.split('/').length-1]}
															className="text-sm"
														/>
														<Typography variant="body2" className="text-gray-500">
															{file.size} 
														</Typography>
													</ListItem>
												))}
											</List>
										</Box>
										<Box className="flex flex-col items-center">
											<Button
												variant="contained"
												color="primary"
												disabled={selectedFile?.name?.length < 1}
												className="w-full max-w-xs"
												onClick={() =>{
                                                    //handleSelectFileAsRoot(zipFile.mainZipFile, selectedFile.fileName)

                                                             const splitList=selectedFile?.name?.split("/")
                                                            //  console.log(splitList)
                                                             const newRootFileName=splitList[splitList?.length - 1]
                                                            // console.log(newRootFileName)
    
                                                             setRootFile(newRootFileName)
                                                             setOpenZipOption(false)
                                                             setIsVisible(true);

                                                    console.log(selectedFile)
                                                }}
											>
												CONTINUE
											</Button>
										</Box>
									</Box>
                                ):(<><Form.Label>{fileList.length > 1 ? "Files" : "File"}</Form.Label>
                                    
                                    {fileList.map((file) => (
                                        <AddedFileList
                                            key={file.id}
                                            fileIds={fileList.map(file => file.id)}
                                            file={file.name || ""}
                                            handleRemoveClick={() => {
                                                const index = fileList.indexOf(file);
                                                if (index > -1) {
                                                    if (index === 0) {
                                                        setFileList([]);
                                                    } else {
                                                        setFileList((prev) => {
                                                            prev = prev.splice(index, 1);
                                                            return prev;
                                                        });
                                                    }
                                                }
                                            }}
                                            id={file.id || ""}
                                            bucket={bucket}
                                        />
                                    ))}</>)
                            }
                          
                        </Form.Group>
                    </Form>
                    )
                }

                {/* iamFiles==undefined ?false:true
                */}
            </Modal.Body>
            <Modal.Footer>
                {progress === 100 && !uploadOneDriveFilesSelector.loading && (
                        <>
                            {fileList.length > 1 ? (
                                <p style={{ color: "green", marginRight: "auto" }}>
                                    Files have been uploaded succesfully
                                </p>
                            ) : (
                                    <p style={{ color: "green", marginRight: "auto" }}>
                                        File has been uploaded successfully
                                    </p>
                                )}
                        </>
                    )}
                {((bucket && isVisible) || (!iamFiles)) &&(
                    <Button
                        className={`uploadButton`}
                        disabled={uploadOneDriveFilesSelector.loading || fileList.length === 0 || selectedFile.name.length < 1}
                        variant={uploadOneDriveFilesSelector.loading ? "secondary" : "primary"}
                        hidden={progress === 100}
                        onClick={() => {
                            uploadFilesSequentially(fileList.map((file) => file.id), bucket as string,[rootFile]);
                        }}
                    >
                        Translate {fileList.length > 1 ? "Files" : "File"}
                    </Button>
                )}

                {uploadOneDriveFilesSelector.loading && (
                    <LinearWithValueLabel progress={progress} step={step} />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default MultipleOneDriveUploadModal;
