import { GetFileModel } from "../../../models/oneDrive/file/get.file.model";
import { GetRecentFilesModel } from "../../../models/oneDrive/file/get.recent.files.model";
import { BaseGetService } from "../../base/base.get.service";



export class GetRecentFilesService extends BaseGetService<GetRecentFilesModel>{

    constructor(controllerName:string="oneDrive",endpoint:string="files/getRecent"){
        super(controllerName,endpoint)
    }
}