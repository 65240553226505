import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { CreateTeamUserModel } from "../../../models/user/create.team.user.model";
import { CreateUserToTeamService } from "../../../services/user/create.team.user.service";

interface CreateTeamUserState extends BaseState<any> {}

const initialState: CreateTeamUserState = {
    data: null,
    loading: false,
    error: null
};

export const createUserToTeam = createAsyncThunk("create/UserToTeam", async (model: CreateTeamUserModel,thunkAPI) => {
    try{
        const service = new CreateUserToTeamService();
        const response = await service.CreateUserAsync(model);
        return response;
    }
    catch(err:any){
        if (!err.response) {
            throw err
          }
          const errorData=err.response.data
          return thunkAPI.rejectWithValue({
            error: errorData.error?errorData.error:errorData.message
          })
    }   
});

export const createTeamUserSlice = createSlice({
    name: "createTeamUserSlice",
    initialState,
    reducers: {
        resetUserError: (state) => {
            state.error = null;
        },
        resetUserData:(state)=>{
            state.data=null;
            state.error=null;
            state.loading=false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createUserToTeam.pending, (state) => {
                state.loading = true;
            })
            .addCase(createUserToTeam.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(createUserToTeam.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { resetUserError,resetUserData } = createTeamUserSlice.actions;
export default createTeamUserSlice.reducer;
