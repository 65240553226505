// import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { BaseState } from "../../../commons/base.state";
// import { UploadOneDriveFilesToForgeSecondaryResponseModel } from "../../../models/oneDrive/file/upload.oneDrive.files.forge.secondary.response.model";
// import { UploadOneDriveFilesToForgeRequestModel } from "../../../models/oneDrive/file/upload.oneDrive.files.forge.request.model";
// import { UploadOneDriveFilesToForgeSecondaryService } from "../../../services/oneDrive/file/upload.to.forge.files.secondary.service";



// interface UploadFilesToForgeSecondaryState extends BaseState<UploadOneDriveFilesToForgeSecondaryResponseModel|undefined>{

// }


// let initialState:UploadFilesToForgeSecondaryState={
//     data:null,
//     loading:false,
//     error:null
// }



// export const uploadFilesToForgeSecondary=createAsyncThunk("upload/forge/secondary",
// async(model:UploadOneDriveFilesToForgeRequestModel,thunkAPI)=>{
//     try{
//         const uploadForgeSecondaryService=new UploadOneDriveFilesToForgeSecondaryService()
//         const response=await uploadForgeSecondaryService.PutAsync(model)
//         return response
//     }
//     catch(err:any){
//         if (!err.response) {
//             throw err
//           }
    
//           console.log(err)
    
//           const errorData=err.response.data
          
//           return thunkAPI.rejectWithValue({
//             error: errorData.error?errorData.error:errorData.message
//           })
//     }
  
// })


// export const uploadFilesToForgeSecondarySlice=createSlice({
//     name:"uploadFilesToForgeSlice",
//     initialState,
//     reducers:{},
//     extraReducers(builder){
//         builder
//         .addCase(uploadFilesToForgeSecondary.pending,(state,action)=>{
//             state.loading=true
//         })
//         .addCase(uploadFilesToForgeSecondary.fulfilled,(state,action)=>{
//             state.loading=false
//             state.data=action.payload
//         })
//         .addCase(uploadFilesToForgeSecondary.rejected,(state,action:PayloadAction<any>)=>{
//             state.loading=false
//             state.error=action.payload
//         })
//     }
// })


// export default uploadFilesToForgeSecondarySlice.reducer


import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UploadOneDriveFilesToForgeSecondaryResponseModel } from "../../../models/oneDrive/file/upload.oneDrive.files.forge.secondary.response.model";
import { UploadOneDriveFilesToForgeRequestModel } from "../../../models/oneDrive/file/upload.oneDrive.files.forge.request.model";
import { UploadOneDriveFilesToForgeSecondaryService } from "../../../services/oneDrive/file/upload.to.forge.files.secondary.service";

interface UploadFilesToForgeSecondaryState extends BaseState<UploadOneDriveFilesToForgeSecondaryResponseModel|undefined> {}

let initialState: UploadFilesToForgeSecondaryState = {
    data: null,
    loading: false,
    error: null
}

export const uploadFilesToForgeSecondary = createAsyncThunk("upload/forge/secondary",
    async(model: UploadOneDriveFilesToForgeRequestModel, thunkAPI) => {
        try {
            const uploadForgeSecondaryService = new UploadOneDriveFilesToForgeSecondaryService();
            const response = await uploadForgeSecondaryService.PutAsync(model);
            return response;
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }

            console.log(err);

            const errorData = err.response.data;
            
            return thunkAPI.rejectWithValue({
                error: errorData.error ? errorData.error : errorData.message
            });
        }
    }
);

export const uploadFilesToForgeSecondarySlice = createSlice({
    name: "uploadFilesToForgeSlice",
    initialState,
    reducers: {
        resetUploadState: (state) => {
            state.data = null;
            state.loading = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFilesToForgeSecondary.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(uploadFilesToForgeSecondary.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(uploadFilesToForgeSecondary.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { resetUploadState } = uploadFilesToForgeSecondarySlice.actions;

export default uploadFilesToForgeSecondarySlice.reducer;
