import axios from "axios";
import http from "../../config/axios.config";
import { BaseModel } from "../../models/base/base.model";
import { BaseService } from "./base.service";




export class BasePutService<TRequest extends BaseModel, TResponse extends BaseModel> extends BaseService {
    async PutAsync(requestBody: TRequest) {
        const request = http(this._controllerName, this._endpointName)
        try{
            var res= await request.put<TResponse>("", requestBody)
            return res.data
        }
        catch(e:any){
            if(axios.isAxiosError(e)){
                console.log(e)
                console.log(e.message)
            }
        }
    
    }

    async PutAsyncWithData(requestBody:TRequest){
        const request = http(this._controllerName, this._endpointName)
        const res=await request.put<TResponse>("", requestBody)
        return res.data
    
    }


    async PutWithFormDataAsync(formData: FormData) {
        const request = http(this._controllerName, this._endpointName)
        const res=await request.put<TResponse>("", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })

        return res.data
    }
}