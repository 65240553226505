import { DeleteFolderModel } from "../../../models/oneDrive/folder/delete.folder.model";
import { BaseDeleteService } from "../../base/base.delete.service";




export class DeleteFolderService extends BaseDeleteService<DeleteFolderModel>{

    constructor(controllerName:string="oneDrive",endpoint:string="folders"){
        super(controllerName,endpoint)
    }
}