import { GetTokenModel } from "../../models/auth/get.token.model";
import { BaseGetService } from "../base/base.get.service";




export class TokenGetService extends BaseGetService<GetTokenModel>{
    constructor(controllerName:string="auth",endpointName:string="token"){
        super(controllerName,endpointName)
    }
}