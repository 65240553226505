import {createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BaseState } from '../../../commons/base.state';
import { MicrosoftGetTokenService } from '../../../services/auth/microsoft/microsoft.get.token.service';
import { MicrosoftLoginRequest, MicrosoftLoginResponse } from '../../../models/auth/microsoft/microsoft.login.model';

interface MicrosoftAuthPostState extends BaseState<MicrosoftLoginResponse> {
  clickToOneDrive:boolean
}

let initialState: MicrosoftAuthPostState= {
  data: null,
  loading: false,
  error: null,
  clickToOneDrive:false
};

export const fetchMicrosoftAuth = createAsyncThunk(
  'fetch/postAuth',
  async (model:MicrosoftLoginRequest, thunkAPI) => {

    try {
      const service = new MicrosoftGetTokenService()
      const response = await service.GetByQueryParameter(`code=${model.code}`)
      return response
    }

    catch (err: any) {
      if (!err.response) {
        throw err
      }


      const errorData=err.response.data
      
      return thunkAPI.rejectWithValue({
        error: errorData.error?errorData.error:errorData.message
      })

    }





  }
);

export const microsoftAuthSlice = createSlice({
  name: 'microsoftAuthSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMicrosoftAuth.pending, (state, action) => {
        state.clickToOneDrive=true
        state.loading = true;
      })
      .addCase(fetchMicrosoftAuth.fulfilled, (state, action) => {
        state.clickToOneDrive=false
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchMicrosoftAuth.rejected, (state, action: any) => {
        state.clickToOneDrive=false
        state.loading = false;
        state.error = action.payload
      });
  },
});

export default microsoftAuthSlice.reducer
