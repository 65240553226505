import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { CreateAdminUserToTeamService } from "../../../services/user/create.team.admin.user.service";
import { CreateAdminUserModel } from "../../../models/user/create.team.user.model";


interface CreateAdminTeamUserState extends BaseState<any> { }

const initialState: CreateAdminTeamUserState = {
    data: null,
    loading: false,
    error: null
};

export const createUserToAdminTeam = createAsyncThunk("create/UserToAdminTeam", async (model: CreateAdminUserModel, thunkAPI) => {
    try {
        const service = new CreateAdminUserToTeamService()
        const response = await service.CreateUserAsync(model);
        return response;
    }
    catch (err: any) {
        if (!err.response) {
            throw err
        }
        const errorData = err.response.data
        return thunkAPI.rejectWithValue({
            error: errorData.error ? errorData.error : errorData.message
        })
    }

});

export const createAdminTeamUserSlice = createSlice({
    name: "createAdminTeamUserSlice",
    initialState,
    reducers: {
        resetAdminUserError: (state) => {
            state.error = null;
        },
        resetAdminData:(state)=>{
            state.data=null;
            state.error=null;
            state.loading=false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createUserToAdminTeam.pending, (state) => {
                state.loading = true;
            })
            .addCase(createUserToAdminTeam.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(createUserToAdminTeam.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { resetAdminUserError,resetAdminData } = createAdminTeamUserSlice.actions;

export default createAdminTeamUserSlice.reducer;
