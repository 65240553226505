import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UploadOneDriveFilesToForgeResponseModel } from "../../../models/oneDrive/file/upload.oneDrive.files.forge.response.model";
import { UploadOneDriveFilesToForgeRequestModel } from "../../../models/oneDrive/file/upload.oneDrive.files.forge.request.model";
import { UploadOneDriveFilesToForgeService } from "../../../services/oneDrive/file/upload.to.forge.files.service";



interface UploadFilesToForgeState extends BaseState<UploadOneDriveFilesToForgeResponseModel>{

}


let initialState:UploadFilesToForgeState={
    data:null,
    loading:false,
    error:null
}


export const uploadFilesToForge=createAsyncThunk("upload/forge",
async(model:UploadOneDriveFilesToForgeRequestModel)=>{
    const uploadForgeService=new UploadOneDriveFilesToForgeService()
    const response=await uploadForgeService.PostAsync(model)
    return response
})


export const uploadFilesToForgeSlice=createSlice({
    name:"uploadFilesToForgeSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(uploadFilesToForge.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(uploadFilesToForge.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(uploadFilesToForge.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})

export default uploadFilesToForgeSlice.reducer