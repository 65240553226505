import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { CopyItemRequestModel, CopyItemResponseModel } from "../../../models/oneDrive/copy.item.model";
import { CopyItemService } from "../../../services/oneDrive/copy.item.service";



interface CopyItemState extends BaseState<CopyItemResponseModel>{

}


let initialState:CopyItemState={
    data:null,
    loading:false,
    error:null
}


export const copyItem=createAsyncThunk("copyItem",async(request:CopyItemRequestModel)=>{
    const service=new CopyItemService()
    const response=await service.PatchAsyncWithStringParams([request.itemId,request.name])
    return response
})


export const copyItemSlice=createSlice({
    name:"copyItemSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(copyItem.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(copyItem.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(copyItem.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})


export default copyItemSlice.reducer