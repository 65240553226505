import React, { useEffect, useState } from 'react';
import '../styles/PhoneVerification/PhoneVerification.css'; // CSS dosyasını import ediyoruz
import SecondHeader from '../components/Navbar/SecondHeader'; // Header bileşenini import ediyoruz
import { useAppDispatch, useAppSelector } from '../store';
import { fetchVerifyPhoneNumber, resetOtpError } from '../store/features/verifyPhone/verify.phone.slice';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';

const PhoneVerification = () => {
  const [code, setCode] = useState('');
  const dispatch = useAppDispatch();
  const verifyPhoneSelector = useAppSelector((x) => x.verifyPhoneSlice);
  const location=useLocation()

  const handleSubmit = () => {
    console.log(location.state)
    const userId=location.state.userId
    dispatch(fetchVerifyPhoneNumber({ userId,code}))
  };


  useEffect(() => {
    if (verifyPhoneSelector.data && verifyPhoneSelector.data.isSuccess) {
      Swal.fire('Verification successful', 'Your phone number has been verified.', 'success').then(()=>{});
    } else if (verifyPhoneSelector.error) {
      Swal.fire('Verification failed', verifyPhoneSelector.error.message, 'error');
      dispatch(resetOtpError());
    }
  }, [verifyPhoneSelector.data, verifyPhoneSelector.error, dispatch]);
  
  return (
    <>
    <div className="phone-verification-container">
      
      <div className="card">
        <h2>Phone Verification</h2>
        <input 
          type="text" 
          placeholder="Doğrulama kodunu girin" 
          className="input-field"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <button className="submit-button" onClick={(e)=>{
          e.preventDefault()
          handleSubmit()
        }}>Send</button>
      </div>
    </div>
    </>
    
  );
}

export default PhoneVerification;
