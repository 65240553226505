import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { CreateBetaUserModel, CreateBetaUserResponseModel } from "../../../models/betaUser/beta.user.model";
import { BetaUserPostService } from "../../../services/betaUser/betaUser.service";




interface CreateBetaUserState extends BaseState<CreateBetaUserResponseModel>{

}

let initialState:CreateBetaUserState={
    data:null,
    loading:false,
    error:null
}

export const fetchBetaUser=createAsyncThunk("fetch/BetaUser",
async(request:CreateBetaUserModel,thunkAPI)=>{

    try{
        const service=new BetaUserPostService()
        const response=await service.PostAsync({
            email:request.email,
            lastName:request.lastName,
            firstName:request.firstName,
            companyName:request.companyName,     
        })
    
        return response
    }
    catch(err:any){
        if (!err.response) {
            throw err
          }
    
    
          const errorData=err.response.data
          
          return thunkAPI.rejectWithValue({
            error: errorData.message
          })
    }
})


export const CreateBetaUserSlice=createSlice({
    name:"createBetaUserSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchBetaUser.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchBetaUser.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchBetaUser.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})

export default CreateBetaUserSlice.reducer