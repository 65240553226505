import { UploadAWSPrevFilesRequestModel, UploadAWSPrevFilesResponseModel } from "../../models/aws/upload.prev.aws.model";
import { BasePutService } from "../base/base.put.service";



export class UploadPrevAWSService extends BasePutService<UploadAWSPrevFilesRequestModel,UploadAWSPrevFilesResponseModel>{
    
    constructor(controllerName:string="aws",endpoint:string="upload"){
        super(controllerName,endpoint)
    }

    async UploadWithFormDataAsync(model: UploadAWSPrevFilesRequestModel) {
        const formData=new FormData()
        formData.append('bucketName',model.bucketName)
        model.files.map((file)=>{
            formData.append(`files`, file)
            
        })    
        return await this.PutWithFormDataAsync(formData)
    }
}