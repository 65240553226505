import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseState } from '../../../commons/base.state';
import { VerifyPhoneNumberRequestModel, VerifyPhoneNumberResponseModel } from '../../../models/verifyPhone/verify.phone.model';
import { VerifyPhonePostService } from '../../../services/verifyPhone/verify.phone.service';

interface VerifyPhoneNumberState extends BaseState<VerifyPhoneNumberResponseModel> { }

const initialState: VerifyPhoneNumberState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchVerifyPhoneNumber = createAsyncThunk(
  'user/fetchVerifyPhoneNumber',
  async (request: VerifyPhoneNumberRequestModel, thunkAPI) => {
    try {
      const service = new VerifyPhonePostService();
      const response = await service.PostAsync(request);
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      const errorData = err.response.data;
      return thunkAPI.rejectWithValue({
        error: errorData.error ? errorData.error : errorData.message,
      });
    }
  }
);

const verifyPhoneNumberSlice = createSlice({
  name: 'verifyPhoneNumber',
  initialState,
  reducers: {
    resetOtpError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVerifyPhoneNumber.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVerifyPhoneNumber.fulfilled, (state, action: PayloadAction<VerifyPhoneNumberResponseModel>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchVerifyPhoneNumber.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetOtpError } = verifyPhoneNumberSlice.actions;
export default verifyPhoneNumberSlice.reducer;
