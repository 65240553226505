import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UploadGoogleDrivePrevFilesRequestModel, UploadGoogleDrivePrevFilesResponseModel } from "../../../models/googleDrive/upload.prev.files.model";
import { UploadPrevGoogleDriveFilesService } from "../../../services/googleDrive/upload.prev.googleDrive.files.service";



interface UploadPrevFilesState extends 
BaseState<UploadGoogleDrivePrevFilesResponseModel>{

}


let initialState:UploadPrevFilesState={
    data:null,
    loading:false,
    error:null
}



export const uploadGoogleDrivePrevFiles=createAsyncThunk("upload/prevGoogleDriveFiles",
async(model:UploadGoogleDrivePrevFilesRequestModel)=>{

    const uploadPrevFilesService=new UploadPrevGoogleDriveFilesService()
    const response=await uploadPrevFilesService.UploadWithFormDataAsync(model)
    return response
})


export const uploadGoogleDrivePrevFilesSlice=createSlice({
    name:"uploadPrevFilesSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(uploadGoogleDrivePrevFiles.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(uploadGoogleDrivePrevFiles.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(uploadGoogleDrivePrevFiles.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})


export default uploadGoogleDrivePrevFilesSlice.reducer