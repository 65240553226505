import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetFileListByFolderModel } from "../../../models/oneDrive/file/get.file.list.by.folder.model";
import { GetFileListByFolderService } from "../../../services/oneDrive/file/get.file.list.by.folder.service";

interface GetFileListByFolderState extends BaseState<GetFileListByFolderModel>{

}
let initialState:GetFileListByFolderState={
    data:null,
    loading:false,
    error:null
}

export const fetchFileListByFolder=createAsyncThunk("fetch/fileListByFolder",async(folderName:string)=>{
    const service=new GetFileListByFolderService()
    const response=await service.GetByStringParamAsync(folderName)
    return response
})

export const getFileListByFolderSlice=createSlice({
    name:"getFileListByFolderSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchFileListByFolder.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchFileListByFolder.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchFileListByFolder.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})
export default getFileListByFolderSlice.reducer