import { GetAllUsersModel } from "../../models/user/get.all.users.model";
import { BaseGetService } from "../base/base.get.service";



export class GetAllUsersService extends BaseGetService<GetAllUsersModel>{

    constructor(controllerName:string="user",endpointName:string=""){
        super(controllerName,endpointName)
    }
}