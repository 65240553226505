import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetAuthorizationUrlModel, GetAuthorizationUrlResponseModel } from "../../../models/auth/get.auth.url.model";
import { GetAuthorizationUrlService } from "../../../services/auth/auth.url.get.service";


interface GetAuthorizationUrlState extends BaseState<GetAuthorizationUrlResponseModel>{

}


let initialState:GetAuthorizationUrlState={
    data:null,
    loading:false,
    error:null
}


export const fetchAuthorizationUrl=createAsyncThunk("fetch/authUrl",async(request:GetAuthorizationUrlModel)=>{
    const service=new GetAuthorizationUrlService()
    const response=await service.GetByQueryParametersAsync(
        [`client_id=${request.client_id}`,
        `callBackUri=${request.callBackUri}`,
        `scopes=${request.scopes}`])

    return response
})


export const getAuthUrlSlice=createSlice({
    name:"getAuthUrlSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAuthorizationUrl.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAuthorizationUrl.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAuthorizationUrl.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAuthUrlSlice.reducer