import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetAllGoogleDriveFilesModel } from "../../../models/googleDrive/get.all.googleDrive.files.model";
import { GetAllGoogleDriveFilesService } from "../../../services/googleDrive/get.all.googleDrive.files.service";

interface GetAllFilesState extends BaseState<GetAllGoogleDriveFilesModel>{

}
let initialState:GetAllFilesState={
    data:null,
    loading:false,
    error:null
}

export const fetchAllGoogleDriveFiles=createAsyncThunk("fetch/allGoogleDriveFiles",async(bucketName:string)=>{
    const service=new GetAllGoogleDriveFilesService()
    const response=await service.GetByStringParamAsync(bucketName)
    return response
})


export const getAllGoogleDriveFilesSlice=createSlice({
    name:"getAllGoogleDriveFilesSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAllGoogleDriveFiles.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAllGoogleDriveFiles.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAllGoogleDriveFiles.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAllGoogleDriveFilesSlice.reducer