import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { DeleteBucketResponseModel } from "../../../models/bucket/delete.bucket.response.model";
import { DeleteBucketService } from "../../../services/bucket/delete.bucket.service";




interface DeleteBucketState extends BaseState<DeleteBucketResponseModel>{

}


let initialState:DeleteBucketState={
    data:null,
    error:null,
    loading:false
}



export const deleteBucket=createAsyncThunk("bucket/delete",async(bucketKey:string)=>{

    const deleteBucketService=new DeleteBucketService("bucket","")
    return await deleteBucketService.DeleteByStringParamByAsync(bucketKey)
})


export const deleteBucketSlice=createSlice({
    name:"deleteBucketSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(deleteBucket.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(deleteBucket.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload

        })
        .addCase(deleteBucket.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})


export default deleteBucketSlice.reducer