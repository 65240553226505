
import { Admin } from "../../models/user/admin.users.model";
import { BaseGetService } from "../base/base.get.service";


export class GetAdminUsersService extends BaseGetService<Admin> {
    constructor(controllerName: string = "user", endpointName: string = "admin/listUsers") {
        super(controllerName, endpointName);
    }

    async GetAdminUsersAsync(adminId: string): Promise<Admin> {
        return this.GetByStringParamAsync(adminId);
    }
}
