import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import GetBucketResponseModel from "../../../models/bucket/get.bucket.response.model";
import { GetBucketListService } from "../../../services/bucket/get.bucket.list.service";





interface GetBucketListState{
    loading:boolean,
    data:GetBucketResponseModel[],
    error:null|string
}


let initialState:GetBucketListState={
    loading:false,
    data:[],
    error:null
}



const getBucketListService=new GetBucketListService("buckets","")

export const fetchBucketList=createAsyncThunk("bucketList/fetch",async(thunkAPI)=>{
    const response=await getBucketListService.GetAsync()
    return response
})


export const getBucketListSlice=createSlice({
    name:"getBucketListSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(fetchBucketList.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchBucketList.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
            
        })
        .addCase(fetchBucketList.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
        
    }
    
})

export default getBucketListSlice.reducer