import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UserFileCountModel } from "../../../models/dashboard/get.user.all.files.count.model";
import { GetUserAllFilesCountService } from "../../../services/dashboard/get.user.all.files.count.service";




interface GetUserAllFilesCountState extends BaseState<UserFileCountModel[]>{

}


let initialState:GetUserAllFilesCountState={
    data:null,
    loading:false,
    error:null
}


export const fetchUserAllFileCount=createAsyncThunk("fetch/UserAllFileCount",
async(bucketName:string)=>{
    const service=new GetUserAllFilesCountService()
    const response=await service.GetByStringParamAsync(bucketName)
    console.log(response)
    return response
})


export const getUserAllFileCountSlice=createSlice({
    name:"getUserAllFileCountSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder.addCase(fetchUserAllFileCount.pending,(state,action)=>{
            state.loading=true
        }).addCase(fetchUserAllFileCount.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchUserAllFileCount.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})

export default getUserAllFileCountSlice.reducer