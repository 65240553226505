import { GetFileListByFolderModel } from "../../../models/oneDrive/file/get.file.list.by.folder.model";
import { BaseGetService } from "../../base/base.get.service";



export class GetFileListByFolderService extends BaseGetService<GetFileListByFolderModel>{

    constructor(controllerName:string="oneDrive",endpoint:string="files/folders"){
        super(controllerName,endpoint)
    }
}