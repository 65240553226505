import { DeleteBucketResponseModel } from "../../models/bucket/delete.bucket.response.model";
import { BaseDeleteService } from "../base/base.delete.service";


export class DeleteBucketService extends BaseDeleteService<DeleteBucketResponseModel>{

    constructor(controllerName:string="buckets",endpointName:string="delete"){
        super(controllerName,endpointName)
    }

}