import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetBoxFolderListModel } from "../../../models/box/get.box.folders.model";
import { GetAllBoxFoldersService } from "../../../services/box/get.all.box.folders.service";




interface GetAllFoldersState extends BaseState<GetBoxFolderListModel>{

}


let initialState:GetAllFoldersState={
    data:null,
    loading:false,
    error:null
}


export const fetchAllBoxFolders=createAsyncThunk("fetch/allBoxFolders",async(bucketName:string)=>{
    const service=new GetAllBoxFoldersService()
    const response=await service.GetByStringParamAsync(bucketName)
    return response
})


export const getAllBoxFoldersSlice=createSlice({
    name:"getAllBoxFoldersSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAllBoxFolders.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAllBoxFolders.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAllBoxFolders.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAllBoxFoldersSlice.reducer