import React from 'react';
import '../../styles/onedrive/multipleUploadModel.css'
import { Button, Form, Modal } from 'react-bootstrap';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { LinearWithValueLabel } from '../LinearProgressBar';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { baseUrl } from '../../config/axios.config';
import { GetFolderItemModel } from '../../models/oneDrive/folder/get.folder.item.model';
import LittleCircleLoading from '../Loading/LittleCircleLoading';
import { fetchGoogleDriveFolder } from '../../store/features/googleDrive/get.googleDrive.folder.slice';
import { uploadGoogleDriveFilesToForge } from '../../store/features/googleDrive/upload.googleDrive.files.forge.slice';
import { GoogleDriveData } from '../../pages/googleDrive/GoogleDriveUpload';

interface AddedFileListProps {
    file: string;
    bucket: string | undefined
    id: string;
    handleRemoveClick: () => void;
    fileIds: string[]
}

interface MultipleOneDriveUploadModalProps {
    show: boolean;
    onHide: () => void;
    fileList: GoogleDriveData[];
    setFileList: React.Dispatch<React.SetStateAction<GoogleDriveData[]>>;
    bucket: string | undefined;
}

const AddedFileList: React.FC<AddedFileListProps> = ({
    file,
    bucket,
    id,
    handleRemoveClick,
    fileIds
}) => {
    const [isPrompt, setIsPrompt] = useState(false);
	const [folder, setFolder] = useState({
		name: "",
		id: "",
		check: false
	})

    const dispatch = useAppDispatch();
    const folderSelector = useAppSelector((x) => x.getGoogleDriveFolderSlice);

    useEffect(() => {
        if (bucket && file) {
            const existFolderName = `${bucket}-${file}`;
            dispatch(fetchGoogleDriveFolder({
                bucketName: bucket,
                folderName: existFolderName
            }));
        }
    }, [bucket, file, dispatch]);

    useEffect(() => {
        if (
            folderSelector &&
            folderSelector.data &&
            folderSelector.data.folder &&
            !folderSelector.error &&
            !folderSelector.loading
        ) {
            const folder = folderSelector.data.folder as GetFolderItemModel;
            if (folder && folder.name && folder.id) {
                setFolder({
                    ...folder,
                    check: false
                });
                setIsPrompt(true);
            }
        }
    }, [folderSelector]);

    return (
        <>
            {id && file && (
                <div className='addedFile'>
                    <li id={`${id}`}>{file} </li>
                    {!folderSelector.loading ? (
                        <FontAwesomeIcon
                            className='trashIcon'
                            icon={faTrash}
                            onClick={(e) => {
                                handleRemoveClick();
                                e.preventDefault();
                            }}
                        />
                    ) : (
                        <LittleCircleLoading />
                    )}

                    {/* {isPrompt && folder && (
                        <UpdateOrCopyModal
                            id={folder.id || ''}
                            type={"folder"}
                            name={folder.name || ''}
                            show={isPrompt}
                            onHide={() => setIsPrompt(false)}
                            files={fileIds}
                            check={folder.check}
                            setFolder={setFolder}
                        />
                    )} */}
                </div>
            )}
        </>
    );
};

export const MultipleOneDriveUploadModal: React.FC<MultipleOneDriveUploadModalProps> = ({
    show,
    onHide,
    fileList,
    setFileList,
    bucket
}) => {
    const dispatch = useAppDispatch();
    const uploadIndexRef = useRef(0);

    const uploadGoogleDriveFilesSelector = useAppSelector(
        (s: RootState) => s.uploadGoogleDriveFilesToForgeSlice
    );
    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState("");
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    const [isConnected, setIsConnected] = useState(false);

    // const startConnection = (fileIds: string[], bucketKey: string) => {
    //     if (connection) {
    //         connection
    //             .start()
    //             .then(() => {
    //                 console.log('Connection successfully established.');
    //                 setIsConnected(true);

    //                 if (fileIds.length > 2) {
    //                     fileIds.forEach((fileId) => {
    //                         dispatch(
    //                             uploadGoogleDriveFilesToForge({
    //                                 fileIds: [fileId],
    //                                 bucketKey,
    //                             })
    //                         );
    //                     });

    //                 } else {
    //                     dispatch(
    //                         uploadGoogleDriveFilesToForge({
    //                             fileIds,
    //                             bucketKey
    //                         })
    //                     );
    //                 }

    //             })
    //             .catch((err) =>
    //                 console.log('Error while establishing the connection: ', err)
    //             );
    //     }
    // };

    // const stopConnection = () => {
    //     if (connection && isConnected) {
    //         connection
    //             .stop()
    //             .then(() => {
    //                 console.log('Connection successfully stopped.');
    //                 setIsConnected(false);

    //             })
    //             .catch((err) => {
    //                 console.log('Error while stopping the connection: ', err)
    //             });
    //     }
    // };

    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(`${baseUrl}progress`)
            .withAutomaticReconnect()
            .build();

        setConnection(connection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.on('ReceiveProgress', (progressValue, stepValue) => {
                console.log(progressValue)
                console.log(stepValue)
                setProgress(progressValue || 0);
                setStep(stepValue || '');
            });

            return () => {
                if (isConnected) {
                    connection
                        .stop()
                        .then(() => {
                            console.log("Connection successfully stopped.")
                        });
                }
            };
        }
    }, [connection, isConnected]);

    const uploadFilesSequentially = (fileIds: string[], bucketKey: string) => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    console.log('Connection successfully established.');
                    setIsConnected(true);

                    const uploadNextFile = () => {
                        if (uploadIndexRef.current < fileIds.length) {
                            const fileId = fileIds[uploadIndexRef.current];
                            dispatch(
                                uploadGoogleDriveFilesToForge({
                                    fileIds: [fileId],
                                    bucketKey,
                                })
                            ).then(() => {
                                // Yükleme tamamlandı, bir sonraki dosyayı yükle
                                uploadIndexRef.current++;
                                uploadNextFile();
                            });
                        }
                    };

                    // İlk dosyayı başlat
                    uploadNextFile();
                })
                .catch((err) =>
                    console.log('Error while establishing the connection: ', err)
                );
        }
    };

    useEffect(() => {
        const { loading: uploadLoading, error: uploadError, data: uploadData } = uploadGoogleDriveFilesSelector;

        if (!uploadLoading && uploadData && uploadData.isSuccess) {
            // Dosya başarıyla yüklendiğinde buraya girecek
            if (uploadIndexRef.current === fileList.length - 1) {
                // Eğer son dosyaysa, işlem tamamlandı
                console.log("All files uploaded successfully!");
                // İşlem tamamlandığında state'i sıfırla veya modal'i kapat
                setFileList([]);
               // onHide();
            } else {
                // Bir sonraki dosyayı yükle
                uploadFilesSequentially(fileList.map((file) => file.id), bucket as string);
            }
        } else if (!uploadLoading && uploadError) {
            // Hata durumunda
            console.error("Error uploading file:", uploadError);
            // İşlemi durdur veya gerekli adımları atla
        }
    }, [uploadGoogleDriveFilesSelector, fileList, bucket, onHide, setFileList]);

    return (
        <Modal
            show={show}
            onHide={() => {
                if(uploadGoogleDriveFilesSelector.loading===false &&
                    uploadGoogleDriveFilesSelector.data!==null && uploadGoogleDriveFilesSelector.error===null){
                       window.location.reload();
                    }
                else{
                    onHide();
                }
               
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>{fileList.length > 1 ? "Files" : "File"}</Form.Label>
                        {fileList.map((file) => (
                            <AddedFileList
                                key={file.id}
                                fileIds={fileList.map(file => file.id)}
                                file={file.name || ""}
                                handleRemoveClick={() => {
                                    const index = fileList.indexOf(file);
                                    if (index > -1) {
                                        if (index === 0) {
                                            setFileList([]);
                                        } else {
                                            setFileList((prev) => {
                                                prev = prev.splice(index, 1);
                                                return prev;
                                            });
                                        }
                                    }
                                }}
                                id={file.id || ""}
                                bucket={bucket}
                            />
                        ))}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {uploadGoogleDriveFilesSelector.data && !uploadGoogleDriveFilesSelector.loading &&
                    !uploadGoogleDriveFilesSelector.error &&
                    uploadGoogleDriveFilesSelector.data.isSuccess && progress === 100 && (
                        <>
                            {fileList.length > 1 ? (
                                <p style={{ color: "green", marginRight: "auto" }}>
                                    Files have been uploaded succesfully
                                </p>
                            ) : (
                                    <p style={{ color: "green", marginRight: "auto" }}>
                                        File has been uploaded successfully
                                    </p>
                                )}
                        </>
                    )}
                {bucket && (
                    <Button
                        className='uploadButton'
                        disabled={uploadGoogleDriveFilesSelector.loading || fileList.length === 0}
                        variant={uploadGoogleDriveFilesSelector.loading ? "secondary" : "primary"}
                        hidden={progress === 100}
                        onClick={() => {
                            uploadFilesSequentially(fileList.map((file) => file.id), bucket as string);
                        }}
                    >
                        Translate {fileList.length > 1 ? "Files" : "File"}
                    </Button>
                )}

                {uploadGoogleDriveFilesSelector.loading && (
                    <LinearWithValueLabel progress={progress} step={step} />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default MultipleOneDriveUploadModal;
