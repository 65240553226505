import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetAWSFileService } from "../../../services/aws/get.aws.file.service";
import { GetAWSFileRequestModel, GetAWSFileResponseModel } from "../../../models/aws/get.file.aws.model";




interface GetAWSFileState extends BaseState<GetAWSFileResponseModel>{

}


let initialState:GetAWSFileState={
    data:null,
    loading:false,
    error:null
}


export const fetchAWSFile=createAsyncThunk("fetch/AWSFile",async(request:GetAWSFileRequestModel)=>{
    const service=new GetAWSFileService()
    const response=await service.GetByStringManyParamsAsync([request.bucketName,request.fileName])
    return response
})


export const getAWSfileslice=createSlice({
    name:"getAWSfileslice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAWSFile.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAWSFile.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAWSFile.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAWSfileslice.reducer