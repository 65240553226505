import { BrowserRouter, Route, Routes } from "react-router-dom"
import Home from "../pages/Home"
import ObjectDetail from "../pages/object/ObjectDetail"
import ObjectList from "../pages/object/ObjectList"
import FolderList from "../pages/oneDrive/FolderList"
import DashboardHome from "../pages/dashboard/DashboardHome"
import OneDrive3DView from "../pages/oneDrive/OneDrive3DView"
import Register from "../pages/Register"
import LoginSignUp from "../pages/LoginSignUp"
import Upload from "../pages/oneDrive/Upload"
import EmbedCode from "../pages/oneDrive/EmbedCode"
import Privacy from "../pages/Privacy"
import GoogleDriveUpload from "../pages/googleDrive/GoogleDriveUpload"
import GoogleDriveFolderList from "../pages/googleDrive/GoogleDriveFolderList"
import BoxUpload from "../pages/box/BoxUpload"
import BoxFolderList from "../pages/box/BoxFolderList"
import AWSUpload from "../pages/aws/AWSUpload"
import AWSFolderList from "../pages/aws/AWSFolderList"
import BucketList from "../pages/bucket/BucketList"
import Payment from "../pages/payment/Payment"
import UserProfile from "../pages/user/UserProfile"
import EmailConfirmed from "../pages/EmailConfirmed"
import CombinedComponent from "../pages/oneDrive/Upload"
import ForgotPassword from "../pages/ForgotPassword"
import VerifyPhone from "../components/VerifyPhone/VerifyPhone"
import ResetPassword from "../pages/ResetPassword"
import PhoneVerification from "../pages/PhoneVerification"
import SecondHeader from "../components/Navbar/SecondHeader"




const MainRoutes=()=>{
    return (
       <BrowserRouter>
        <SecondHeader setIsBox={undefined} />
         <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/embed" element={<EmbedCode/>}/>
            <Route path="/login" element={<LoginSignUp/>}/>
            <Route path="/models" element={<ObjectList/>}/>
            <Route path="/object" element={<ObjectDetail/>}/>
            <Route path="/oneDrive/models" element={<CombinedComponent/>}/>
            <Route path="/oneDrive/view" element={<OneDrive3DView/>}/>
            <Route path="/dashboard" element={<DashboardHome/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="/googleDrive/upload" element={<GoogleDriveUpload/>}/>
            <Route path="/googleDrive/files" element={<GoogleDriveFolderList/>}/>
            <Route path="/box/upload" element={<BoxUpload/>}/>
            <Route path="/box/files" element={<BoxFolderList/>}/>
            <Route path="/aws/upload" element={<AWSUpload/>}/>
            <Route path="/aws/files" element={<AWSFolderList/>}/>
            <Route path='/storage' element={<BucketList/>}/>
            <Route path="/payment" element={<Payment />} />
            <Route path="/profile" element={<UserProfile/>}/>
            <Route path="/emailConfirmed" element={<EmailConfirmed/>}/>
            <Route path='/forgotPassword' element={<ForgotPassword/>}/>
            <Route path="/resetPassword" element={<ResetPassword/>}/>
            <Route path='/verifyPhone' element={<PhoneVerification/>}/>
            {/* <Route path="/googleDrive/embed" element={<GoogleDriveEmbedCode/>}/> */}

         </Routes>
       </BrowserRouter>
    )
}

export default MainRoutes