import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UploadAWSForgeService } from "../../../services/aws/upload.aws.forge.service";
import { UploadAWSFilesToForgeRequestModel, UploadAWSFilesToForgeResponseModel } from "../../../models/aws/upload.aws.files.forge.model";




interface UploadFilesToForgeState extends BaseState<UploadAWSFilesToForgeResponseModel>{

}


let initialState:UploadFilesToForgeState={
    data:null,
    loading:false,
    error:null
}


export const uploadAWSFilesToForge=createAsyncThunk("upload/forge",
async(model:UploadAWSFilesToForgeRequestModel)=>{
    const uploadForgeService=new UploadAWSForgeService()
    const response=await uploadForgeService.PutAsync(model) as UploadAWSFilesToForgeResponseModel
    return response
})


export const uploadAWSFilesToForgeSlice=createSlice({
    name:"uploadAWSFilesToForgeSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(uploadAWSFilesToForge.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(uploadAWSFilesToForge.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(uploadAWSFilesToForge.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})

export default uploadAWSFilesToForgeSlice.reducer