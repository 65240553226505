import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetUserModel } from "../../../models/user/get.user.model";
import { GetUserInfoService } from "../../../services/user/get.user.service";

interface UserInfoState extends BaseState<GetUserModel> {}

const initialState: UserInfoState = {
    data: null,
    loading: false,
    error: null
};

export const fetchUserInfo = createAsyncThunk("fetch/UserInfo", async (userId: string) => {
    const service = new GetUserInfoService();
    const response = await service.GetUserInfoAsync(userId);
    return response;
});

export const userInfoSlice = createSlice({
    name: "userInfoSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchUserInfo.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default userInfoSlice.reducer;
