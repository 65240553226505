
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import GetBucketResponseModel from "../../../models/bucket/get.bucket.response.model";
import { GetUserBucketService } from "../../../services/bucket/get.user.bucket.service";
import { LoginResponse } from "../../../models/auth/login.model";



interface GetBucketListState{
    loading:boolean,
    data:GetBucketResponseModel|null,
    error:null|string
}


let initialState:GetBucketListState={
    loading:false,
    data:null,
    error:null
}


export const fetchUserBucket=createAsyncThunk("bucket/fetch",async()=>{
    const getBucketListService=new GetUserBucketService()
    const  loginInfo = sessionStorage.getItem('login') as string;
	const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse
    
    const response=await getBucketListService.GetByStringParamAsync(jsonLoginInfo.uid)

    return response
})


export const getUserBucketSlice=createSlice({
    name:"getUserBucket",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(fetchUserBucket.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchUserBucket.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchUserBucket.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
        
    }
    
})


export default getUserBucketSlice.reducer