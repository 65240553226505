import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetAWSFolderRequestModel, GetAWSFolderResponseModel } from "../../../models/aws/get.folder.aws.model";
import { GetAWSFolderService } from "../../../services/aws/get.aws.folder.service";

interface GetAWSFolderState extends BaseState<GetAWSFolderResponseModel>{

}


let initialState:GetAWSFolderState={
    data:null,
    loading:false,
    error:null
}


export const fetchAWSFolder=createAsyncThunk("fetch/AWSFile",async(request:GetAWSFolderRequestModel)=>{
    const service=new GetAWSFolderService()
    const response=await service.GetByStringManyParamsAsync([request.bucketName,request.folderName])
    return response
})


export const getAWSfolderslice=createSlice({
    name:"getAWSFileSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAWSFolder.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAWSFolder.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAWSFolder.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAWSfolderslice.reducer