import { GetAllGoogleDriveFilesModel } from "../../models/googleDrive/get.all.googleDrive.files.model";
import { BaseGetService } from "../base/base.get.service";



export class GetAllGoogleDriveFilesService extends BaseGetService<GetAllGoogleDriveFilesModel>{
    constructor(controllerName:string="googleDrive",endpoint:string="fileList"){
        super(controllerName,endpoint)
    }
    
}