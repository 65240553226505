
import { UserFileCountModel } from "../../models/dashboard/get.user.all.files.count.model";
import { BaseGetService } from "../base/base.get.service";



export class GetUserAllFilesCountService extends BaseGetService<UserFileCountModel[]>{

    constructor(controllerName:string="dashboard",endpoint:string="owner/fileCount"){
        super(controllerName,endpoint)
    }
}