import { GetAuthorizationUrlResponseModel } from "../../models/auth/get.auth.url.model";
import { BaseGetService } from "../base/base.get.service";




export class GetAuthorizationUrlService extends BaseGetService<GetAuthorizationUrlResponseModel>{
    constructor(controllerName:string="auth",endpointName:string="login") {
        super(controllerName,endpointName);

    }
}