import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetRecentFilesService } from "../../../services/oneDrive/file/get.recent.files.service";
import { GetRecentFilesModel } from "../../../models/oneDrive/file/get.recent.files.model";




interface GetRecentFilesState extends BaseState<GetRecentFilesModel>{

}


let initialState:GetRecentFilesState={
    data:null,
    loading:false,
    error:null
}


export const fetchRecentFiles=createAsyncThunk("fetch/recentFiles",async(files:string)=>{
    const service=new GetRecentFilesService()
    const response=await service.GetByQueryParameter(`recentUploadFiles=${files}`)
    return response
})


export const getRecentFilesSlice=createSlice({
    name:"getRecentFilesSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchRecentFiles.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchRecentFiles.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchRecentFiles.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getRecentFilesSlice.reducer