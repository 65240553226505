import React from 'react';
import { Container, Typography, Box, Button, Link } from '@mui/material';
import Footer from "../components/Footer/Footer";
import SecondHeader from "../components/Navbar/SecondHeader";

const Privacy = () => {
    return (
        <>
            
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Box textAlign="center" mb={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Privacy Policy
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We respect your privacy and are committed to protecting your personal information. This page outlines our policies regarding the collection, use, and disclosure of personal data when you use our website and application.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By using our website and application, you agree to the collection and use of information as described in this policy.
                    </Typography>
                </Box>

                <Box mb={4}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Information Collection and Use
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We collect personal information that you voluntarily provide to us when you use our website and application. This includes your name, email address, and any other information you choose to provide.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Any information you provide will be used to respond to your inquiries, fulfill your requests, and improve our services.
                    </Typography>
                </Box>

                <Box mb={4}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        How We Use Google User Data
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Our application, Veewer, accesses, uses, stores, and/or shares Google user data to provide and improve our services. Specifically:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body1" paragraph>
                                <strong>File Upload:</strong> We use Google Drive to allow users to upload 3D model files to their Drive storage. This enables users to easily store and manage their 3D models.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" paragraph>
                                <strong>File Listing:</strong> Our application lists the 3D model files stored in the user's Google Drive. This helps users to view and select the files they want to work with directly from their Drive.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" paragraph>
                                <strong>File Viewing:</strong> We use the Viewer to display the 3D models stored in Google Drive. This allows users to view their 3D models directly within our application.
                            </Typography>
                        </li>
                    </ul>
                    <Typography variant="body1" paragraph>
                        We only use Google user data for the purposes disclosed in this Privacy Policy and in accordance with Google's Limited Use Requirements.
                    </Typography>
                </Box>

                <Box mb={4}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Log Data
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Like many site operators, we collect information that your browser sends whenever you visit our site. This may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
                    </Typography>
                </Box>

                <Box mb={4}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Cookies
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We use cookies to improve your experience on our site. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our site.
                    </Typography>
                </Box>

                <Box mb={4}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        In-Product Privacy Notifications
                    </Typography>
                    <Typography variant="body1" paragraph>
                        In-product privacy notifications are prominently displayed in our app interface to inform users about how their data is being used. These notifications are kept up to date with our current practices.
                    </Typography>
                </Box>

                <Box mb={4}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Changes to This Privacy Policy
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page.
                    </Typography>
                </Box>

                <Box textAlign="center" mb={4}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Contact Us
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you have any questions about this privacy policy, please contact us through our Codeo website.
                    </Typography>
                    <Button variant="contained" color="primary" href="https://www.codeosolutions.com/">
                        Visit Our Website
                    </Button>
                </Box>
            </Container>
            <Footer />
        </>
    );
};

export default Privacy;
 