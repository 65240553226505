import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetAllBoxFilesModel } from "../../../models/box/get.all.box.files.model";
import { GetAllBoxFilesService } from "../../../services/box/get.all.box.files.service";




interface GetAllFilesState extends BaseState<GetAllBoxFilesModel>{

}


let initialState:GetAllFilesState={
    data:null,
    loading:false,
    error:null
}


export const fetchAllBoxFiles=createAsyncThunk("fetch/allBoxFiles",async(bucketName:string)=>{
    const service=new GetAllBoxFilesService()
    const response=await service.GetByStringParamAsync(bucketName)
    return response
})


export const getAllBoxFilesSlice=createSlice({
    name:"getAllBoxFilesSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAllBoxFiles.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAllBoxFiles.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAllBoxFiles.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAllBoxFilesSlice.reducer