import { CreateBetaUserModel, CreateBetaUserResponseModel } from "../../models/betaUser/beta.user.model";
import { BasePostService } from "../base/base.post.service";




export class BetaUserPostService extends BasePostService<CreateBetaUserModel,CreateBetaUserResponseModel>{

    constructor(controllerName:string="betaUser",endpointName:string=""){
        super(controllerName,endpointName)
    }
}