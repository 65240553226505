import React, { useState, useEffect } from 'react';
import VeewerLogo from '../assets/Veewer siyah @4x.png';

export default function Hero({ scrollToBeta }: { scrollToBeta: () => void }) {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight); // Ekran boyutu değiştikçe yüksekliği günceller
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className="flex flex-col items-center py-16 text-center xs:w-full 2xl:h-screen xl:h-5/6 lg:h-4/6 md:h-3/6 sm:h-2/6">
      <div className="max-w-[1500px] w-[95%] xs:w-full mt-[2.6%] 2xl:space-y-24 xs:space-y-12 sm:space-y-14 tracking-widest">
        <h1 className="2xl:text-[69px] xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl font-[250] 2xl:!leading-[90px] lg:!leading-[50px] md:!leading-[40px] sm:!leading-[30px] xs:!leading-[35px]">
          Elevate customer engagement with impactful 3D interactions.
        </h1>
        <div className="mb-16">
          <img
            src={VeewerLogo}
            alt="Veewer Logo"
            
            height={80}
            className="mx-auto 2xl:w-[480px] xl:w-[400px] lg:w-96 sm:w-40 xs:w-20"
          />
        </div>
        <button id='join-beta' onClick={scrollToBeta} className="px-8 py-3 sm:text-sm hover:!bg-white  2xl:text-lg font-semibold border border-black hover:!text-black text-white bg-black rounded-full transition-colors duration-300">
          Join Beta Program
        </button>
      </div>
    </header>
  );
}
