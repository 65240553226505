import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { SecondaryGetUploadedObjectRequest } from "../../../models/object/uploadedObject/get.uploadedObject.request.model";
import { GetUploadedObjectResponseModel } from "../../../models/object/uploadedObject/get.uploadedObject.response.model";
import { GetListUploadedObjectService } from "../../../services/uploadedObject/get.list.uploadedObject.service";



interface GetUploadedObjectState extends BaseState<GetUploadedObjectResponseModel[]>{

}


let initialState:GetUploadedObjectState={
    data:null,
    error:null,
    loading:false
}


export const getUploadedObjects=createAsyncThunk("uploadedObjects/get",async(request:SecondaryGetUploadedObjectRequest)=>{
    const {bucketKey}=request
    const getListUploadedObjectService=new GetListUploadedObjectService("objects","list")

    if(bucketKey===""){
        return await getListUploadedObjectService.GetAsync()       
    }else{
        return await getListUploadedObjectService.GetByStringParamAsync(bucketKey)     
    }

})

export const getUploadedObjectsBybucketKey=createAsyncThunk("uploadedObjects/get/bucketKey",async(bucketKey:string)=>{
    const getListUploadedObjectService=new GetListUploadedObjectService("objects","list")
    const response=await getListUploadedObjectService.GetByStringParamAsync(bucketKey)
    return response

})


export const getUploadedObjectsSlice=createSlice({
    name:"getUploadedObjectsSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(getUploadedObjects.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(getUploadedObjects.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(getUploadedObjects.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })      
    }
})


export const getUploadedObjectsByBucketKeySlice=createSlice({
    name:"getUploadedObjectsByBucketKeySlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(getUploadedObjectsBybucketKey.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(getUploadedObjectsBybucketKey.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(getUploadedObjectsBybucketKey.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })      
    }
})


export default {
    getUploadedObjectsBucketKeyReducer:getUploadedObjectsByBucketKeySlice.reducer,
    getUploadedObjectsReducer:getUploadedObjectsSlice.reducer
}




