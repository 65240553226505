import { BaseService } from "../base/base.service";
import { CreateAdminUserModel, CreateTeamUserModel } from "../../models/user/create.team.user.model";

export class CreateAdminUserToTeamService extends BaseService {
    constructor(controllerName: string = "user", endpointName: string = "team/admin") {
        super(controllerName, endpointName);
    }

    async CreateUserAsync(model: CreateAdminUserModel): Promise<any> {
        const res = await this.request.post("", model);
        return res.data;
    }
}
