import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { PutUploadedObjectRequestModel } from "../../../models/object/uploadedObject/put.uploadedObject.request.model";
import { PutUploadedObjectResponseModel } from "../../../models/object/uploadedObject/put.uploadedObject.response.model";
import { PutUploadedObjectService } from "../../../services/uploadedObject/put.uploadedObject.service";




interface CreateUploadedObjectState extends BaseState<PutUploadedObjectResponseModel>{

}


let initialState:CreateUploadedObjectState={
    data:null,
    error:null,
    loading:false
}



export const createUploadedObject=createAsyncThunk("uploadedObject/create",
async(request:PutUploadedObjectRequestModel)=>{

    const service=new PutUploadedObjectService("objects","multiple")
    console.log(request.files)
    const response=await service.UploadObjectAsync(request)

    

    return response
})


export const createUploadedObjectSlice=createSlice({
    name:"createUploadedObjectSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(createUploadedObject.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(createUploadedObject.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(createUploadedObject.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
        
    }
})

export default createUploadedObjectSlice.reducer











