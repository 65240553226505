import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SecondHeader from '../components/Navbar/SecondHeader';
import Footer from '../components/Footer/Footer';
import '../styles/resetPassword/resetPassword.css';
import { RootState, useAppDispatch, useAppSelector } from '../store';
import { fetchResetPassword } from '../store/features/resetPassword/reset.password.slice';

export default function ResetPassword() {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const resetPasswordState = useAppSelector((state:RootState) => state.resetPasswordSlice);

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    dispatch(fetchResetPassword({ email, password, confirmPassword, token }));
  };

  useEffect(() => {
    if (resetPasswordState.data) {
      setMessage(resetPasswordState.data.message);
      if (resetPasswordState.data.message.includes('Password has been reset successfully')) {
        setTimeout(() => navigate('/login'), 3000);
      }
    }
  }, [resetPasswordState.data, navigate]);

  return (
    <>
      <div className="reset-password-page">
        <div className="reset-password-container">
          <h2>Reset Password</h2>
          <form onSubmit={handleResetPassword}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Token</label>
              <input
                type="text"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>New Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Confirm New Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Reset Password</button>
          </form>
          {message && <p>{message}</p>}
        </div>
      </div>
      <Footer />
    </>
  );
}
