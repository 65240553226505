import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Tooltip,
  Paper,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const EmbedCode: React.FC = () => {
  const location = useLocation();
  const initialEmbedCode = location.state.embedCode;
  const [newEmbedCode, setNewEmbedCode] = useState("");
  const [width, setWidth] = useState("500px");
  const [height, setHeight] = useState("500px");
  const [frameborder, setFrameborder] = useState(false);
  const [allowfullscreen, setAllowfullscreen] = useState(true);
  const [mozallowfullscreen, setMozallowfullscreen] = useState(true);
  const [webkitallowfullscreen, setWebkitallowfullscreen] = useState(true);
  const [xrSpatialTracking, setXrSpatialTracking] = useState(true);
  const [executionWhileOutOfViewport, setExecutionWhileOutOfViewport] = useState(true);
  const [executionWhileNotRendered, setExecutionWhileNotRendered] = useState(true);
  const [webShare, setWebShare] = useState(true);
  const [sandbox, setSandbox] = useState("");
  const [loading, setLoading] = useState("auto");
  const [referrerpolicy, setReferrerpolicy] = useState("no-referrer");
  const [longdesc, setLongdesc] = useState("");
  const [scrolling, setScrolling] = useState("auto");
  const [encryptedMedia, setEncryptedMedia] = useState(false);
  const [pictureInPicture, setPictureInPicture] = useState(false);
  const [camera, setCamera] = useState(false);
  const [microphone, setMicrophone] = useState(false);
  const [autoplay, setAutoplay] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [mediaSource, setMediaSource] = useState("");
  const [accelerometer, setAccelerometer] = useState(false);
  const [gyroscope, setGyroscope] = useState(false);
  const [payment, setPayment] = useState(false);

  useEffect(() => {
    setNewEmbedCode(initialEmbedCode);
  }, [initialEmbedCode]);

  useEffect(() => {
    updateEmbedCode();
  }, [
    width,
    height,
    frameborder,
    allowfullscreen,
    mozallowfullscreen,
    webkitallowfullscreen,
    xrSpatialTracking,
    executionWhileOutOfViewport,
    executionWhileNotRendered,
    webShare,
    sandbox,
    loading,
    referrerpolicy,
    longdesc,
    scrolling,
    encryptedMedia,
    pictureInPicture,
    camera,
    microphone,
    autoplay,
    fullscreen,
    mediaSource,
    accelerometer,
    gyroscope,
    payment,
  ]);

  const updateEmbedCode = () => {
    const frameborderValue = frameborder ? "1" : "0";
    const fullscreenAttributes = allowfullscreen ? "allowfullscreen" : "";
    const mozFullscreenAttributes = mozallowfullscreen ? "mozallowfullscreen='true'" : "";
    const webkitFullscreenAttributes = webkitallowfullscreen ? "webkitallowfullscreen='true'" : "";
    const xrTracking = xrSpatialTracking ? "xr-spatial-tracking" : "";
    const execWhileOutOfViewport = executionWhileOutOfViewport ? "execution-while-out-of-viewport" : "";
    const execWhileNotRendered = executionWhileNotRendered ? "execution-while-not-rendered" : "";
    const webShareValue = webShare ? "web-share" : "";
    const encryptedMediaAttributes = encryptedMedia ? "encrypted-media" : "";
    const pictureInPictureAttributes = pictureInPicture ? "picture-in-picture" : "";
    const cameraValue = camera ? "camera" : "";
    const microphoneValue = microphone ? "microphone" : "";
    const autoplayValue = autoplay ? "autoplay" : "";
    const fullscreenValue = fullscreen ? "fullscreen" : "";
    const accelerometerValue = accelerometer ? "accelerometer" : "";
    const gyroscopeValue = gyroscope ? "gyroscope" : "";
    const paymentValue = payment ? "payment" : "";

    const updatedCode = initialEmbedCode.replace(/width='.*?'/, `width='${width}'`)
      .replace(/height='.*?'/, `height='${height}'`)
      .replace(/sandbox='.*?'/, `sandbox='${sandbox}'`)
      .replace(/loading='.*?'/, `loading='${loading}'`)
      .replace(/referrerpolicy='.*?'/, `referrerpolicy='${referrerpolicy}'`)
      .replace(/longdesc='.*?'/, `longdesc='${longdesc}'`)
      .replace(/scrolling='.*?'/, `scrolling='${scrolling}'`)
      .replace(/frameborder='.*?'/, `frameborder='${frameborderValue}'`)
      .replace(/allowfullscreen|mozallowfullscreen='true'|webkitallowfullscreen='true'|xr-spatial-tracking|execution-while-out-of-viewport|execution-while-not-rendered|web-share|encrypted-media|picture-in-picture|camera|microphone|autoplay|fullscreen|accelerometer|gyroscope|payment/g, '')
      .replace(/<iframe /, `<iframe ${fullscreenAttributes} ${mozFullscreenAttributes} ${webkitFullscreenAttributes} ${xrTracking} ${execWhileOutOfViewport} ${execWhileNotRendered} ${webShareValue} ${encryptedMediaAttributes} ${pictureInPictureAttributes} ${cameraValue} ${microphoneValue} ${autoplayValue} ${fullscreenValue} ${accelerometerValue} ${gyroscopeValue} ${paymentValue} `);

    setNewEmbedCode(updatedCode);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(newEmbedCode).then(() => {
      alert("Embed code copied to clipboard!");
    }).catch(err => {
      alert("Failed to copy embed code.");
    });
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" component="h1" align="center" gutterBottom>
        Veewer Embed Code View
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Box mb={2}>
              <Tooltip title="The embed code of the iframe">
                <TextField
                  fullWidth
                  label="IFrame"
                  multiline
                  rows={4}
                  value={newEmbedCode}
                  onChange={(e) => setNewEmbedCode(e.target.value)}
                />
              </Tooltip>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Tooltip title="The width of the iframe">
                  <TextField
                    fullWidth
                    label="Width"
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="The height of the iframe">
                  <TextField
                    fullWidth
                    label="Height"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </Tooltip>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Tooltip title="Specifies whether the iframe should have a border">
                <FormControlLabel
                  control={<Checkbox checked={frameborder} onChange={(e) => setFrameborder(e.target.checked)} />}
                  label="Frameborder"
                />
              </Tooltip>
            </Box>
            <Box mt={2}>
              <Tooltip title="Allows the iframe content to be displayed in fullscreen mode">
                <FormControlLabel
                  control={<Checkbox checked={allowfullscreen} onChange={(e) => setAllowfullscreen(e.target.checked)} />}
                  label="Allow Fullscreen"
                />
              </Tooltip>
            </Box>
            <Box mt={2}>
              <Tooltip title="Allows XR spatial tracking in the iframe">
                <FormControlLabel
                  control={<Checkbox checked={xrSpatialTracking} onChange={(e) => setXrSpatialTracking(e.target.checked)} />}
                  label="XR Spatial Tracking"
                />
              </Tooltip>
            </Box>
            <Box mt={2}>
              <Tooltip title="Allows execution of the iframe content even when it is out of the viewport">
                <FormControlLabel
                  control={<Checkbox checked={executionWhileOutOfViewport} onChange={(e) => setExecutionWhileOutOfViewport(e.target.checked)} />}
                  label="Execution While Out Of Viewport"
                />
              </Tooltip>
            </Box>
            <Box mt={2}>
              <Tooltip title="Allows execution of the iframe content even when it is not rendered">
                <FormControlLabel
                  control={<Checkbox checked={executionWhileNotRendered} onChange={(e) => setExecutionWhileNotRendered(e.target.checked)} />}
                  label="Execution While Not Rendered"
                />
              </Tooltip>
            </Box>
            <Box mt={2}>
              <Tooltip title="Allows the Web Share API in the iframe">
                <FormControlLabel
                  control={<Checkbox checked={webShare} onChange={(e) => setWebShare(e.target.checked)} />}
                  label="Web Share"
                />
              </Tooltip>
            </Box>
            <Box mt={2}>
              <Tooltip title="Allows encrypted media in the iframe">
                <FormControlLabel
                  control={<Checkbox checked={encryptedMedia} onChange={(e) => setEncryptedMedia(e.target.checked)} />}
                  label="Encrypted Media"
                />
              </Tooltip>
            </Box>
            <Box mt={2}>
              <Tooltip title="Allows picture-in-picture mode in the iframe">
                <FormControlLabel
                  control={<Checkbox checked={pictureInPicture} onChange={(e) => setPictureInPicture(e.target.checked)} />}
                  label="Picture-in-Picture"
                />
              </Tooltip>
            </Box>
            <Box mt={2}>
              <Tooltip title="Specifies extra restrictions on the content in the iframe">
                <TextField
                  select
                  fullWidth
                  label="Sandbox"
                  value={sandbox}
                  onChange={(e) => setSandbox(e.target.value)}
                >
                  <MenuItem value="">No Restrictions</MenuItem>
                  <MenuItem value="allow-same-origin">Allow Same Origin</MenuItem>
                  <MenuItem value="allow-top-navigation">Allow Top Navigation</MenuItem>
                  <MenuItem value="allow-forms">Allow Forms</MenuItem>
                  <MenuItem value="allow-scripts">Allow Scripts</MenuItem>
                </TextField>
              </Tooltip>
            </Box>
            <Box mt={2}>
              <Tooltip title="Controls how the referrer information is sent with requests">
                <TextField
                  select
                  fullWidth
                  label="Referrer Policy"
                  value={referrerpolicy}
                  onChange={(e) => setReferrerpolicy(e.target.value)}
                >
                  <MenuItem value="no-referrer">No Referrer</MenuItem>
                  <MenuItem value="no-referrer-when-downgrade">No Referrer When Downgrade</MenuItem>
                  <MenuItem value="origin">Origin</MenuItem>
                  <MenuItem value="origin-when-cross-origin">Origin When Cross-Origin</MenuItem>
                  <MenuItem value="same-origin">Same Origin</MenuItem>
                  <MenuItem value="strict-origin">Strict Origin</MenuItem>
                  <MenuItem value="strict-origin-when-cross-origin">Strict Origin When Cross-Origin</MenuItem>
                  <MenuItem value="unsafe-url">Unsafe URL</MenuItem>
                </TextField>
              </Tooltip>
            </Box>
            <Box mt={2}>
              <Button variant="contained" color="primary" onClick={() => {
                var container = document.getElementById("iframeContainer") as HTMLElement;
                container.innerHTML = newEmbedCode;
              }}>
                Show Iframe
              </Button>
              <Button variant="contained" color="secondary" onClick={handleCopy} style={{ marginLeft: '10px' }}>
                Copy Embed Code
              </Button>
            </Box>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Box id="iframeContainer" style={{ width: '100%', height: '500px' }}>
              <iframe
                src={initialEmbedCode.match(/src='(.*?)'/)?.[1]}
                width={width}
                height={height}
                sandbox={sandbox}
                scrolling={scrolling}
                frameBorder={frameborder ? "1" : "0"}
                allowFullScreen={allowfullscreen}
                xr-spatial-tracking={xrSpatialTracking ? "true" : undefined}
                execution-while-out-of-viewport={executionWhileOutOfViewport ? "true" : undefined}
                execution-while-not-rendered={executionWhileNotRendered ? "true" : undefined}
                web-share={webShare ? "true" : undefined}
                encrypted-media={encryptedMedia ? "true" : undefined}
                picture-in-picture={pictureInPicture ? "true" : undefined}
              />
            </Box>
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EmbedCode;