import * as React from 'react';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number}
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant='determinate'
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant='body2'
          color='text.secondary'
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

interface LinearProps {
  progress: number;
  step:string
}

export const LinearWithValueLabel: React.FC<LinearProps> = ({ progress,step}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <p>{step}</p>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
};
