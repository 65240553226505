import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";


import { GetAWSFoldersResponseModel } from "../../../models/aws/get.folders.aws.model";
import { GetAllAWSFoldersService } from "../../../services/aws/get.all.aws.folders.service";




interface GetAllAWSFoldersState extends BaseState<GetAWSFoldersResponseModel>{

}


let initialState:GetAllAWSFoldersState={
    data:null,
    loading:false,
    error:null
}


export const fetchAllAWSFolders=createAsyncThunk("fetch/allAWSFolders",async(bucketName:string)=>{
    const service=new GetAllAWSFoldersService()
    const response=await service.GetByStringParamAsync(bucketName)
    return response
})


export const getAllAWSFoldersSlice=createSlice({
    name:"getAllAWSFoldersSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAllAWSFolders.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAllAWSFolders.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAllAWSFolders.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAllAWSFoldersSlice.reducer