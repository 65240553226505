import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UploadBoxPrevFilesRequestModel, UploadBoxPrevFilesResponseModel } from "../../../models/box/upload.prev.box.files.model";
import { UploadPrevBoxFilesService } from "../../../services/box/upload.prev.box.files.service";



interface UploadPrevFilesState extends 
BaseState<UploadBoxPrevFilesResponseModel>{

}


let initialState:UploadPrevFilesState={
    data:null,
    loading:false,
    error:null
}



export const uploadBoxPrevFiles=createAsyncThunk("upload/prevBoxFiles",
async(model:UploadBoxPrevFilesRequestModel)=>{

    const uploadPrevFilesService=new UploadPrevBoxFilesService()
    const response=await uploadPrevFilesService.UploadWithFormDataAsync(model)
    return response
})


export const uploadBoxPrevFilesSlice=createSlice({
    name:"uploadPrevFilesSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(uploadBoxPrevFiles.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(uploadBoxPrevFiles.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(uploadBoxPrevFiles.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})


export default uploadBoxPrevFilesSlice.reducer