import { LoginRequest, LoginResponse } from '../../models/auth/login.model';
import { BasePostService } from '../base/base.post.service';

export class LoginPostService extends BasePostService<
  LoginRequest,
  LoginResponse
> {
  constructor(controllerName: string = 'auth', endpointName: string = 'login') {
    super(controllerName, endpointName);
  }
}
