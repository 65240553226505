
import { GetTypesFilterFileModel } from "../../../models/oneDrive/file/get.types.filter.file.model";
import { BaseGetService } from "../../base/base.get.service";



export class GetTypeFiltersFilesService extends BaseGetService<GetTypesFilterFileModel>{
    constructor(controllerName:string="oneDrive",endpoint:string="files/get"){
        super(controllerName,endpoint)
    }
}