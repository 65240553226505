import React, { useState, useEffect } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import SecondHeader from '../../components/Navbar/SecondHeader';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import CardItem from '../../components/CardItem';
import IAM from '../../assets/IAM.png';
import IPT from '../../assets/IPT.png';
import ZIP from '../../assets/zip.svg';
import RVT from '../../assets/RVT.jpg';
import DWG from '../../assets/DWG.jpg';
import FBX from '../../assets/FBX.png';
import DWFX from '../../assets/DWFX.png';
import IDW from '../../assets/IDW.png';
import '../../styles/onedrive/upload.css';
import CircleLoading from '../../components/Loading/CircleLoading';
import { Container, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlushed } from '@fortawesome/free-solid-svg-icons';
import MultipleBoxUploadModal from '../../components/MultipleUploadModal/MultipleBoxUploadModal';
import BoxDragnDrop from '../../components/Box/BoxDragnDrop';
import { fetchAllBoxFiles } from '../../store/features/box/get.all.box.files.slice';


export interface BoxData {
	name: string;
	id: string;
	size: number;
}

const BoxUpload: React.FC = () => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showTranslationModal, setShowTranslationModal] = useState<boolean>(false);
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [secondSelectedFiles, setSecondSelectedFiles] = useState<BoxData[]>([]);
	const [fileLimit, setFileLimit] = useState(false);
	const [progress, setProgress] = useState(0);
	const [dragNdrop, setDragNdrop] = useState(false);

	const dispatch = useAppDispatch();
	const getUserBucket = useAppSelector((s: RootState) => s.getUserBucket);

	const getFiles = useAppSelector((s: RootState) => s.getAllBoxFilesSlice);

	// const loginInfo = sessionStorage.getItem('login') as string;
	// const jsonLoginInfo = JSON.parse(loginInfo) as TokenResponse;
	// const microsoftAuth = sessionStorage.getItem('microsoft-auth') as string;
	// const codeResponse = JSON.parse(microsoftAuth) as SaveCodeResponseModel;
	// const isSuccess = codeResponse.isSuccess;

	const bucket=sessionStorage.getItem('b') as string

	const MAX_COUNT = 40;

	const UploadFilesSwal = withReactContent(Swal);


	



	useEffect(() => {
		dispatch(fetchAllBoxFiles(bucket));
	}, [dispatch]);

	const handleUploadFiles = (chosenFiles: File[]) => {
		const uploaded = [...selectedFiles];
		let limitedExceeded = false;

		chosenFiles.map((file) => {
			if (uploaded.findIndex((f) => f.name === file.name) === -1) {
				uploaded.push(file);
			}

			if (uploaded.length === MAX_COUNT) setFileLimit(true);

			if (uploaded.length > MAX_COUNT) {
				UploadFilesSwal.fire({
					title: <h3>Maximum upload file limit warning</h3>,
					didOpen: () => {
						UploadFilesSwal.showLoading();
					},
				}).then(() => {
					UploadFilesSwal.fire(
						<p>{`You can only add a maximum of ${MAX_COUNT} files`}</p>
					);
				});
				setFileLimit(false);
				limitedExceeded = true;
				return true;
			}
		});

		if (!limitedExceeded) {
			console.log(uploaded);
			setSelectedFiles(uploaded);
			setShowModal(true);
		}
	};

	const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
		const chosenFiles = Array.prototype.slice.call(e.target.files);
		handleUploadFiles(chosenFiles);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedFiles([]);
	};

	// const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
	// 	event.preventDefault();
	// 	const files = Array.prototype.slice.call(event.dataTransfer.files);
	// 	handleUploadFiles(files);
	// };

	const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const items = event.dataTransfer.items;
		let containsDirectory = false;

		for (let i = 0; i < items.length; i++) {
			const item = items[i].webkitGetAsEntry();
			if (item && item.isDirectory) {
				containsDirectory = true;
				break;
			}
		}

		if (containsDirectory) {
			alert('You cannot drag folders. Please drag only files.');
		} else {
			const files = Array.prototype.slice.call(event.dataTransfer.files);
			handleUploadFiles(files);
		}
	};

	// return microsoftAuth && isSuccess ? (
	// 	loginInfo && jsonLoginInfo.isAuthenticated ? 
	  return (
			<>
				{getFiles.loading === true || getUserBucket.loading === true ? (
					<div className='loading-bucket'>
						<CircleLoading text='Trying to open upload page...' />
					</div>
				) : (
					<div className='onedrive-upload'>
						<button
							onClick={() => setDragNdrop((p) => !p)}
							className='onedrive-upload-btn'
						>
							Upload
						</button>

						{dragNdrop && (
							<BoxDragnDrop
								setIsDragDrop={setDragNdrop}
								handleFile={handleFileEvent}
								handleCloseModal={handleCloseModal}
								handleFileDrop={handleFileDrop}
								selectedFiles={selectedFiles}
								setSelectedFiles={setSelectedFiles}
								bucket={bucket}
							/>
						)}

						{getFiles.data?.files && getFiles.data.files.length>0 ? (
							<>
								<div className='layout-grid-folder-list'>
									{getFiles.data.files.map((file) => (
										<CardItem
											imageName={
												file.name.includes('iam')
													? IAM
													: file.name.includes('ipt')
													? IPT
													: file.name.includes('zip')
													? ZIP
													: file.name.includes('rvt')
													? RVT
													: file.name.includes('dwg')
													? DWG
													: file.name.includes('fbx')
													? FBX
													: file.name.includes('idw')
													? IDW
													: file.name.includes('dwfx')
													? DWFX
													: ''
											}
											title={file.name}
											buttonNames={[]}
											buttonEvents={[]}
											onDelete={() => {}}
											onClickToCard={() => {
												if (showTranslationModal !== true) {
													setShowTranslationModal(true);
												}
												setSecondSelectedFiles(() => {
													const condition =
														secondSelectedFiles.find(
															(selectedfile) => selectedfile.name === file.name
														) === undefined;
													if (condition) {
														secondSelectedFiles.push({
															name: file.name,
															id: file.id,
															size: file.size!,
														});
													}
													return secondSelectedFiles;
												});
											}}
										/>
									))}
								</div>
							</>
						):(<>
						   	<Container
										className='modal show'
										style={{
											display: 'block',
											position: 'initial',
											alignContent: 'center',
											marginTop: '200px',
										}}
									>
										<Modal.Dialog>
											<Modal.Header>
												<Modal.Title>
													<FontAwesomeIcon icon={faFlushed} />
												</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												There is no file here, if you wanna upload, you can click the button
											</Modal.Body>
										</Modal.Dialog>
									</Container>
						</>)}

						{showTranslationModal ? (
							<MultipleBoxUploadModal
								fileList={secondSelectedFiles}
								setFileList={setSecondSelectedFiles}
								show={showTranslationModal}
								onHide={() => setShowTranslationModal(false)}
								bucket={bucket}
							/>
						) : (
							<></>
						)}
					</div>
				)}
			</>
		)
};

export default BoxUpload;
