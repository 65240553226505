import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UploadGoogleDriveFilesToForgeRequestModel, UploadGoogleDrivesFilesToForgeResponseModel } from "../../../models/googleDrive/upload.googleDrive.files.forge.model";
import { UploadGoogleDriveFilesForgeService } from "../../../services/googleDrive/upload.googleDrive.files.forge.service";

interface UploadFilesToForgeState extends BaseState<UploadGoogleDrivesFilesToForgeResponseModel>{

}


let initialState:UploadFilesToForgeState={
    data:null,
    loading:false,
    error:null
}


export const uploadGoogleDriveFilesToForge=createAsyncThunk("upload/forge",
async(model:UploadGoogleDriveFilesToForgeRequestModel)=>{
    const uploadForgeService=new UploadGoogleDriveFilesForgeService()
    const response=await uploadForgeService.PutAsync(model) as UploadGoogleDrivesFilesToForgeResponseModel
    return response
})


export const uploadGoogleDriveFilesToForgeSlice=createSlice({
    name:"uploadGoogleDriveFilesToForgeSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(uploadGoogleDriveFilesToForge.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(uploadGoogleDriveFilesToForge.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(uploadGoogleDriveFilesToForge.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})

export default uploadGoogleDriveFilesToForgeSlice.reducer