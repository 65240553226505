import { UploadOneDriveFilesToForgeRequestModel } from "../../../models/oneDrive/file/upload.oneDrive.files.forge.request.model";
import { UploadOneDriveFilesToForgeResponseModel } from "../../../models/oneDrive/file/upload.oneDrive.files.forge.response.model";
import { BasePostService } from "../../base/base.post.service";


export class UploadOneDriveFilesToForgeService extends 
BasePostService<UploadOneDriveFilesToForgeRequestModel,UploadOneDriveFilesToForgeResponseModel>{

    constructor(controllerName:string="oneDrive",endpoint:string="upload/forge"){
        super(controllerName,endpoint)
    }
}