import { MicrosoftLoginResponse } from "../../../models/auth/microsoft/microsoft.login.model";
import { BaseGetService } from "../../base/base.get.service";



export class MicrosoftGetTokenService extends BaseGetService<MicrosoftLoginResponse>{

    constructor(controllerName= "microsoft/auth/login",endpoint=""){
        super(controllerName,endpoint)
    }
}