

import '../styles/register/register.css';
import Footer from '../components/Footer/Footer';
import RegisterForm from '../components/User/RegisterForm';
const Register = () => {
  return (
    <div className='page-container'>
      <div className='page-wrapper'>
        <div className='register-container'>
          <div className='register-card'>
            <div className='card-body'>
              <RegisterForm/>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Register;
