import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { BoxLoginRequest, BoxLoginResponse } from "../../../models/auth/box/box.login.model";
import { BoxPostTokenService } from "../../../services/auth/box/box.post.token.service";



interface BoxAuthPostState extends BaseState<BoxLoginResponse>{

}

let initialState:BoxAuthPostState={
    data: null,
    loading: false,
    error: null,
}

export const fetchBoxAuth=createAsyncThunk('fetch/postAuth',async(request:BoxLoginRequest)=>{
    const service=new BoxPostTokenService()
    const response=await service.PostAsync(request)
    return response
})

export const boxAuthSlice = createSlice({
    name:'boxAuthSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
        .addCase(fetchBoxAuth.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(fetchBoxAuth.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(fetchBoxAuth.rejected, (state, action: any) => {
          state.loading = false;
          state.error = action.payload
        });
    },
});

export default boxAuthSlice.reducer