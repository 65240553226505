import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetUploadedObjectHtmlContentRequestModel, GetUploadedObjectHtmlContentResponseModel } from "../../../models/object/uploadedObject/get.uploadedObject.html.content.model";
import { GetUploadedObjectHtmlContentService } from "../../../services/uploadedObject/get.uploadedObject.html.content.service";

interface GetUploadedObjectHtmlContentState extends BaseState<GetUploadedObjectHtmlContentResponseModel>{

}


let initialState:GetUploadedObjectHtmlContentState={
    data:null,
    error:null,
    loading:false
}


export const getUploadedObjectHtmlContent=createAsyncThunk("uploadedObject/htmlContent",
async(request:GetUploadedObjectHtmlContentRequestModel)=>{
    const service=new GetUploadedObjectHtmlContentService("objects","content")  
    const response=await service.GetByStringParamAsync(request.urn)
    return response
})


export const getUploadedObjectHtmlContentSlice=createSlice({
    name:"getUploadedObjectHtmlContentSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(getUploadedObjectHtmlContent.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(getUploadedObjectHtmlContent.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(getUploadedObjectHtmlContent.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })      
    }
})

export default getUploadedObjectHtmlContentSlice.reducer