import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetBoxFolderModel, GetBoxFolderRequestModel } from "../../../models/box/get.box.folder.model";
import { GetBoxFolderService } from "../../../services/box/get.box.folder.service";

interface GetFolderState extends BaseState<GetBoxFolderModel>{

}


let initialState:GetFolderState={
    data:null,
    loading:false,
    error:null
}


export const fetchBoxFolder=createAsyncThunk("fetch/BoxFolder",async(model:GetBoxFolderRequestModel)=>{
    const service=new GetBoxFolderService()
    const response=await service.GetByStringManyParamsAsync([model.bucketName,model.folderName])
    return response
})


export const getBoxFolderSlice=createSlice({
    name:"getBoxFolderSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchBoxFolder.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchBoxFolder.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchBoxFolder.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getBoxFolderSlice.reducer