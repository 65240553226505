import { DeleteFileRequestModel, DeleteFileResponseModel } from "../../../models/oneDrive/file/delete.file.model";
import { BaseDeleteService } from "../../base/base.delete.service";



export class DeleteFileService extends BaseDeleteService<DeleteFileResponseModel>{

    constructor(controllerName:string="oneDrive",endpoint:string="delete/file"){
        super(controllerName,endpoint)
    }
}