import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UploadAWSPrevFilesRequestModel, UploadAWSPrevFilesResponseModel } from "../../../models/aws/upload.prev.aws.model";
import { UploadPrevAWSService } from "../../../services/aws/upload.prev.aws.service";




interface UploadPrevFilesState extends 
BaseState<UploadAWSPrevFilesResponseModel>{

}


let initialState:UploadPrevFilesState={
    data:null,
    loading:false,
    error:null
}



export const uploadAWSPrevFiles=createAsyncThunk("upload/prevAWSFiles",
async(model:UploadAWSPrevFilesRequestModel)=>{

    const uploadPrevFilesService=new UploadPrevAWSService()
    const response=await uploadPrevFilesService.UploadWithFormDataAsync(model)
    return response
})


export const uploadAWSPrevFilesSlice=createSlice({
    name:"uploadAWSPrevFilesSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(uploadAWSPrevFiles.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(uploadAWSPrevFiles.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(uploadAWSPrevFiles.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})


export default uploadAWSPrevFilesSlice.reducer