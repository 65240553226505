import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetFolderListModel } from "../../../models/oneDrive/folder/get.folder.list.model";
import { GetFolderListService } from "../../../services/oneDrive/folder/get.folder.list.service";




interface GetAllFoldersState extends BaseState<GetFolderListModel>{

}


let initialState:GetAllFoldersState={
    data:null,
    loading:false,
    error:null
}


export const fetchAllFolders=createAsyncThunk("fetch/allFolders",async(folderName:string)=>{
    const folderService=new GetFolderListService()
    const response=await folderService.GetByStringParamAsync(folderName)
    return response
})



export const getAllFoldersSlice=createSlice({
    name:"getAllFoldersSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAllFolders.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAllFolders.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAllFolders.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAllFoldersSlice.reducer