import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UserCreditModel } from "../../../models/dashboard/get.user.credit.model";
import { GetUserCreditService } from "../../../services/dashboard/get.user.credit.service";




interface GetUserCreditState extends BaseState<UserCreditModel[]>{

}


let initialState:GetUserCreditState={
    data:null,
    loading:false,
    error:null
}


export const fetchUserCredit=createAsyncThunk("fetch/UserCredit",
async(bucketName:string)=>{
    const service=new GetUserCreditService()
    const response=await service.GetByStringParamAsync(bucketName)
    return response
})


export const getUserCreditSlice=createSlice({
    name:"getUserCreditSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder.addCase(fetchUserCredit.pending,(state,action)=>{
            state.loading=true
        }).addCase(fetchUserCredit.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchUserCredit.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})

export default getUserCreditSlice.reducer