import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetIAMFilesModelResponse,GetAWSIAMFilesRequest } from "../../../models/oneDrive/file/get.iam.files.model";
import { GetIAMFilesService } from "../../../services/aws/get.iam.files.service";




interface GetAllFilesState extends BaseState<GetIAMFilesModelResponse>{

}


let initialState:GetAllFilesState={
    data:null,
    loading:false,
    error:null
}


export const fetchAWSIAMFiles=createAsyncThunk("fetch/IAMFiles",async(model:GetAWSIAMFilesRequest)=>{
    const service=new GetIAMFilesService()
    const response=await service.GetByStringManyParamsAsync([model.bucketName,model.fileName])
    return response
})


export const getAWSIAMFilesSlice=createSlice({
    name:"getFileSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAWSIAMFiles.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAWSIAMFiles.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAWSIAMFiles.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAWSIAMFilesSlice.reducer