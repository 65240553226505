import React from 'react';
import '../../styles/onedrive/multipleUploadModel.css'
import { Button, Form, Modal } from 'react-bootstrap';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { LinearWithValueLabel } from '../LinearProgressBar';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { baseUrl } from '../../config/axios.config';
import LittleCircleLoading from '../Loading/LittleCircleLoading';
import { GetFolderItemModel } from '../../models/oneDrive/folder/get.folder.item.model';
import { AWSData } from '../../pages/aws/AWSUpload';
import { uploadAWSFilesToForge } from '../../store/features/aws/upload.aws.files.forge.slice';
import { fetchAWSFolder } from '../../store/features/aws/get.aws.folder.slice';
import { GetIAMFilesModelResponse } from '../../models/oneDrive/file/get.iam.files.model';
import CircleLoading from '../Loading/CircleLoading';


interface AddedFileListProps {
    file: string;
    bucket: string | undefined
    name: string;
    handleRemoveClick: () => void;
    fileNames: string[]
}

interface MultipleAWSUploadModalProps {
    show: boolean;
    onHide: () => void;
    fileList: AWSData[];
    setFileList: React.Dispatch<React.SetStateAction<AWSData[]>>;
    bucket: string | undefined;
    iamFileList:GetIAMFilesModelResponse|undefined
}

const AddedFileList: React.FC<AddedFileListProps> = ({
    file,
    bucket,
    name,
    handleRemoveClick,
    fileNames
}) => {
    const [isPrompt, setIsPrompt] = useState(false);
	const [folder, setFolder] = useState({
		name: "",
		check: false
	})

    const dispatch = useAppDispatch();
    const folderSelector = useAppSelector((x) => x.getAWSfolderslice);
  

    useEffect(() => {
        if (bucket && file) {
            const existFolderName = `${bucket}-${file}`;
            dispatch(fetchAWSFolder({
                bucketName:bucket,
                folderName:existFolderName
            }));
        }
    }, [bucket, file, dispatch]);

    useEffect(() => {
        if (
            folderSelector &&
            folderSelector.data &&
            folderSelector.data.folder &&
            !folderSelector.error &&
            !folderSelector.loading
        ) {
            const folder = folderSelector.data.folder as GetFolderItemModel;
            if (folder && folder.name) {
                setFolder({
                    ...folder,
                    check: false
                });
                setIsPrompt(true);
            }
        }
    }, [folderSelector]);

    return (
        <>
            {name && file && (
                <div className='addedFile'>
                    <li id={`${name}`}>{file} </li>
                    {!folderSelector.loading ? (
                        <FontAwesomeIcon
                            className='trashIcon'
                            icon={faTrash}
                            onClick={(e) => {
                                handleRemoveClick();
                                e.preventDefault();
                            }}
                        />
                    ) : (
                        <LittleCircleLoading />
                    )}

                    {/* {isPrompt && folder && (
                        <UpdateOrCopyModal
                            id={folder.id || ''}
                            type={"folder"}
                            name={folder.name || ''}
                            show={isPrompt}
                            onHide={() => setIsPrompt(false)}
                            files={fileIds}
                            check={folder.check}
                            setFolder={setFolder}
                        />
                    )} */}
                </div>
            )}
        </>
    );
};

export const MultipleAWSUploadModal: React.FC<MultipleAWSUploadModalProps> = ({
    show,
    onHide,
    fileList,
    setFileList,
    bucket,
    iamFileList
}) => {
    const dispatch = useAppDispatch();
    const uploadIndexRef = useRef(0);

    const uploadAWSFilesSelector = useAppSelector(
        (s: RootState) => s.uploadAWSFilesToForgeSlice
    );
    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState("");
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const fetchIAMFilesSelector=useAppSelector((s: RootState) =>s.getAWSIAMFilesSlice)
    const [rootFile,setRootFile] = useState("")

    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(`${baseUrl}progress`)
            .withAutomaticReconnect()
            .build();

        setConnection(connection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.on('ReceiveProgress', (progressValue, stepValue) => {
                console.log(progressValue)
                console.log(stepValue)
                setProgress(progressValue || 0);
                setStep(stepValue || '');
            });

            return () => {
                if (isConnected) {
                    connection
                        .stop()
                        .then(() => {
                            console.log("Connection successfully stopped.")
                        });
                }
            };
        }
    }, [connection, isConnected]);

    const uploadFilesSequentially = (fileNames: string[], bucketKey: string) => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    console.log('Connection successfully established.');
                    setIsConnected(true);

                    const uploadNextFile = () => {
                        if (uploadIndexRef.current < fileNames.length) {
                            const fileName = fileNames[uploadIndexRef.current];
                            dispatch(
                                uploadAWSFilesToForge({
                                    fileNames:[fileName],
                                    bucketKey,
                                })
                            ).then(() => {
                                // Yükleme tamamlandı, bir sonraki dosyayı yükle
                                uploadIndexRef.current++;
                                uploadNextFile();
                            });
                        }
                    };

                    // İlk dosyayı başlat
                    uploadNextFile();
                })
                .catch((err) =>
                    console.log('Error while establishing the connection: ', err)
                );
        }
    };

    useEffect(() => {
        const { loading: uploadLoading, error: uploadError, data: uploadData } = uploadAWSFilesSelector;

        if (!uploadLoading && uploadData && uploadData.isSuccess) {
            // Dosya başarıyla yüklendiğinde buraya girecek
            if (uploadIndexRef.current === fileList.length - 1) {
                // Eğer son dosyaysa, işlem tamamlandı
                console.log("All files uploaded successfully!");
                // İşlem tamamlandığında state'i sıfırla veya modal'i kapat
                setFileList([]);
               // onHide();
            } else {
                // Bir sonraki dosyayı yükle
                uploadFilesSequentially(fileList.map((file) => file.name), bucket as string);
            }
        } else if (!uploadLoading && uploadError) {
            // Hata durumunda
            console.error("Error uploading file:", uploadError);
            // İşlemi durdur veya gerekli adımları atla
        }
    }, [uploadAWSFilesSelector, fileList, bucket, onHide, setFileList]);

    return (
        <Modal
            show={show}
            onHide={() => {
                if(uploadAWSFilesSelector.loading===false &&
                    uploadAWSFilesSelector.data!==null && uploadAWSFilesSelector.error===null){
                       window.location.reload();
                    }
                    else{
                        onHide();
                    }
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    fetchIAMFilesSelector.loading  ? (
                        <div className="text-center">
                            <CircleLoading text='Extracting all iam files from zip'/>
                        </div>
                    ):(
                        <Form>         
                    <Form.Group>
                        {
                            iamFileList!==undefined && iamFileList.iamFiles.length>0 && rootFile===""?(
                                <div className='zipFile'>
                                <h4>{iamFileList.mainZipFileName}</h4>
                                <div className='zip-file-item'>
                                    {iamFileList.iamFiles.map((zipSubFile,i)=>(
                                        <li key={i}>
                                            <label>
                                                <input type='checkbox'onClick={() =>{
                                                    const splitList=zipSubFile.name.split("/")
                                                    console.log(splitList)
                                                    const newRootFileName=splitList[splitList.length - 1]
                                                    console.log(newRootFileName)

                                                    setRootFile(newRootFileName)
                                                    //setOpenZipOption(false)
                                                }}
                                                        />
                                                        {zipSubFile.name.split("/")[zipSubFile.name.split("/").length - 1]} &nbsp;
                                            </label>
                                        </li>
                                    ))}
                                </div>
                            </div>
                            ):(
                                <>
                                <Form.Label>{fileList.length > 1 ? "Files" : "File"}</Form.Label>
                                {fileList.map((file) => (
                                    <AddedFileList
                                        key={file.name}
                                        fileNames={fileList.map(file => file.name)}
                                        file={file.name || ""}
                                        handleRemoveClick={() => {
                                            const index = fileList.indexOf(file);
                                            if (index > -1) {
                                                if (index === 0) {
                                                    setFileList([]);
                                                } else {
                                                    setFileList((prev) => {
                                                        prev = prev.splice(index, 1);
                                                        return prev;
                                                    });
                                                }
                                            }
                                        }}
                                        name={file.name || ""}
                                        bucket={bucket}
                                    />
                                ))}
                              </>
                            
                            )
                           
                        }
                      </Form.Group>
                </Form>
                    )
                }
                
            </Modal.Body>
            <Modal.Footer>
                {uploadAWSFilesSelector.data && !uploadAWSFilesSelector.loading &&
                    !uploadAWSFilesSelector.error &&
                    uploadAWSFilesSelector.data.isSuccess && progress === 100 && (
                        <>
                            {fileList.length > 1 ? (
                                <p style={{ color: "green", marginRight: "auto" }}>
                                    Files have been uploaded succesfully
                                </p>
                            ) : (
                                    <p style={{ color: "green", marginRight: "auto" }}>
                                        File has been uploaded successfully
                                    </p>
                                )}
                        </>
                    )}
                {bucket && (
                    <Button
                        className='uploadButton'
                        disabled={uploadAWSFilesSelector.loading || fileList.length === 0}
                        variant={uploadAWSFilesSelector.loading ? "secondary" : "primary"}
                        hidden={progress === 100}
                        onClick={() => {
                            uploadFilesSequentially(fileList.map((file) => file.name), bucket as string);
                        }}
                    >
                        Translate {fileList.length > 1 ? "Files" : "File"}
                    </Button>
                )}

                {uploadAWSFilesSelector.loading && (
                    <LinearWithValueLabel progress={progress} step={step} />
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default MultipleAWSUploadModal;
