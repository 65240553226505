import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetTokenModel } from "../../../models/auth/get.token.model";
import { TokenGetService } from "../../../services/auth/token.get.service";




interface GetTokenState extends BaseState<GetTokenModel>{

}

let initialState:GetTokenState={
    data:null,
    loading:false,
    error:null
}


export const fetchToken=createAsyncThunk("fetch/token",async()=>{

    const service=new TokenGetService()

    const response=await service.GetAsync()

    return response
})

export const getTokenSlice=createSlice({
    name:"getTokenSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchToken.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchToken.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchToken.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getTokenSlice.reducer