
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import GetBucketResponseModel from "../../../models/bucket/get.bucket.response.model";
import { GetBucketService } from "../../../services/bucket/get.bucket.service";



interface GetBucketListState{
    loading:boolean,
    data:GetBucketResponseModel|null,
    error:null|string
}


let initialState:GetBucketListState={
    loading:false,
    data:null,
    error:null
}


export const fetchBucket=createAsyncThunk("bucket/fetch",async(bucketKey:string)=>{

    const getBucketListService=new GetBucketService("buckets","get")
    const response=await getBucketListService.GetByStringParamAsync(bucketKey)

    return response
})


export const getBucketSlice=createSlice({
    name:"getBucket",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(fetchBucket.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchBucket.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchBucket.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
        
    }
    
})


export default getBucketSlice.reducer