import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { TokenRequest, TokenResponse } from "../../../models/auth/auth.token.model";
import { TokenPostService } from "../../../services/auth/token.post.service";
import { TokenRefreshService } from "../../../services/auth/token.refresh.service";




interface PostTokenState extends BaseState<TokenResponse>{

}

let initialState:PostTokenState={
    data:null,
    loading:false,
    error:null
}


export const postToken=createAsyncThunk("post/token",async(request:TokenRequest)=>{

   

    const service=new TokenPostService()
    const response=await service.PostAsync(request)

    if(sessionStorage.getItem("login")!==null){
        const loginInfo=sessionStorage.getItem("login") as string
        const jsonLoginInfo=JSON.parse(loginInfo) as TokenResponse

        if(new Date(jsonLoginInfo.expiresAt).getMilliseconds()<Date.now()){

            

            const refreshService=new TokenRefreshService()
            const Refreshresponse=await refreshService.GetAsync()


            sessionStorage.removeItem("login")
            sessionStorage.setItem("login",JSON.stringify({
                isAuthenticated:Refreshresponse.isAuthenticated,
                expiresAt:Refreshresponse.expiresAt
            }))

            return Refreshresponse
        }
        else{
            return response
        }
    }
    else{
        return response
    }
})



export const postTokenSlice=createSlice({
    name:"postTokenSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(postToken.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(postToken.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(postToken.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default postTokenSlice.reducer