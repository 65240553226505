import { UploadGoogleDriveFilesToForgeRequestModel, UploadGoogleDrivesFilesToForgeResponseModel } from "../../models/googleDrive/upload.googleDrive.files.forge.model";
import { BasePostService } from "../base/base.post.service";
import { BasePutService } from "../base/base.put.service";




export class UploadGoogleDriveFilesForgeService extends 
BasePutService<UploadGoogleDriveFilesToForgeRequestModel,UploadGoogleDrivesFilesToForgeResponseModel>{

    constructor(controllerName:string="googleDrive",endpoint:string="upload/forge"){
        super(controllerName,endpoint)
    }
}