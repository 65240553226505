
import GetBucketResponseModel from "../../models/bucket/get.bucket.response.model";
import { BaseGetService } from "../base/base.get.service";



export class GetUserBucketService extends BaseGetService<GetBucketResponseModel>{
    constructor(controllerName:string="buckets",endpointName:string="user"){
        super(controllerName,endpointName)
    }
}