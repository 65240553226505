import React from 'react';
import { Container, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const OneDriveUnauthorized = () => {
    return (
        <Container className="d-flex justify-content-center align-items-center min-vh-100">
            <Card className="text-center" style={{ maxWidth: '90%', width: '28rem', padding: '20px' }}>
                <Card.Img 
                    variant="top" 
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Microsoft_Office_OneDrive_%282019%E2%80%93present%29.svg/2560px-Microsoft_Office_OneDrive_%282019%E2%80%93present%29.svg.png" 
                    style={{ width: '50%', margin: '20px auto' }} 
                />
                <Card.Body>
                    <Card.Text style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        Unauthorized!
                    </Card.Text>
                    <Card.Text>
                        You have to sign in to Microsoft before accessing OneDrive.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default OneDriveUnauthorized;
