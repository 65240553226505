import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { DeleteUploadedObjectResponseModel } from "../../../models/object/uploadedObject/delete.uploadedObject.response.model";
import { DeleteUploadedObjectService } from "../../../services/uploadedObject/delete.uploadedObject.service";
import { DeleteUploadedObjectRequestModel } from "../../../models/object/uploadedObject/delete.uploadedObject.request.model";




interface DeleteUploadedObjectState extends BaseState<DeleteUploadedObjectResponseModel>{

}


let initialState:DeleteUploadedObjectState={
    data:null,
    error:null,
    loading:false
}



export const deleteUploadedObject=createAsyncThunk("uploadedObject/delete",
async(request:DeleteUploadedObjectRequestModel)=>{

    const service=new DeleteUploadedObjectService()
    const response=await service.DeleteByStringManyParamByAsync([request.bucketKey,request.objectKey])
    return response
})


export const deleteUploadedObjectSlice=createSlice({
    name:"createUploadedObjectSlice",
    initialState,
    reducers:{
        resetDeleteState:()=>{
            initialState.data=null
            initialState.error=null
            initialState.loading=false
        }
    },
    extraReducers(builder){
        builder
        .addCase(deleteUploadedObject.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(deleteUploadedObject.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(deleteUploadedObject.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
        
    }
})


export const {resetDeleteState}=deleteUploadedObjectSlice.actions
export default deleteUploadedObjectSlice.reducer











