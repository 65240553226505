import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetGoogleDriveFolderModel, GetGoogleDriveFolderRequestModel } from "../../../models/googleDrive/get.googleDrive.folder.model";
import { GetGoogleDriveFolderService } from "../../../services/googleDrive/get.googleDrive.folder.service";

interface GetFolderState extends BaseState<GetGoogleDriveFolderModel>{

}


let initialState:GetFolderState={
    data:null,
    loading:false,
    error:null
}


export const fetchGoogleDriveFolder=createAsyncThunk("fetch/GoogleDriveFolder",async(model:GetGoogleDriveFolderRequestModel)=>{
    const service=new GetGoogleDriveFolderService()
    const response=await service.GetByStringManyParamsAsync([model.bucketName,model.folderName])
    return response
})


export const getGoogleDriveFolderSlice=createSlice({
    name:"getAllGoogleDriveFilesSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchGoogleDriveFolder.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchGoogleDriveFolder.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchGoogleDriveFolder.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getGoogleDriveFolderSlice.reducer