import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ForgotPasswordRequest, ForgotPasswordResponse } from '../../../models/forgotPassword/forgot.password.model';
import { ForgotPasswordPostService } from '../../../services/forgotPassword/forgot.password.service';
import { BaseState } from '../../../commons/base.state';

interface ForgotPasswordState extends BaseState<ForgotPasswordResponse> { }

const initialState: ForgotPasswordState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchForgotPassword = createAsyncThunk(
  'auth/fetchForgotPassword',
  async (request: ForgotPasswordRequest, thunkAPI) => {
    try {
      const service = new ForgotPasswordPostService();
      const response = await service.PostAsync(request);
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      const errorData = err.response.data;
      return thunkAPI.rejectWithValue({
        error: errorData.error ? errorData.error : errorData.message,
      });
    }
  }
);

const forgotPasswordSlice = createSlice({
  name: 'forgotPasswordSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchForgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchForgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchForgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default forgotPasswordSlice.reducer;
