import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetFileViewUrlModel} from "../../../models/oneDrive/file/get.file.view.url.model";
import { GetFileViewUrlService } from "../../../services/oneDrive/file/get.file.view.url.service";

interface GetFileViewUrlState extends BaseState<GetFileViewUrlModel>{

}


let initialState:GetFileViewUrlState={
    data:null,
    loading:false,
    error:null
}


export const fetchFileViewUrl=createAsyncThunk("fetch/FileViewUrl",async(fileId:string)=>{
    const service=new GetFileViewUrlService()
    const response=await service.GetByStringParamAsync(fileId)
    return response
})


export const getFileViewUrlSlice=createSlice({
    name:"getFileViewUrlSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchFileViewUrl.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchFileViewUrl.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchFileViewUrl.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getFileViewUrlSlice.reducer