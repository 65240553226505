// ConfirmDeleteModal.js
import React from 'react';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import CircleLoading from '../Loading/CircleLoading';

interface ConfirmDeleteModalProps{
  show:boolean,
  handleClose:()=>void,
  handleConfirm:()=>void,
  itemName:string,
  isContinue:boolean,
  deleteText:string
}

const ConfirmDeleteModal:React.FC<ConfirmDeleteModalProps>=({
  show,
  handleClose,
  handleConfirm,
  itemName,
  isContinue,
  deleteText
})=> {
  return (
    <>

    {
       isContinue?(<Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <>
          <CircleLoading text={deleteText}/>
          </>
        </Modal.Body>
      </Modal>):(<Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete the folder {itemName}?
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
        <Button variant="primary" onClick={handleClose}>No</Button>
        <Button variant="danger" onClick={handleConfirm}>Yes</Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>)
    }
    
    </>
    
  );
};

export default ConfirmDeleteModal;
