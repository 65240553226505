import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetFileService } from "../../../services/oneDrive/file/get.file.service";
import { GetFileModel, GetFileRequestModel } from "../../../models/oneDrive/file/get.file.model";




interface GetAllFilesState extends BaseState<GetFileModel>{

}


let initialState:GetAllFilesState={
    data:null,
    loading:false,
    error:null
}


export const fetchFile=createAsyncThunk("fetch/file",async(model:GetFileRequestModel)=>{
    const service=new GetFileService()
    const response=await service.GetByStringManyParamsAsync([model.bucketName,model.id])
    return response
})


export const getFileSlice=createSlice({
    name:"getFileSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchFile.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchFile.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchFile.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getFileSlice.reducer