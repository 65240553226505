import { TokenRequest, TokenResponse } from "../../models/auth/auth.token.model";
import { BasePostService } from "../base/base.post.service";



export class TokenPostService extends BasePostService<TokenRequest,TokenResponse>{

    constructor(controllerName:string="auth",endpointName:string="token"){
        super(controllerName,endpointName)
    }
}