// src/components/EmailConfirmed.js

import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/email/confirmed.scss'; // Optional: For custom styling

const EmailConfirmed = () => {
    return (
        <div className="email-confirmed-container">
            <div className="email-confirmed-content">
                <h1>Email Confirmed</h1>
                <p>Your email has been successfully confirmed. You can now log in to your account.</p>
                <Link to="/login" className="login-button">
                    Go to Login
                </Link>
            </div>
        </div>
    );
};

export default EmailConfirmed;
