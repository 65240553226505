import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetGoogleDriveFolderListModel } from "../../../models/googleDrive/get.googleDrive.folders.model";
import { GetAllGoogleDriveFoldersService } from "../../../services/googleDrive/get.all.googleDrive.folders.service";

interface GetAllFoldersState extends BaseState<GetGoogleDriveFolderListModel>{

}


let initialState:GetAllFoldersState={
    data:null,
    loading:false,
    error:null
}


export const fetchAllGoogleDriveFolders=createAsyncThunk("fetch/allGoogleDriveFolders",async(bucketName:string)=>{
    const service=new GetAllGoogleDriveFoldersService()
    const response=await service.GetByStringParamAsync(bucketName)
    return response
})


export const getAllGoogleDriveFoldersSlice=createSlice({
    name:"getAllGoogleDriveFilesSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchAllGoogleDriveFolders.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAllGoogleDriveFolders.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAllGoogleDriveFolders.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAllGoogleDriveFoldersSlice.reducer