
import { GetGoogleDriveFolderModel } from "../../models/googleDrive/get.googleDrive.folder.model";

import { BaseGetService } from "../base/base.get.service";



export class GetGoogleDriveFolderService extends BaseGetService<GetGoogleDriveFolderModel>{
    constructor(controllerName:string="googleDrive",endpoint:string="folders"){
        super(controllerName,endpoint)
    }
    
}