import {createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BaseState } from '../../../commons/base.state';
import { GoogleLoginRequest, GoogleLoginResponse } from '../../../models/auth/google/google.login.model';
import { GoogleGetTokenService } from '../../../services/auth/google/google.get.token.service';

interface GoogleAuthPostState extends BaseState<GoogleLoginResponse> { }

let initialState: GoogleAuthPostState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchGoogleAuth = createAsyncThunk(
  'fetch/postAuth',
  async ({code}:GoogleLoginRequest, thunkAPI) => {

    try {
      const service = new GoogleGetTokenService()
      const response = await service.GetByQueryParameter(`code=${code}`)
      return response
    }

    catch (err: any) {
      if (!err.response) {
        throw err
      }


      const errorData=err.response.data
      
      return thunkAPI.rejectWithValue({
        error: errorData.error?errorData.error:errorData.message
      })

    }





  }
);

export const googleAuthSlice = createSlice({
  name: 'googleAuthSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGoogleAuth.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchGoogleAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchGoogleAuth.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload
      });
  },
});

export default googleAuthSlice.reducer
