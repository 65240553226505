import React, { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import CardItem from '../../components/CardItem';
import DragnDrop from '../../components/OneDrive/DragnDrop';
import IAM from '../../assets/IAM.png';
import IPT from '../../assets/IPT.png';
import ZIP from '../../assets/zip.svg';
import RVT from '../../assets/RVT.jpg';
import DWG from '../../assets/DWG.jpg';
import FBX from '../../assets/FBX.png';
import DWFX from '../../assets/DWFX.png';
import IDW from '../../assets/IDW.png';
import '../../styles/onedrive/upload.css';
import { fetchAllFiles } from '../../store/features/oneDrive/get.all.files.slice';
import { fetchAllFolders } from '../../store/features/oneDrive/get.all.folders.slice';
import CircleLoading from '../../components/Loading/CircleLoading';
import ConfirmDeleteModal from '../../components/ConfirmModals/ConfirmDeleteModal';
import { deleteFile } from '../../store/features/oneDrive/delete.file.slice';
import { deleteFolder } from '../../store/features/oneDrive/delete.folder.slice';
import TextAreaModal from '../../components/Modals/TextAreaModal';
import http, { baseUrl } from '../../config/axios.config';
import { OneDriveFileData } from './FileList';
import MultipleOneDriveUploadModal from '../../components/MultipleUploadModal/MultipleOneDriveUploadModal';
import { LoginResponse } from '../../models/auth/login.model';
import OneDriveUnauthorized from './Unauthorized';
import { SaveCodeResponseModel } from '../../models/oneDrive/auth/save.code.response.model';
import { SelectableZipFiles } from '../object/ObjectList';
import { GetIAMFilesModelResponse } from '../../models/oneDrive/file/get.iam.files.model';
import { fetchIAMFiles } from '../../store/features/oneDrive/get.iam.files.service';

const CombinedComponent: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showTranslationModal, setShowTranslationModal] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [secondSelectedFiles, setSecondSelectedFiles] = useState<OneDriveFileData[]>([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [dragNdrop, setDragNdrop] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDeleteFile, setItemToDeleteFile] = useState({ id: '', name: '' });
  const [itemToDeleteFolder, setItemToDeleteFolder] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [htmlAreaModal, setHtmlAreaModal] = useState(false);
  const [loading, setLoading] = useState({ loading: false, itemName: '' });
  const [generateLoading, setGenerateLoading] = useState({ loading: false, itemName: '' });

  const [zipFile, setZipFile] = useState<SelectableZipFiles | undefined>();
  const [zipOption, setOpenZipOption] = useState(false)

  const dispatch = useAppDispatch();
  const getUserBucket = useAppSelector((s: RootState) => s.getUserBucket);
  const getFiles = useAppSelector((s: RootState) => s.getAllFiles);
  const folderListSelector = useAppSelector((s: RootState) => s.getFolderList);
  const folderList = folderListSelector.data;
  const loginInfo = sessionStorage.getItem('login') as string;
  const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse;
  const bucket = sessionStorage.getItem('b') as string;
  const microsoftAuth = sessionStorage.getItem('microsoft-auth') as string;
  const microsoftAuthInfo = JSON.parse(microsoftAuth) as SaveCodeResponseModel

  const MAX_COUNT = 40;
  const UploadFilesSwal = withReactContent(Swal);
  const deleteFileSelector = useAppSelector((s: RootState) => s.deleteFileSlice);
  const deleteFolderSelector = useAppSelector((s: RootState) => s.deleteFolderSlice);
  const [iamFiles, setIamFiles] = useState<GetIAMFilesModelResponse | undefined>(undefined)

  const iamFilesSelector=useAppSelector((s:RootState)=>s.getIAMFilesSlice)

  useEffect(() => {
    dispatch(fetchAllFiles(bucket));
    dispatch(fetchAllFolders(bucket));
  }, [dispatch, bucket]);

  const handleUploadFiles = (chosenFiles: File[]) => {
    const uploaded = [...selectedFiles];
    let limitedExceeded = false;

    chosenFiles.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        if (file.name.endsWith('.zip')) {
          setOpenZipOption(true)
        }
        uploaded.push(file);
      }

      if (uploaded.length === MAX_COUNT) setFileLimit(true);

      if (uploaded.length > MAX_COUNT) {
        UploadFilesSwal.fire({
          title: <h3>Maximum upload file limit warning</h3>,
          didOpen: () => {
            UploadFilesSwal.showLoading();
          },
        }).then(() => {
          UploadFilesSwal.fire(<p>{`You can only add a maximum of ${MAX_COUNT} files`}</p>);
        });
        setFileLimit(false);
        limitedExceeded = true;
        return true;
      }
    });

    if (!limitedExceeded) {
      setSelectedFiles(uploaded);
      setShowModal(true);
    }
  };

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleCloseModal = () => {
    setShowTranslationModal(false)
    setShowModal(false);
    setSelectedFiles([]);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteFile = (fileId: string, bucketName: string) => {
    dispatch(deleteFile({ fileId, bucketName }))
      .then(() => {
        setShowDeleteModal(false);
        dispatch(fetchAllFiles(bucket));
      })
      .catch((error) => {
        console.error('Error deleting file:', error);
      });
  };

  const handleDeleteFolder = (folderName: string, bucketName: string) => {
    dispatch(deleteFolder({ folderName, bucketName }))
      .then(() => {
        setShowDeleteModal(false);
        dispatch(fetchAllFolders(bucket));
      })
      .catch((error) => {
        console.error('Error deleting folder:', error);
      });
  };

  const renderFileIcon = (fileExtension: string) => {
    switch (fileExtension) {
      case 'zip':
        return ZIP;
      case 'iam':
        return IAM;
      case 'ipt':
        return IPT;
      case 'rvt':
        return RVT;
      case 'dwg':
        return DWG;
      case 'fbx':
        return FBX;
      case 'dwfx':
        return DWFX;
      case 'idw':
        return IDW;
      default:
        return "";
    }
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const items = event.dataTransfer.items;
    let containsDirectory = false;

    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry();
      if (item && item.isDirectory) {
        containsDirectory = true;
        break;
      }
    }

    if (containsDirectory) {
      alert('You cannot drag folders. Please drag only files.');
    } else {
      const files = Array.prototype.slice.call(event.dataTransfer.files);
      handleUploadFiles(files);
    }
  };

  const handleEmbedCodeAndOpen = async (name: string, fileName: string) => {
    let folderName = `${name}-${fileName}`
    const request = http('oneDrive', `files/view/add/${encodeURIComponent(folderName)}`);
    setLoading({ loading: true, itemName: name });
    await request.get('').then(() => {
      setLoading({ loading: false, itemName: '' });

      window.open(`${baseUrl}oneDrive/files/${encodeURIComponent(folderName.replace('%20', ' '))}/view/get/index.html`);
    });
  };

  const handleGenerateEmbedCode = async (name: string, fileName: string) => {
    let folderName = `${name}-${fileName}`
    folderName = folderName.replace('%20', ' ')
    const request = http('oneDrive', `files/view/add/${encodeURIComponent(folderName)}`);
    setGenerateLoading({ loading: true, itemName: name });
    await request.get('').then(() => {
      folderName = folderName.replace('%20', ' ')
      const viewUrl = `${baseUrl}oneDrive/files/${encodeURIComponent(folderName)}/view/get/index.html`;
      setGenerateLoading({ loading: false, itemName: '' });
      setHtmlContent(
        `<iframe frameborder='0' width='500px' height='500px' allowfullscreen mozallowfullscreen='true' webkitallowfullscreen='true' 
        fullscreen; xr-spatial-tracking xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share
        src='${viewUrl}'></iframe>`
      );
      setHtmlAreaModal(true);
    });
  };

  const handleTranslateFile = (file: OneDriveFileData) => {
    setSecondSelectedFiles([file]);
    setShowTranslationModal(true);
  };

  return (
    <>
      {
        microsoftAuthInfo && microsoftAuthInfo.isSuccess ? (
          <>
            {getUserBucket.loading || getFiles.loading || folderListSelector.loading ? (

              <CircleLoading text={'Trying to open models...'} />
            ) : (
              <>

                <button onClick={() => setDragNdrop((p) => !p)} className='onedrive-upload-btn'>
                  Upload
                </button>
                {dragNdrop && (
                  <DragnDrop
                    setIsDragDrop={setDragNdrop}
                    handleFile={handleFileEvent}
                    handleCloseModal={handleCloseModal}
                    handleFileDrop={handleFileDrop}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    bucket={bucket}
                  />
                )}
                <div className='layout-grid-folder-list'>
                  {folderList &&
                    getFiles.data &&
                    getFiles.data.files.map((file, i) => {
                      const fileExtension = file.name.split('.').pop();
                      const fileIcon = renderFileIcon(fileExtension as string);
                      const existFolder = folderList.folders.find((folder) => folder.name === `${bucket}-${file.name}`);
                      console.log(fileIcon)

                      return (
                        <CardItem
                          key={file.id}
                          title={file.name}
                          imageName={existFolder ? `data:image/jpeg;base64,${existFolder.thumbnail}` : fileIcon as string}
                          buttonNames={existFolder ? ['</>'] : []}
                          buttonEvents={[() => handleGenerateEmbedCode(`${bucket}`, `${file.name}`)]}
                          onClickToCard={async () => {
                            if (existFolder) {
                              handleEmbedCodeAndOpen(`${bucket}`, `${file.name}`);
                            } else {
                              if (file.name.endsWith(".zip")) {
                                dispatch(fetchIAMFiles({
                                  itemId: file.id,
                                  bucketName: bucket
                                }))
                              }
                              let oneDriveData: OneDriveFileData = {
                                id: file.id,
                                name: file.name,
                                size: file.size as number,
                                rootFileName: ""
                              }
                              handleTranslateFile(oneDriveData);
                            }
                          }}
                          onDelete={() => {
                            setShowDeleteModal(true);
                            setItemToDeleteFile({
                              id: file.id,
                              name: file.name,
                            });
                          }}
                        />
                      );
                    })}
                </div>
                <ConfirmDeleteModal
                  show={showDeleteModal}
                  handleClose={handleCloseDeleteModal}
                  itemName={itemToDeleteFile.name}
                  handleConfirm={() => handleDeleteFile(itemToDeleteFile.id, bucket)}
                  deleteText='Model is deleting...'
                  isContinue={deleteFileSelector.loading}
                />
                <TextAreaModal
                  show={htmlAreaModal}
                  onHide={() => setHtmlAreaModal(false)}
                  incomingValue={htmlContent}
                />
                {showTranslationModal && (
                  <MultipleOneDriveUploadModal
                    fileList={secondSelectedFiles}
                    setFileList={setSecondSelectedFiles}
                    show={showTranslationModal}
                    onHide={() => setShowTranslationModal(false)}
                    bucket={bucket}
                    zipOption={zipOption}
                    setOpenZipOption={setOpenZipOption}
                    zipFile={zipFile}
                    setZipFile={setZipFile}
                    iamFiles={iamFilesSelector.data?iamFilesSelector.data:undefined}
                  />
                )}
              </>
            )}
          </>
        ) : (<><OneDriveUnauthorized /></>)
      }


    </>
  );
};

export default CombinedComponent;

