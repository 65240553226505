import { SubscriberModel, SubscriberResponseModel } from "../../models/subscriber/subscriber.model";
import { BasePutService } from "../base/base.put.service";



export class PutSubscriberService extends BasePutService<SubscriberModel,SubscriberResponseModel>{
    constructor(controllerName:string="subscriber", endpoint:string=""){
        super(controllerName, endpoint)
    }

    async SubscribeAsync(request:SubscriberModel){
        const formData=new FormData()  
        formData.append("email",request.email)
        return await this.PutWithFormDataAsync(formData)
    }
}