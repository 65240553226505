import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UploadPrevFilesRequestModel, UploadPrevFilesResponseModel } from "../../../models/oneDrive/file/upload.prev.files.model";
import { UploadPrevFilesService } from "../../../services/oneDrive/file/upload.prev.files.service";



interface UploadPrevFilesState extends 
BaseState<UploadPrevFilesResponseModel>{

}


let initialState:UploadPrevFilesState={
    data:null,
    loading:false,
    error:null
}



export const uploadPrevFiles=createAsyncThunk("upload/prevFiles",
async(model:UploadPrevFilesRequestModel,thunkAPI)=>{

    try{
        const uploadPrevFilesService=new UploadPrevFilesService()
        const response=await uploadPrevFilesService.UploadWithFormDataAsync(model)
        return response
    }catch (err: any) {
        if (!err.response) {
          throw err;
        }
  
        const errorData = err.response.data;
  
        return thunkAPI.rejectWithValue({
          error: errorData.error ? errorData.error : errorData.message,
        });
      }
   
})


export const uploadPrevFilesSlice=createSlice({
    name:"uploadPrevFilesSlice",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(uploadPrevFiles.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(uploadPrevFiles.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(uploadPrevFiles.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})


export default uploadPrevFilesSlice.reducer