import { UploadGoogleDrivePrevFilesRequestModel, UploadGoogleDrivePrevFilesResponseModel } from "../../models/googleDrive/upload.prev.files.model";
import { BasePutService } from "../base/base.put.service";



export class UploadPrevGoogleDriveFilesService extends 
BasePutService<UploadGoogleDrivePrevFilesRequestModel,UploadGoogleDrivePrevFilesResponseModel>{

    constructor(controllerName:string="googleDrive",endpoint:string="upload"){
        super(controllerName,endpoint)
    }

    async UploadWithFormDataAsync(request:UploadGoogleDrivePrevFilesRequestModel){
        const formData=new FormData()
        request.files.map((file,i)=>{
            formData.append(`files`, file)
        })

        formData.append('bucketName', request.bucketName)
        
       
        return await this.PutWithFormDataAsync(formData)
    }
}