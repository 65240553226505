// import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { BaseState } from "../../../commons/base.state";
// import { UserRegisterRequestModel, UserRegisterResponseModel } from "../../../models/user/user.register.model";
// import { UserRegisterPostService } from "../../../services/user/user.register.service";



// interface UserRegisterState extends BaseState<UserRegisterResponseModel>{

// }

// let initialState:UserRegisterState={
//     data:null,
//     loading:false,
//     error:null
// }

// export const fetchUserRegister=createAsyncThunk("fetch/UserRegister",
// async(request:UserRegisterRequestModel,thunkAPI)=>{

//     try{
//         const service=new UserRegisterPostService()
//         const response=await service.PostAsync({
//             userName:request.userName,
//             password:request.password,
//             email:request.email,
//             lastName:request.lastName,
//             firstName:request.firstName,
//             companyName:request.companyName,
//             subscriptionName:request.subscriptionName,
//             phoneNumber:request.phoneNumber
//         })
    
//         return response
//     }
//     catch(err:any){
//         if (!err.response) {
//             throw err
//           }
    
    
//           const errorData=err.response.data
          
//           return thunkAPI.rejectWithValue({
//             error: errorData.error?errorData.error:errorData.message
//           })
//     }
// })


// export const UserRegisterSlice=createSlice({
//     name:"userRegisterSlice",
//     initialState,
//     reducers:{},
//     extraReducers(builder) {
//         builder
//         .addCase(fetchUserRegister.pending,(state,action)=>{
//             state.loading=true
//         })
//         .addCase(fetchUserRegister.fulfilled,(state,action)=>{
//             state.loading=false
//             state.data=action.payload
//         })
//         .addCase(fetchUserRegister.rejected,(state,action:PayloadAction<any>)=>{
//             state.loading=false
//             state.error=action.payload
//         })
//     }
// })

// export default UserRegisterSlice.reducer


import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UserRegisterRequestModel, UserRegisterResponseModel } from "../../../models/user/user.register.model";
import { UserRegisterPostService } from "../../../services/user/user.register.service";

interface UserRegisterState extends BaseState<UserRegisterResponseModel> {}

const initialState: UserRegisterState = {
    data: null,
    loading: false,
    error: null,
};

export const fetchUserRegister = createAsyncThunk(
    "fetch/UserRegister",
    async (request: UserRegisterRequestModel, thunkAPI) => {
        try {
            const service = new UserRegisterPostService();
            const response = await service.PostAsync({
                userName: request.userName,
                password: request.password,
                email: request.email,
                lastName: request.lastName,
                firstName: request.firstName,
                companyName: request.companyName,
                subscriptionName: request.subscriptionName,
                phoneNumber: request.phoneNumber,
            });

            return response;
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }

            const errorData = err.response.data;

            return thunkAPI.rejectWithValue({
                error: errorData.error ? errorData.error : errorData.message,
            });
        }
    }
);

export const UserRegisterSlice = createSlice({
    name: "userRegisterSlice",
    initialState,
    reducers: {
        reset: (state) => {
            return initialState; // Reset state to initial values
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchUserRegister.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUserRegister.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchUserRegister.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { reset } = UserRegisterSlice.actions;

export default UserRegisterSlice.reducer;
