import '../../styles/onedrive/upload.css';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { uploadPrevFiles } from '../../store/features/oneDrive/upload.prev.files.slice';
import CircleLoading from '../Loading/CircleLoading';
import { useEffect, useState } from 'react';
import { fetchAllFiles } from '../../store/features/oneDrive/get.all.files.slice';

interface DragAndDropProps {
	handleFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleCloseModal: () => void;
	handleFileDrop: (e: React.DragEvent<HTMLDivElement>) => void;
	setIsDragDrop: React.Dispatch<React.SetStateAction<boolean>>;
	selectedFiles: File[];
	setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
	bucket: string
}

const DragnDrop: React.FC<DragAndDropProps> = ({
	handleFile,
	handleFileDrop,
	setIsDragDrop,
	selectedFiles,
	setSelectedFiles,
	bucket
}) => {
	const dispatch = useAppDispatch();
	const uploadPrevSelector = useAppSelector((s: RootState) => s.uploadPrevFilesSlice);
	const [isFinishedToLoading, SetIsFinishedToLoading] = useState(false);
	const [warningMessage, setWarningMessage] = useState('');

	const fileFormats = ["3dm", "3ds", "asm", "asm.<d>", "brd", "CATPart", "CATProduct", "cgr", "collab", "dae", "dgn", "dvl", "dwfx", "dwg", "dwt", "dxf", "ipt", "iam", "emode", "exp", "f2d", "f3d", "fbx", "gcode", "nc", "xml", "glb", "gltf", "ifc", "iges", "igs", "iwm", "jt", "mpf", "msr","rvt", "neu", "neu.<d>", "nwc", "nwd", "obj", "par", "pmlprj", "pmlprjz", "prt", "prt.<d>", "psm", "psmodel", "rvm", "sab", "sat", "sch", "session", "skp", "sldasm", "slddrw", "sldprt", "ste", "step", "stl", "stla", "stlb", "stp", "stpz", "vue", "wire", "x_b", "x_t", "xas", "xpr","zip"];

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(e.target.files || []);
		const validFiles = files.filter(file => {
			const fileExtension = file.name.split('.').pop()?.toLowerCase();
			return fileFormats.includes(fileExtension || '');
		});
		const invalidFiles = files.filter(file => {
			const fileExtension = file.name.split('.').pop()?.toLowerCase();
			return !fileFormats.includes(fileExtension || '');
		});

		if (invalidFiles.length > 0) {
			setWarningMessage(`The format ${invalidFiles.map(file => file.name.split('.').pop()).join(', ')} is not supported for upload`);
		} else {
			setWarningMessage('');
		}

		setSelectedFiles(validFiles);
		handleFile(e);
		e.target.value = '';
	};

	const handleFileDropChange = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const files = Array.from(e.dataTransfer.files);
		const validFiles = files.filter(file => {
			const fileExtension = file.name.split('.').pop()?.toLowerCase();
			return fileFormats.includes(fileExtension || '');
		});
		const invalidFiles = files.filter(file => {
			const fileExtension = file.name.split('.').pop()?.toLowerCase();
			return !fileFormats.includes(fileExtension || '');
		});

		if (invalidFiles.length > 0) {
			setWarningMessage(`The format ${invalidFiles.map(file => file.name.split('.').pop()).join(', ')} is not supported for upload`);
		} else {
			setWarningMessage('');
		}

		setSelectedFiles(validFiles);
		handleFileDrop(e);
	};

	const removeFile = (index: number) => {
		const updatedFiles = selectedFiles.filter((_, i) => i !== index);
		setSelectedFiles(updatedFiles);
		if (updatedFiles.some(file => !fileFormats.includes(file.name.split('.').pop()?.toLowerCase() || ''))) {
			setWarningMessage(`The format ${updatedFiles.filter(file => !fileFormats.includes(file.name.split('.').pop()?.toLowerCase() || '')).map(file => file.name.split('.').pop()).join(', ')} is not supported for upload`);
		} else {
			setWarningMessage('');
		}
	};

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);

	return (
		<div className='dnd-modal'>
			<div className='dnd-container'>
				<button className="close-button" hidden={uploadPrevSelector.loading ? true : false} onClick={() => {
					setSelectedFiles([])
					if (isFinishedToLoading) {
						SetIsFinishedToLoading(false)
						dispatch(fetchAllFiles(bucket))
						setIsDragDrop(false)
					} else {
						setIsDragDrop(false)
					}
				}}>
					<svg className="close-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={{ width: "50px", height: "50px" }}>
						<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
						<path d="M0 0h24v24H0z" fill="none" />
					</svg>
				</button>

				<label className='dnd-label'>
					<input
						type='file'
						multiple
						onChange={handleFileChange}
						disabled={uploadPrevSelector.loading ? true : false}
						hidden={uploadPrevSelector.loading ? true : false}
						style={{ display: 'none' }}
					/>
					<div
						onDrop={handleFileDropChange}
						onDragOver={(e) => e.preventDefault()}
						className='drag-drop-area'
					>
						<div className='svg-container'>
							<svg
								className='svg-icon'
								viewBox='0 0 24 24'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g>
									<path
										fill='none'
										d='M0 0h24v24H0z'
									/>
									<path
										className='hover-path'
										fillRule='nonzero'
										d='M14 6h2v2h5a1 1 0 0 1 1 1v7.5L16 13l.036 8.062 2.223-2.15L20.041 22H9a1 1 0 0 1-1-1v-5H6v-2h2V9a 1 1 0 0 1 1-1h5V6zm8 11.338V21a 1 1 0 0 1-.048.307l-1.96-3.394L22 17.338zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z'
									/>
								</g>
							</svg>
						</div>

						<div className='dnd-desc'>
							<p>
								<b>Drag & Drop or Browse</b>
							</p>
							<>
								<p>We support IPT,ZIP,DWG,DWFX,RVT and more...</p>
							</>
						</div>
						<br />
						{warningMessage && (
							<p style={{ color: 'orange', textAlign: 'center' }}>
								{warningMessage}
							</p>
						)}
						<div className='dnd-files'>
							{uploadPrevSelector.loading ? (
								<CircleLoading text={`${selectedFiles.length > 1 ? "Files are" : "File is"} uploading...`} />
							) : (
								<>
									{selectedFiles &&
										selectedFiles.length > 0 &&
										selectedFiles.map((file, i) => (
											<div
												className='addedFile'
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
												key={i}
											>
												<span
													style={{
														marginLeft: '0.5rem',
													}}
												>
													{file.name}
												</span>
												<button
													style={{
														marginLeft: 'auto',
														background: 'none',
														border: 'none',
														cursor: 'pointer',
														color: 'red',
														fontSize: '1.5rem',
														lineHeight: '1rem'
													}}
													onClick={(e) => {
														e.stopPropagation(); // Bu olayın yukarıya doğru yayılmasını engeller
														removeFile(i);
													}}
												>
													&times;
												</button>
											</div>
										))}
								</>
							)}
						</div>
					</div>
					<div className='dnd-upload-btn-grp'>
						<button
							className='dnd-cancel-btn'
							hidden={
								uploadPrevSelector.loading ? true : false ||
									isFinishedToLoading ? true : false
							}
							onClick={() => {
								setSelectedFiles([])
								setIsDragDrop(false)
							}}
						>
							Cancel
						</button>
						{selectedFiles && selectedFiles.length > 0 && !warningMessage && (
							<button
								className='dnd-upload-btn'
								hidden={uploadPrevSelector.loading ? true : false}
								onClick={() => {
									dispatch(
										uploadPrevFiles({
											files: selectedFiles,
											bucketName: bucket
										})
									).then(() => {
										setSelectedFiles([])
										SetIsFinishedToLoading(true)
									})
								}}
							>
								Upload
							</button>
						)}
						</div>
						{isFinishedToLoading && selectedFiles.length === 0 && (
							<div className='dnd-finished-btn-grp'>
								{uploadPrevSelector.data === null && uploadPrevSelector.loading === false && uploadPrevSelector.error !== null ? (
									<>
										{selectedFiles.length > 1 ? (
											<>
												<div style={{ textAlign: "center" }}>
													<p style={{ color: "red", display:"block" }}>File hasn't been uploaded successfully</p>
												</div>
												<div style={{ textAlign: "center",marginTop:"10px;" }}>
													<button
														className='dnd-cancel-btn'
														onClick={() => {
															setSelectedFiles([])
															setIsDragDrop(false)
														}}
													>
														Return Upload Page
													</button>
												</div>
											</>


										)
											: (<>
												<div style={{ textAlign: "center" }}>
													<p style={{ color: "red", display:"block"}}>File hasn't been uploaded successfully</p>
												</div>
												<div style={{textAlign: "center",marginTop:"10px;" }}>
													<button
														className='dnd-cancel-btn'
														onClick={() => {
															setSelectedFiles([])
															setIsDragDrop(false)
														}}
													>
														Return Upload Page
													</button>
												</div>
											</>
											)
										}
									</>
								) : (
									<>
										{selectedFiles.length > 1 ? (
											<>
												<div style={{ textAlign: "center" }}>
													<p style={{ color: "green", display:"block" }}>Files have been uploaded successfully</p>
												</div>
												<div style={{ textAlign: "center",marginTop:"10px;" }}>
													<button
														className='dnd-upload-btn'
														onClick={() => {
															setSelectedFiles([])
															dispatch(fetchAllFiles(bucket))
															setIsDragDrop(false)
														}}
													>
														Return Upload Page
													</button>
												</div>

											</>


										)
											: (
												<>
													<div style={{ textAlign: "center" }}>
														<p style={{ color: "green",display:"block" }}>File has been uploaded successfully</p>
													</div>
													<div style={{ textAlign: "center",marginTop:"10px;" }}>
														<button
															className='dnd-upload-btn'
															onClick={() => {
																setSelectedFiles([])
																dispatch(fetchAllFiles(bucket))
																setIsDragDrop(false)
															}}
														>
															Return Upload Page
														</button>
													</div>
												</>
											)
										}
									</>
								)}
							</div>
						)}
					
				</label>
			</div>
		</div>

	);
};

export default DragnDrop;


