import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { fetchUserCredit } from "../../store/features/dashboard/get.user.credit.count.slice";
import { UserCreditModel } from "../../models/dashboard/get.user.credit.model";
import { Bar } from "react-chartjs-2";
import { fetchUserAllFileCount } from "../../store/features/dashboard/get.user.all.files.count.slice";
import { UserFileCountModel } from "../../models/dashboard/get.user.all.files.count.model";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import SecondHeader from "../../components/Navbar/SecondHeader";
import { LoginResponse } from "../../models/auth/login.model";
import Forbidden from '../../assets/403Forbidden.png';
import { baseUrl } from "../../config/axios.config";
import { HubConnectionBuilder } from "@microsoft/signalr";
import CircleLoading from "../../components/Loading/CircleLoading";
import '../../styles/dashboard/dashboard.css';

const DashboardHome = () => {
    const dispatch = useAppDispatch();
    const userCreditSelector = useAppSelector(x => x.getUserCreditCountSlice);
    const userFileCountSelector = useAppSelector(x => x.getUserAllFilesCountSlice);
    const loginInfo = sessionStorage.getItem('login') as string;
    const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse;

    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const bucket=sessionStorage.getItem('b') as string;

    const startConnection = () => {
        if (connection) {
            connection.start()
                .then(() => {
                    setIsConnected(true);
                })
                .catch((err) => {
                    console.error('Error while establishing the connection: ', err);
                });
        }
    };

    const stopConnection = () => {
        if (connection && isConnected) {
            connection.stop()
                .then(() => {
                    setIsConnected(false);
                })
                .catch((err) => {
                    console.error('Error while stopping the connection: ', err);
                });
        }
    };

    useEffect(() => {
        dispatch(fetchUserCredit(bucket)).then(() => {
            dispatch(fetchUserAllFileCount(bucket));
        });
    }, [dispatch]);

    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(`${baseUrl}update`)
            .withAutomaticReconnect()
            .build();

        setConnection(connection);
    }, []);

    useEffect(() => {
        startConnection();
        if (connection) {
            connection.on("ReceiveUpdateDataStatus", (val: boolean) => {
                if (val) {
                    dispatch(fetchUserCredit(bucket)).then(() => {
                        dispatch(fetchUserAllFileCount(bucket));
                    });
                }
            });
        }
        return () => {
            stopConnection();
        };
    }, [dispatch, connection, isConnected]);

    const userCreditChart = (data: UserCreditModel[]) => {
        return {
            labels: data.map(user => user.userName),
            datasets: [
                {
                    label: 'Available Credit',
                    backgroundColor: 'rgba(75,192,192,0.2)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(75,192,192,0.4)',
                    hoverBorderColor: 'rgba(75,192,192,1)',
                    data: data.map(user => user.availableCredit),
                    maxBarThickness: 40,
                    barPercentage: 0.7,
                    categoryPercentage: 0.5
                },
                {
                    label: 'Used Credit',
                    backgroundColor: 'rgba(255,99,132,0.2)',
                    borderColor: 'rgba(255,99,132,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: data.map(user => user.usedCredit),
                    maxBarThickness: 40,
                    barPercentage: 0.7,
                    categoryPercentage: 0.5
                }
            ]
        };
    };

    const userFileChart = (data: UserFileCountModel[]) => {
        return {
            labels: data.map(file => file.userName),
            datasets: [{
                label: "Count",
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(75,192,192,0.4)',
                hoverBorderColor: 'rgba(75,192,192,1)',
                data: data.map(file => file.fileCount),
                maxBarThickness: 40,
                barPercentage: 0.7,
                categoryPercentage: 0.5
            }]
        };
    };

    return (
        <div>
            <Container fluid className="dashboard-container d-flex flex-column align-items-center">
                {jsonLoginInfo.isOwner ? (
                    <>
                        <Row className="mt-4 w-100 justify-content-center">
                            <Col xs={12} md={6} lg={6} className="mb-4">
                                <Card className="dashboard-card">
                                    <Card.Body>
                                        <h4 className="text-center">User Credits</h4>
                                        <div className="chart-container">
                                            {userCreditSelector.data ? (
                                                <Bar 
                                                    data={userCreditChart(userCreditSelector.data)} 
                                                    options={{ 
                                                        responsive: true, 
                                                        maintainAspectRatio: false,
                                                        scales: {
                                                            x: {
                                                                beginAtZero: true,
                                                                ticks: {
                                                                    autoSkip: false,
                                                                    maxRotation: 45,
                                                                    minRotation: 45,
                                                                    padding: 10
                                                                }
                                                            },
                                                            y: {
                                                                beginAtZero: true,
                                                                ticks: {
                                                                    stepSize: 1
                                                                }
                                                            }
                                                        }
                                                    }} 
                                                    className="chart-canvas"
                                                />
                                            ) : (
                                                <div className="loading-container">
                                                    <CircleLoading text="Chart loading may take a long time depending on the number of data" />
                                                </div>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} lg={6} className="mb-4">
                                <Card className="dashboard-card">
                                    <Card.Body>
                                        <h4 className="text-center">User File Counts</h4>
                                        <div className="chart-container">
                                            {userFileCountSelector.data ? (
                                                <Bar 
                                                    data={userFileChart(userFileCountSelector.data)} 
                                                    options={{ 
                                                        responsive: true, 
                                                        maintainAspectRatio: false,
                                                        scales: {
                                                            x: {
                                                                beginAtZero: true,
                                                                ticks: {
                                                                    autoSkip: false,
                                                                    maxRotation: 45,
                                                                    minRotation: 45,
                                                                    padding: 10
                                                                }
                                                            },
                                                            y: {
                                                                beginAtZero: true,
                                                                ticks: {
                                                                    stepSize: 1
                                                                }
                                                            }
                                                        }
                                                    }} 
                                                    className="chart-canvas"
                                                />
                                            ) : (
                                                <div className="loading-container">
                                                    <CircleLoading text="Chart loading may take a long time depending on the number of data" />
                                                </div>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <div className="back-button-container">
                            <Button className="back-button" onClick={() => window.location.href = '/profile'}>
                                Back to Profile
                            </Button>
                        </div>
                    </>
                ) : (
                    <Row className="justify-content-center mt-5">
                        <Col xs={12} md={8} lg={6}>
                            <Card className="text-center">
                                <Card.Header>
                                    <Card.Img src={Forbidden} style={{ width: "100px", margin: "0 auto" }} />
                                </Card.Header>
                                <Card.Body>
                                    <p style={{ fontSize: "24px" }}>
                                        This user cannot access the dashboard
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
};

export default DashboardHome;
