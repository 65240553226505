
import '../../styles/onedrive/upload.css';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import CircleLoading from '../Loading/CircleLoading';
import { useEffect, useState } from 'react';
import { uploadGoogleDrivePrevFiles } from '../../store/features/googleDrive/upload.prev.googleDrive.files.slice';
import { fetchAllGoogleDriveFiles } from '../../store/features/googleDrive/get.all.googleDrive.files.slice';

interface DragAndDropProps {
	handleFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleCloseModal: () => void;
	handleFileDrop: (e: React.DragEvent<HTMLDivElement>) => void;
	setIsDragDrop: React.Dispatch<React.SetStateAction<boolean>>;
	selectedFiles: File[];
	setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
	bucket:string
}

const GoogleDriveDragnDrop: React.FC<DragAndDropProps> = ({
	handleFile,
	handleFileDrop,
	setIsDragDrop,
	selectedFiles,
	setSelectedFiles,
	bucket
}) => {
	const dispatch = useAppDispatch();
	const uploadPrevSelector = useAppSelector((s: RootState) => s.uploadGoogleDrivePrevFilesSlice);
	const [isFinishedToLoading, SetIsFinishedToLoading] = useState(false)
	
	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);
	return (
		<div className='dnd-modal'>
			<div className='dnd-container'>
				<button className="close-button" hidden={uploadPrevSelector.loading?true:false} onClick={()=>{
					if(isFinishedToLoading){
						SetIsFinishedToLoading(false)
						dispatch(fetchAllGoogleDriveFiles(bucket))
						setIsDragDrop(false)
					}

					else{
						setIsDragDrop(false)
					}
					
					
				}}>
					<svg className="close-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
						<path d="M0 0h24v24H0z" fill="none" />
					</svg>
				</button>
				<label className='dnd-label'>
					<input
						type='file'
						multiple
						onChange={(e) => {
							handleFile(e);
							e.target.value = '';
						}}
						disabled={uploadPrevSelector.loading ? true : false}
						hidden={uploadPrevSelector.loading ? true : false}
						style={{ display: 'none' }}
					/>
					<div
						onDrop={handleFileDrop}
						onDragOver={(e) => e.preventDefault()}
						className='drag-drop-area'
					>
						<div className='svg-container'>
							<svg
								className='svg-icon'
								viewBox='0 0 24 24'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g>
									<path
										fill='none'
										d='M0 0h24v24H0z'
									/>
									<path
										className='hover-path'
										fill-rule='nonzero'
										d='M14 6h2v2h5a1 1 0 0 1 1 1v7.5L16 13l.036 8.062 2.223-2.15L20.041 22H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zm8 11.338V21a1 1 0 0 1-.048.307l-1.96-3.394L22 17.338zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z'
									/>
								</g>
							</svg>
						</div>

						<div className='dnd-desc'>
							<p>
								<b>Drag & Drop or Browse</b>
							</p>
							<>
								<p>We support IPT,ZIP,DWG,DWFX,RVT and more...</p>
							</>
						</div>
						<br />
						{
							<div className='dnd-files'>

								{uploadPrevSelector.loading ? (
									<CircleLoading text={`${selectedFiles.length > 1 ? "Files are" : "File is"} uploading...`} />
								) : (
									<>
										{selectedFiles &&
											selectedFiles.length > 0 &&
											selectedFiles.map((file, i) => (
												<div
													className='addedFile'
													style={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<span
														style={{
															marginLeft: '0.5rem',
														}}
													>
														{file.name}
													</span>
												</div>
											))}
									</>
								)}
							</div>
						}
					</div>
					<div className='dnd-upload-btn-grp'>
					<button
							className='dnd-cancel-btn'
							hidden={
								uploadPrevSelector.loading ? true : false ||
									isFinishedToLoading ? true : false 
									
							}
							onClick={() => {
								setSelectedFiles([])
								setIsDragDrop(false)
							}}
						>
							Cancel
						</button>
						{selectedFiles && selectedFiles.length > 0 && (
							<button
								className='dnd-upload-btn'
								hidden={uploadPrevSelector.loading ? true : false}
								onClick={() => {
									dispatch(
										uploadGoogleDrivePrevFiles({
											files: selectedFiles,
											bucketName:bucket
										})
									).then(() => {
										setSelectedFiles([])
										SetIsFinishedToLoading(true)
									})
								}}
							>
								Upload
							</button>
						)}
						{
							isFinishedToLoading && selectedFiles.length===0 && (
								<>
									{
										selectedFiles.length > 1 ? (<p style={{
											color: "green",
											marginRight: "auto"
										}}>Files have been uploaded succesfully</p>)
											: (<p style={{
												color: "green",
												marginRight: "auto"
											}}>File has been uploaded successfully</p>)
									}
								</>
							)
						}
					
					</div>
				</label>
			</div>
		</div>
	);
};

export default GoogleDriveDragnDrop
