import { BaseModel } from "../../models/base/base.model";
import { BaseService } from "./base.service";

export class BaseGetService<T extends BaseModel> extends BaseService{
    async GetAsync():Promise<T>{
        const res= await this.request.get<T>("")
        return res.data
    }

    async GetByStringParamAsync(param:string):Promise<T>{
        const res=await this.request.get<T>(`/${param}`)  
        return res.data
    }

    async GetByStringManyParamsAsync(params:string[]):Promise<T>{

        let text:string=""
        params.map((param)=>{
            text+=`/${param}`
        })
        const res=await this.request.get<T>(text)
        console.log(res)
        return res.data
    }

    async GetByNumberParamAsync(param:number[]):Promise<T>{
        const res= await this.request.get<T>(`/${param}`)
        return res.data
    }


    async GetByNumberManyParamsAsync(params:number[]):Promise<T>{
        let text:string=""
        params.map((param)=>{
            text+=`/${param}`
        })
        const res=await this.request.get<T>(text)
        return res.data
    }

    async GetByQueryParameter(query:string):Promise<T>{
        const res=await this.request.get(`?${query}`)
        return res.data
    }

    async GetByQueryParametersAsync(params:string[]):Promise<T>{
        let text:string="?"
        params.map((param,i)=>{
            text+=`${param}`
            if(i!==params.length-1){
                text+="&"
            }
        })
       const res=await this.request.get<T>(text)
        return res.data
    }

    async GetByQueryParametersAsyncNoData(params:string[]){
        let text:string="?"
        params.map((param,i)=>{
            text+=`${param}`
            if(i!==params.length-1){
                text+="&"
            }
        })
        const res=await this.request.get<T>(text)
        return res
    }
}