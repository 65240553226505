
import { Owner } from "../../models/user/owner.users.model";
import { BaseGetService } from "../base/base.get.service";


export class GetOwnerUsersService extends BaseGetService<Owner> {
    constructor(controllerName: string = "user", endpointName: string = "owner/listUsers") {
        super(controllerName, endpointName);
    }

    async GetOwnerUsersAsync(ownerId: string): Promise<Owner> {
        return this.GetByStringParamAsync(ownerId);
    }
}
